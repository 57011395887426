@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
@import url("https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@import 'react-calendar/dist/Calendar.css';
.onscreen-fade-in-1s {
  animation: fadeIn 1s ease-in;
}

.onscreen-fade-in-500ms {
  animation: fadeIn 500ms ease-in;
}

.offscreen-fade-out {
  animation: fadeOut 1s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.onscreen-fade-in-100ms {
  animation: fadeIn 100ms ease-in;
}

.fade-in-image-1 {
  animation: fadeIn 0.35s;
  -webkit-animation: fadeIn 0.35s;
  -moz-animation: fadeIn 0.35s;
  -o-animation: fadeIn 0.35s;
  -ms-animation: fadeIn 0.35s;
}

.fade-in-image-2 {
  animation: fadeIn 0.65s;
  -webkit-animation: fadeIn 0.65s;
  -moz-animation: fadeIn 0.65s;
  -o-animation: fadeIn 0.65s;
  -ms-animation: fadeIn 0.65s;
}

.fade-in-image-3 {
  animation: fadeIn 0.95s;
  -webkit-animation: fadeIn 0.95s;
  -moz-animation: fadeIn 0.95s;
  -o-animation: fadeIn 0.95s;
  -ms-animation: fadeIn 0.95s;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}
.fadeInOut {
  animation: fadeInOut 2s infinite;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.modal-fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.12s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  pointer-events: none;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.modal-slideIn-top {
  animation: slideInTop 0.75s forwards;
}

@keyframes slideInTop {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0%);
  }
}
.modal-slideIn-bottom {
  animation: slideInBottom 0.75s forwards;
}

@keyframes slideInBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.live-connection-icon {
  position: relative;
  min-height: 100%;
}
.live-connection-icon .live-connection-status--pending {
  animation: spin 6s linear infinite;
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  animation: spin 0.8s infinite;
  position: absolute;
  min-height: 32px;
  min-width: 32px;
  max-height: 32px;
  max-width: 32px;
  margin-top: auto;
  margin-bottom: auto;
}
@media (max-width: 1280px) {
  .live-connection-icon .live-connection-status--pending {
    padding: 3px;
  }
}
.live-connection-icon .live-connection-status--pending--staging {
  animation: spin 6s linear infinite;
  min-height: 32px;
  min-width: 32px;
  max-height: 32px;
  max-width: 32px;
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  animation: spin 0.8s infinite;
  position: absolute;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }
  100% {
    transform: rotate(0deg);
  }
}
.shake {
  transform: scale(1);
  animation: pulse 2s infinite;
  animation: tilt-shaking 0.2s 2;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.fade-in-slide-down-anim {
  animation-delay: 0.5s;
  animation: fadeInSlideDown 0.5s ease forwards;
}

.fade-in-slide-up-anim {
  animation: fadeOutSlideUp 0.5s ease forwards;
  display: block;
}

.fadeOut-slide-down-anim--strength-level {
  animation: fadeOutSlideDownStrengthLevel 0.3s ease forwards;
  display: block;
}

.fadeOut-slide-up-anim--strength-level {
  animation: fadeOutSlideUpStrengthLevel 0.3s ease forwards;
  display: block;
}

@keyframes fadeInSlideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(4px);
  }
}
@keyframes fadeOutSlideUp {
  from {
    opacity: 1;
    transform: translateY(4px);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
@keyframes fadeOutSlideDownStrengthLevel {
  0% {
    opacity: 0;
    height: 0;
    transform: translateY(0px);
  }
  100% {
    opacity: 1;
    margin-top: 24px;
    transform: translateY(0);
  }
}
@keyframes fadeOutSlideUpStrengthLevel {
  0% {
    opacity: 1;
    margin-top: 24px;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    height: 0;
    transform: translateY(0px);
  }
}
.transition-opacity--500ms {
  transition: opacity 500ms ease-in;
}

.h6 {
  min-height: 6px;
  min-width: 100%;
  pointer-events: none;
}

.h12 {
  min-height: 12px;
  min-width: 100%;
  pointer-events: none;
}

.h18 {
  min-height: 18px;
  min-width: 100%;
  pointer-events: none;
}

.h24 {
  min-height: 24px;
  min-width: 100%;
  pointer-events: none;
}

.h25 {
  min-height: 25px;
  min-width: 100%;
  pointer-events: none;
}

.h36 {
  min-height: 36px;
  min-width: 100%;
  pointer-events: none;
}

.h48 {
  min-height: 48px;
  min-width: 100%;
  pointer-events: none;
}

.h64 {
  min-height: 64px;
  min-width: 100%;
  pointer-events: none;
}

.h72 {
  min-height: 72px;
  min-width: 100%;
  pointer-events: none;
}

.h96 {
  min-height: 96px;
  min-width: 100%;
  pointer-events: none;
}

.h128 {
  min-height: 128px;
  min-width: 100%;
  pointer-events: none;
}

@media (max-width: 480px) {
  .h72--ph {
    min-height: 72px;
  }
}

@media (max-width: 480px) {
  .h96--ph {
    min-height: 96px;
  }
}

.h0-ph12 {
  min-height: 0px;
  min-width: 100%;
  pointer-events: none;
}
@media (max-width: 480px) {
  .h0-ph12 {
    min-height: 12px;
  }
}

.h0-ph24 {
  min-height: 0px;
  min-width: 100%;
  pointer-events: none;
}
@media (max-width: 480px) {
  .h0-ph24 {
    min-height: 24px;
  }
}

.h24-ph12 {
  min-height: 24px;
  min-width: 100%;
  pointer-events: none;
}
@media (max-width: 480px) {
  .h24-ph12 {
    min-height: 12px;
  }
}

.h24-ph36 {
  min-height: 24px;
  min-width: 100%;
  pointer-events: none;
}
@media (max-width: 480px) {
  .h24-ph36 {
    min-height: 36px;
  }
}

.h48-ph24 {
  min-height: 48px;
  min-width: 100%;
  pointer-events: none;
}
@media (max-width: 480px) {
  .h48-ph24 {
    min-height: 24px;
  }
}

.h36-ph24 {
  min-height: 36px;
  min-width: 100%;
  pointer-events: none;
}
@media (max-width: 480px) {
  .h36-ph24 {
    min-height: 24px;
  }
}

.no-outline {
  outline: none;
  transition: none;
}

.access-ow:focus-visible {
  outline: 2px solid white;
  transition: none;
}

.access-ob:focus-visible {
  outline: 3px solid blue;
  transition: none;
}

.access-ot:focus-visible {
  outline: 2px solid #f67512;
  transition: none;
}

.access-o-6:focus-visible {
  outline-offset: -6px;
  transition: none;
}

.access-o6:focus-visible {
  outline-offset: 6px;
  transition: none;
}

.access-o-12:focus-visible {
  outline-offset: -12px;
  transition: none;
}

.access-o12:focus-visible {
  outline-offset: 12px;
  transition: none;
}

.border-reg {
  border: 2px solid rgba(0, 0, 0, 0.35);
}

.border-theme-1 {
  border: 1px solid #f67512 !important;
}

.border-theme-2 {
  border: 2px solid #f67512 !important;
}

.border-thin {
  border: 1px solid rgba(0, 0, 0, 0.35);
}

.br--small {
  border-radius: 4px;
}

.br--standard {
  border-radius: 10px;
}

.border-green {
  border: 2px solid #64c071;
}

.no-border {
  border: 0px solid rgba(0, 0, 0, 0);
}

.br--circular {
  border-radius: 50%;
}

.br--none {
  border-radius: 0% !important;
}

.border-call-to-action {
  border: 3px solid #dc2c2c;
  border-radius: 10px;
}

.b-standard {
  border: 2px solid rgba(0, 0, 0, 0.35);
}

.outline-invis-2 {
  outline: 2px solid rgba(0, 0, 0, 0);
}

.outline-invis-1 {
  outline: 1px solid rgba(0, 0, 0, 0);
}

.outline-theme-1 {
  outline: 1px solid #f67512;
}

.border-reg-1 {
  border: 1px solid rgba(0, 0, 0, 0.35);
}

.outline-reg-1 {
  outline: 1px solid rgba(0, 0, 0, 0.35);
}

.outline-reg-2 {
  outline: 1px solid rgba(0, 0, 0, 0.35);
}

.border-bottom-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
}

.border-bottom-2 {
  border-bottom: 2px solid rgba(0, 0, 0, 0.35);
}

.border-bottom-3 {
  border-bottom: 3px solid rgba(0, 0, 0, 0.35);
}

.previewMode-border {
  outline: 2px dashed rgba(0, 0, 0, 0.25);
}

.outline-offset-2 {
  outline-offset: 2px;
}

.outline-offset-4 {
  outline-offset: 8px;
}

.outline-offset-8 {
  outline-offset: 8px;
}

.outline-offset-12 {
  outline-offset: 12px;
}

.outline-offset-16 {
  outline-offset: 16px;
}

.no-box-shadow {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

.highlight-it-theme:focus-visible {
  color: #f67512;
}
@media (hover: hover) {
  .highlight-it-theme:hover {
    color: #f67512;
  }
  .highlight-it-theme:hover img {
    filter: invert(60%) sepia(48%) saturate(628%) hue-rotate(339deg) brightness(104%) contrast(93%);
  }
}

.highlight-t-red:focus-visible {
  color: #dc2c2c;
}
@media (hover: hover) {
  .highlight-t-red:hover {
    color: #dc2c2c;
  }
  .highlight-t-red:hover img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}

.highlight-t-white:focus-visible {
  color: #ffffff;
}
@media (hover: hover) {
  .highlight-t-white:hover {
    color: #ffffff;
  }
}

.highlight-o-theme {
  outline: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.highlight-o-theme:focus-visible {
  outline: 2px solid #f67512;
}
@media (hover: hover) {
  .highlight-o-theme:hover {
    outline: 2px solid #f67512;
  }
}

.highlight-o-thin-theme {
  outline: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.highlight-o-thin-theme:focus-visible {
  outline: 1px solid #f67512;
}
@media (hover: hover) {
  .highlight-o-thin-theme:hover {
    outline: 1px solid #f67512;
  }
}

.highlight-ob-theme--thin {
  outline: 1px solid rgba(0, 0, 0, 0.35);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.highlight-ob-theme--thin:focus-visible {
  outline: 1px solid #f67512;
}
@media (hover: hover) {
  .highlight-ob-theme--thin:hover {
    outline: 1px solid #f67512;
  }
}

.highlight-ob-theme--thin--active {
  outline: 1px solid #f67512;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.highlight-ob-theme--thin--active:focus-visible {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}
.highlight-ob-theme--thin--active:focus {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}
@media (hover: hover) {
  .highlight-ob-theme--thin--active:hover {
    outline: 1px solid #f67512;
  }
}

.highlight-ob-theme--thin--focused {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
  border-radius: 4px;
}

.highlight-b-theme {
  border: 2px solid rgba(0, 0, 0, 0.35);
}
.highlight-b-theme:focus-visible {
  border: 2px solid #f67512;
}
@media (hover: hover) {
  .highlight-b-theme:hover {
    border: 2px solid #f67512;
  }
}

.highlight-ob-theme {
  outline: 1px solid rgba(0, 0, 0, 0.35);
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.highlight-ob-theme:focus-visible {
  outline: 1px solid #f67512;
}
@media (hover: hover) {
  .highlight-ob-theme:hover {
    border: 1px solid #f67512;
    outline: 1px solid #f67512;
  }
}

.highlight-ob-theme--active {
  outline: 1px solid #f67512;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
}
.highlight-ob-theme--active:focus-visible {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}
.highlight-ob-theme--active:focus {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}
@media (hover: hover) {
  .highlight-ob-theme--active:hover {
    outline: 1px solid #f67512;
  }
}

.highlight-bthin-theme {
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.highlight-bthin-theme:focus-visible {
  border: 1px solid #f67512;
}
@media (hover: hover) {
  .highlight-bthin-theme:hover {
    border: 1px solid #f67512;
  }
}

.highlight-btreg-theme {
  border: 2px solid rgba(0, 0, 0, 0.4);
  color: #333333;
}
.highlight-btreg-theme:focus-visible {
  border: 2px solid #f67512;
  color: #f67512;
}
@media (hover: hover) {
  .highlight-btreg-theme:hover {
    color: #f67512;
    border: 2px solid #f67512;
  }
}

.highlight-breg-theme {
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.highlight-breg-theme:active, .highlight-breg-theme:focus-visible {
  border: 1px solid #f67512;
  outline: 1px solid #f67512;
}
@media (hover: hover) {
  .highlight-breg-theme:hover {
    border: 1px solid #f67512;
  }
}

.highlight-b2-theme {
  border: 2px solid rgba(0, 0, 0, 0.4);
}
.highlight-b2-theme:active, .highlight-b2-theme:focus-visible {
  border: 2px solid #f67512;
}
@media (hover: hover) {
  .highlight-b2-theme:hover {
    border: 2px solid #f67512;
  }
}

.highlight-b-green {
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}
.highlight-b-green:focus-visible {
  border: 1px solid #64c071;
}
@media (hover: hover) {
  .highlight-b-green:hover {
    border: 1px solid #64c071;
  }
}

.highlight-b-red {
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}
.highlight-b-red:focus-visible {
  border: 1px solid #dc2c2c;
}
@media (hover: hover) {
  .highlight-b-red:hover {
    border: 1px solid #dc2c2c;
  }
}

.highlight-breg-red {
  border: 2px solid rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}
.highlight-breg-red:focus-visible {
  border: 2px solid #dc2c2c;
}
@media (hover: hover) {
  .highlight-breg-red:hover {
    border: 2px solid #dc2c2c;
  }
}

.highlight-tb-theme:focus-visible {
  color: #f67512;
  border: 2px solid #f67512;
}
@media (hover: hover) {
  .highlight-tb-theme:hover {
    color: #f67512;
    border: 2px solid #f67512;
  }
}

.highlight-tb3-theme:focus-visible {
  color: #f67512;
  border: 3px solid #f67512;
}
@media (hover: hover) {
  .highlight-tb3-theme:hover {
    color: #f67512;
    border: 3px solid #f67512;
  }
}

.highlight-i-theme:focus-visible img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}
@media (hover: hover) {
  .highlight-i-theme:hover img {
    filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
  }
}

.highlight-i-lgr img {
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(93%) contrast(92%);
}
.highlight-i-lgr:active img, .highlight-i-lgr:focus-visible img {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}
@media (hover: hover) {
  .highlight-i-lgr:hover img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}

.highlight-i-gr img {
  filter: invert(71%) sepia(0%) saturate(1637%) hue-rotate(286deg) brightness(80%) contrast(87%);
}
.highlight-i-gr:active img, .highlight-i-gr:focus-visible img {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}
@media (hover: hover) {
  .highlight-i-gr:hover img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}

@media (hover: hover) {
  .highlight-i-hover--red:hover {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}

.highlight-i-lgg img {
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(93%) contrast(92%);
}
.highlight-i-lgg:active img, .highlight-i-lgg:focus-visible img {
  filter: invert(72%) sepia(52%) saturate(383%) hue-rotate(77deg) brightness(90%) contrast(91%);
}
@media (hover: hover) {
  .highlight-i-lgg:hover img {
    filter: invert(72%) sepia(52%) saturate(383%) hue-rotate(77deg) brightness(90%) contrast(91%);
  }
}

.highlight-i-lg img {
  filter: invert(71%) sepia(0%) saturate(1637%) hue-rotate(286deg) brightness(80%) contrast(87%);
}
.highlight-i-lg:active img, .highlight-i-lg:focus-visible img {
  filter: invert(72%) sepia(52%) saturate(383%) hue-rotate(77deg) brightness(90%) contrast(91%);
}
@media (hover: hover) {
  .highlight-i-lg:hover img {
    filter: invert(72%) sepia(52%) saturate(383%) hue-rotate(77deg) brightness(90%) contrast(91%);
  }
}

.highlight-i-lgt img {
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(93%) contrast(92%);
}
.highlight-i-lgt:active img, .highlight-i-lgt:focus-visible img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}
@media (hover: hover) {
  .highlight-i-lgt:hover img {
    filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
  }
}

.highlight-i-gt img {
  filter: invert(71%) sepia(0%) saturate(1637%) hue-rotate(286deg) brightness(80%) contrast(87%);
}
.highlight-i-gt:active img, .highlight-i-gt:focus-visible img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}
@media (hover: hover) {
  .highlight-i-gt:hover img {
    filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
  }
}

.highlight-it-theme:active, .highlight-it-theme:focus-visible {
  color: #f67512;
}
.highlight-it-theme:active img, .highlight-it-theme:focus-visible img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}
@media (hover: hover) {
  .highlight-it-theme:hover {
    color: #f67512;
  }
  .highlight-it-theme:hover img {
    filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
  }
}

.highlight-t-theme:active, .highlight-t-theme:focus-visible {
  color: #f67512;
}
@media (hover: hover) {
  .highlight-t-theme:hover {
    color: #f67512;
  }
}

.highlight-t-gr {
  color: #777777;
}
@media (hover: hover) {
  .highlight-t-gr:hover, .highlight-t-gr:focus {
    color: #dc2c2c;
  }
}
.highlight-t-gr:focus-visible {
  color: #dc2c2c;
}

.highlight-bg-theme-theme-light {
  background-color: #f67512;
}
.highlight-bg-theme-theme-light:active, .highlight-bg-theme-theme-light:focus-visible {
  background-color: #f6974e;
}
@media (hover: hover) {
  .highlight-bg-theme-theme-light:hover {
    background-color: #f6974e;
  }
}

.highlight-bg-green--hover:focus-visible {
  background-color: #64c071;
}
@media (hover: hover) {
  .highlight-bg-green--hover:hover {
    background-color: #64c071;
  }
}

.highlight-bg-red--hover:focus-visible {
  background-color: #dc2c2c;
}
@media (hover: hover) {
  .highlight-bg-red--hover:hover {
    background-color: #dc2c2c;
  }
}

.highlight-bg-lg--tap {
  background-color: rgba(0, 0, 0, 0.03);
}

.border-1px--warning {
  border: 1px solid red !important;
}

.outline-1px--warning {
  outline: 1px solid red !important;
}

@media (hover: hover) {
  .hover-color-white:hover {
    color: #ffffff;
  }
}

@media (hover: hover) {
  .hover-border-thin-theme:hover {
    border: 1px solid #f67512;
  }
}

.highlight-ob-theme--tap {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}

.highlight-o-theme--tap {
  outline: 1px solid #f67512;
}

.highlight-o2-theme--tap {
  outline: 2px solid #f67512;
}

.highlight-b-theme--tap {
  border: 1px solid #f67512;
}

.highlight-b2-theme--tap {
  border: 2px solid #f67512;
}

.highlight-tb-theme--tap {
  color: #f67512;
  border: 2px solid #f67512;
}

.highlight-tb3-theme--tap {
  color: #f67512;
  border: 3px solid #f67512;
}

.highlight-t-theme--tap {
  color: #f67512;
}

.highlight-it-theme--tap {
  color: #f67512;
}
.highlight-it-theme--tap img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}

.highlight-t-red--tap {
  color: #dc2c2c;
}

.highlight-i-theme--tap img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}

.highlight-b-red--tap {
  border: 2px solid #dc2c2c;
}

.highlight-i-lgr--tap img {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}

.highlight-bg-theme-light--tap {
  background-color: #f6974e;
}

.highlight-bg-theme--tap {
  background-color: #f67512;
  color: #ffffff !important;
}

.highlight-bg-theme {
  background-color: #f67512;
}

.highlight-bg-red--tap {
  background-color: #dc2c2c;
  color: #ffffff;
}

.highlight-bg-green--tap {
  background-color: #64c071;
  color: #ffffff;
}

.highlight-bg-transparent-lg--tap {
  background-color: rgba(128, 128, 128, 0.125);
  color: #ffffff;
}

.highlight-opacity--tap {
  opacity: 1;
}

@media (hover: hover) {
  .hover-i-filter-theme:hover {
    filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
  }
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

@media (max-width: 480px) {
  .flex-row--ph {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 480px) {
  .flex-column--ph {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1280px) {
  .flex-column--mob {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 320px) {
  .flex-column--phn {
    display: flex;
    flex-direction: column;
  }
}

.row-reverse {
  flex-direction: row-reverse !important;
}

@media (max-width: 320px) {
  .col-reverse--phn {
    flex-direction: column-reverse;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 480px) {
  .flex-center--ph {
    display: flex;
    justify-content: center;
  }
}

.justify-center {
  display: flex;
  justify-content: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.align-right {
  display: flex;
  align-items: flex-end;
}

.align-left {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 480px) {
  .flex-center--ph {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  align-items: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.space-around {
  display: flex;
  justify-content: space-around;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .space-between--ph {
    display: flex;
    justify-content: space-between;
  }
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 480px) {
  .space-evenly--ph {
    display: flex;
    justify-content: space-evenly;
  }
}

.no-mrg {
  margin: 0px !important;
}

.no-mrg-left {
  margin-left: 0px !important;
}

.no-mrg-right {
  margin-right: 0px !important;
}

.no-mrg-lr {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mrg-auto {
  margin: auto;
}

.mrg-auto-tb {
  margin-top: auto;
  margin-bottom: auto;
}

.mrg-auto-lr {
  margin-left: auto;
  margin-right: auto;
}

.mrg-auto-top {
  margin-top: auto;
}

@media (max-width: 480px) {
  .mrg-auto-top--ph {
    margin-top: auto;
  }
}

.mrg-auto-bottom {
  margin-bottom: auto;
}

@media (max-width: 480px) {
  .mrg-auto-bottom--ph {
    margin-bottom: auto;
  }
}

.mrg-auto-left {
  margin-left: auto;
}

@media (max-width: 480px) {
  .mrg-auto-left--ph {
    margin-left: auto;
  }
}

.mrg-auto-right {
  margin-right: auto;
}

@media (max-width: 480px) {
  .mrg-auto-right--ph {
    margin-right: auto;
  }
}

.mrg-48 {
  margin: 48px;
}

.mrg-t0 {
  margin-top: 0px;
}

.mrg-t2 {
  margin-top: 2px;
}

.mrg-t0 {
  margin-top: 0px;
}

.mrg-t4 {
  margin-top: 4px;
}

.mrg-t6 {
  margin-top: 6px;
}

.mrg-t8 {
  margin-top: 8px;
}

.mrg-t12 {
  margin-top: 12px;
}

.mrg-t14 {
  margin-top: 14px;
}

.mrg-t16 {
  margin-top: 16px;
}

.mrg-t18 {
  margin-top: 18px;
}

.mrg-t24 {
  margin-top: 24px;
}

.mrg-t32 {
  margin-top: 32px;
}

.mrg-t36 {
  margin-top: 36px;
}

.mrg-t48 {
  margin-top: 48px;
}

.mrg-t64 {
  margin-top: 64px;
}

.mrg-t96 {
  margin-top: 96px;
}

.mrg-t128 {
  margin-top: 128px;
}

.mrg-l0 {
  margin-left: 0px;
}

.mrg-l6 {
  margin-left: 6px;
}

.mrg-l8 {
  margin-left: 8px;
}

.mrg-l12 {
  margin-left: 12px;
}

@media (max-width: 480px) {
  .mrg-l12--ph {
    margin-left: 12px;
  }
}

.mrg-l16 {
  margin-left: 16px;
}

.mrg-l18 {
  margin-left: 18px;
}

.mrg-l24 {
  margin-left: 24px;
}

.mrg-l36 {
  margin-left: 36px;
}

.mrg-l48 {
  margin-left: 48px;
}

.mrg-r0 {
  margin-right: 0px;
}

.mrg-r4 {
  margin-right: 4px;
}

.mrg-r6 {
  margin-right: 6px;
}

.mrg-r8 {
  margin-right: 8px;
}

.mrg-r12 {
  margin-right: 12px;
}

.mrg-r16 {
  margin-right: 16px;
}

.mrg-r24 {
  margin-right: 24px;
}

.mrg-r28 {
  margin-right: 28px;
}

.mrg-r36 {
  margin-right: 36px;
}

.mrg-r48 {
  margin-right: 48px;
}

.mrg-b0 {
  margin-bottom: 0px;
}

.mrg-b2 {
  margin-bottom: 2px;
}

.mrg-b3 {
  margin-bottom: 3px;
}

.mrg-b4 {
  margin-bottom: 4px;
}

.mrg-b5 {
  margin-bottom: 5px;
}

.mrg-b6 {
  margin-bottom: 6px;
}

.mrg-b8 {
  margin-bottom: 8px;
}

.mrg-b12 {
  margin-bottom: 12px;
}

.mrg-b14 {
  margin-bottom: 14px;
}

.mrg-b16 {
  margin-bottom: 16px;
}

.mrg-b24 {
  margin-bottom: 24px;
}

.mrg-b36 {
  margin-bottom: 36px;
}

.mrg-b48 {
  margin-bottom: 48px;
}

.mrg-tb6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mrg-tb8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mrg-tb12 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.mrg-tb16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mrg-tb24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mrg-tb32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mrg-tb36 {
  margin-top: 36px;
  margin-bottom: 36px;
}

.mrg-tb48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.mrg-tb96 {
  margin-top: 96px;
  margin-bottom: 96px;
}

.mrg-lr2 {
  margin-left: 2px;
  margin-right: 2px;
}

.mrg-lr4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mrg-lr6 {
  margin-left: 6px;
  margin-right: 6px;
}

.mrg-lr12 {
  margin-left: 12px;
  margin-right: 12px;
}

.mrg-lr16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mrg-lr18 {
  margin-left: 18px;
  margin-right: 18px;
}

.mrg-lr24 {
  margin-left: 24px;
  margin-right: 24px;
}

@media (max-width: 480px) {
  .mrg-t0--ph {
    margin-top: 0px;
  }
}

@media (max-width: 480px) {
  .mrg-t6--ph {
    margin-top: 6px;
  }
}

@media (max-width: 480px) {
  .mrg-t12--ph {
    margin-top: 12px;
  }
}

@media (max-width: 480px) {
  .mrg-t24--ph {
    margin-top: 24px;
  }
}

@media (max-width: 1280px) {
  .mrg-t24--mob {
    margin-top: 24px;
  }
}

@media (max-width: 480px) {
  .mrg-t36--ph {
    margin-top: 36px;
  }
}

@media (max-width: 1280px) {
  .mrg-t36--mob {
    margin-top: 36px;
  }
}

@media (max-width: 480px) {
  .mrg-b12--ph {
    margin-bottom: 12px;
  }
}

@media (max-width: 480px) {
  .mrg-b24--ph {
    margin-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .mrg-b36--ph {
    margin-bottom: 36px;
  }
}

@media (max-width: 480px) {
  .mrg-b48--ph {
    margin-bottom: 48px;
  }
}

@media (max-width: 1280px) {
  .mrg-b24--mob {
    margin-bottom: 24px;
  }
}

@media (max-width: 1280px) {
  .mrg-l0--mob {
    margin-left: 0px;
  }
}

@media (max-width: 1280px) {
  .mrg-r0--mob {
    margin-right: 0px;
  }
}

.mrg-l12-phn-l0 {
  margin-left: 12px;
}
@media (max-width: 320px) {
  .mrg-l12-phn-l0 {
    margin-left: 0px;
  }
}

@media (max-width: 480px) {
  .mrg-r0--ph {
    margin-right: 0px;
  }
}

@media (max-width: 480px) {
  .mrg-l0--ph {
    margin-left: 0px;
  }
}

.mrg-b0-ph {
  margin-bottom: 0px;
}

.mrg-tb24--mob {
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 480px) {
  .mrg-auto-left--ph {
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  .mrg-auto-right--ph {
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .mrg-auto-lr--ph {
    margin-right: auto;
    margin-left: auto;
  }
}

.pad-auto-lr {
  padding-left: auto;
  padding-right: auto;
}

.pad-2 {
  padding: 2px;
}

.pad-3 {
  padding: 3px;
}

.pad-4 {
  padding: 4px;
}

.pad-6 {
  padding: 6px;
}

.pad-8 {
  padding: 8px;
}

.pad-12 {
  padding: 12px;
}

.pad-16 {
  padding: 16px;
}

.pad-24 {
  padding: 24px;
}

.pad-36 {
  padding: 36px;
}

.pad-48 {
  padding: 48px;
}

.pad-t2 {
  padding-top: 2px;
}

.pad-t6 {
  padding-top: 6px;
}

.pad-t12 {
  padding-top: 12px;
}

.pad-t24 {
  padding-top: 24px;
}

.pad-t36 {
  padding-top: 36px;
}

.pad-t48 {
  padding-top: 48px;
}

.pad-b6 {
  padding-bottom: 6px;
}

.pad-b12 {
  padding-bottom: 12px;
}

.pad-l12 {
  padding-left: 12px;
}

.pad-l24 {
  padding-left: 24px;
}

.pad-l36 {
  padding-left: 36px;
}

.pad-l48 {
  padding-left: 48px;
}

.pad-r6 {
  padding-right: 6px;
}

.pad-r12 {
  padding-right: 12px;
}

.pad-r24 {
  padding-right: 24px;
}

.pad-r36 {
  padding-right: 36px;
}

.pad-r48 {
  padding-right: 48px;
}

.pad-tb6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.pad-tb8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.pad-tb10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pad-tb12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pad-tb24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pad-tb36 {
  padding-top: 36px;
  padding-bottom: 36px;
}

.pad-tb48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.pad-lr6 {
  padding-left: 6px;
  padding-right: 6px;
}

.pad-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pad-lr12 {
  padding-left: 12px;
  padding-right: 12px;
}

.pad-lr14 {
  padding-left: 14px;
  padding-right: 14px;
}

.pad-lr24 {
  padding-left: 24px;
  padding-right: 24px;
}

.pad-lr16 {
  padding-left: 16px;
  padding-right: 16px;
}

.pad-lr18 {
  padding-left: 18px;
  padding-right: 18px;
}

.pad-lr24 {
  padding-left: 24px;
  padding-right: 24px;
}

.pad-lr32 {
  padding-left: 32px;
  padding-right: 32px;
}

.pad-b12 {
  padding-bottom: 12px;
}

.pad-tb12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.pad-tb16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.pad-b24 {
  padding-bottom: 24px;
}

@media (max-width: 480px) {
  .pad-tb12--ph {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (max-width: 480px) {
  .pad-lr12--ph {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (max-width: 480px) {
  .pad-0--ph {
    padding: 0px;
  }
}

.text-center {
  text-align: center;
}

@media (max-width: 480px) {
  .text-center--ph {
    text-align: center;
  }
}

.text-left {
  text-align: left !important;
}

@media (max-width: 480px) {
  .text-left--ph {
    text-align: left;
  }
}

.text-right {
  text-align: right;
}

@media (max-width: 480px) {
  .text-right--ph {
    text-align: right;
  }
}

.text-responsive-left-to-center {
  text-align: left;
}
@media (max-width: 480px) {
  .text-responsive-left-to-center {
    text-align: center;
  }
}

.no-wrap {
  white-space: nowrap;
}

.wrap {
  white-space: pre-wrap;
}

.break-word {
  word-wrap: break-word;
}

.text-underline {
  text-decoration: underline;
}

.text-decoration--dashed {
  text-decoration: dashed;
}

.description-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.7;
}
.description-text:focus-visible {
  outline: 3px solid blue;
  outline-offset: 6px;
}

.content-heading-styled {
  font-weight: 700;
  font-size: 21px;
  width: fit-content;
}
.content-heading-styled:focus-visible {
  outline-offset: 6px;
  outline: 3px solid blue;
}

.txt-align-center--ph-left {
  text-align: center;
}
@media (max-width: 480px) {
  .txt-align-center--ph-left {
    text-align: flex-start;
  }
}

.reverse {
  transform: scaleX(-1);
}

.reverse-vertical {
  transform: scaleY(-1);
}

.rotate-cw45 {
  transform: rotate(45deg);
}

.rotate-cw90 {
  transform: rotate(90deg);
}

.rotate-ccw90 {
  transform: rotate(-90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.click-scaledown-90 {
  transform: scale(0.9);
}

.click-scaledown-95 {
  transform: scale(0.95);
}

.click-scaledown-98 {
  transform: scale(0.98);
}

.mirror {
  transform: scaleX(-1) scaleY(-1);
}

.blur {
  position: absolute;
  height: 100%;
  max-width: 100vw;
  min-width: 100%;
  top: 0;
  z-index: 1;
  backdrop-filter: blur(1.15px);
}

.above-blur {
  z-index: 2;
}

.click-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 7;
  transition: 0.25s;
}

.overlay {
  z-index: 5;
  position: fixed;
  background-color: black;
  min-width: 100%;
  opacity: 0.4;
  display: flex;
  cursor: pointer;
  height: 200vh;
  top: 0;
  right: 0;
}

.overblur {
  z-index: 9;
}

.dropdown-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  -webkit-tap-highlight-color: transparent;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.max-width-full {
  max-width: 100%;
}

.min-full-width {
  min-width: 100%;
}

.fit-content {
  width: fit-content;
}

@media (max-width: 480px) {
  .full-width--ph {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .min-full-width--ph {
    min-width: 100%;
  }
}

.full-height {
  height: 100%;
}

.min-full-height {
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

.fs12 {
  font-size: 12px;
}

.fs14 {
  font-size: 14px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs19 {
  font-size: 19px;
}

.fs20 {
  font-size: 20px;
}

.fs21 {
  font-size: 21px;
}

.fs24 {
  font-size: 24px;
}

.fs28 {
  font-size: 28px;
}

@media (max-width: 480px) {
  .fs16--ph {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .fs18--ph {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .fs21--ph {
    font-size: 21px;
  }
}

@media (max-width: 320px) {
  .fs14--phn {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .fs16--phn {
    font-size: 16px;
  }
}

.ff2 {
  font-family: "Poppins", sans-serif;
}

.fwl {
  font-weight: 300;
}

.fwn {
  font-weight: 400;
}

.fwsb {
  font-weight: 600;
}

.fwb {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.lh24 {
  line-height: 24px;
}

.lh26 {
  line-height: 26px;
}

.lh28 {
  line-height: 28px;
}

.lh30 {
  line-height: 30px;
}

.lh36 {
  line-height: 36px;
}

.lh42 {
  line-height: 42px;
}

.color-white {
  color: #ffffff;
}

.color-black {
  color: #333333;
}

.color-lightgray {
  color: #ccc;
}

.color-gray {
  color: rgba(0, 0, 0, 0.55);
}

.color-theme {
  color: #f67512;
}

.color-theme--bold {
  color: #ff480f;
}

.color-blue {
  color: blue;
}

.color-red {
  color: #dc2c2c;
}

.color-yellow {
  color: rgb(230, 230, 0);
}

.text-gray {
  color: rgba(0, 0, 0, 0.65);
}

.text-darkgray {
  color: rgba(0, 0, 0, 0.75);
}

.color-darkgray {
  color: rgba(0, 0, 0, 0.75);
}

.text-lightgray {
  color: rgba(0, 0, 0, 0.45);
}

.text-red {
  color: #dc2c2c;
}

.color-green {
  color: #64c071;
}

.color-open {
  color: #5a96e4;
}

.bg-color-lightgray {
  background-color: #ccc;
}

.bg-color-theme {
  background-color: #f67512;
}

.bg-color-green {
  background-color: #64c071;
}

.bg-color-red {
  background-color: #dc2c2c;
}

.bg-color-white {
  background-color: white;
}

.bg-color-back {
  background-color: black;
}

.bg-color-light {
  background-color: #fafafa;
}

.bg-radial-light-theme {
  background: radial-gradient(circle at 0 0, #ffe5d0 5%, #f9b9b1 35%, rgba(230, 132, 160, 0.7803921569));
}

.bg-theme-light {
  background: linear-gradient(90deg, rgba(186, 96, 221, 0.3764705882), rgba(199, 103, 191, 0.3254901961), rgba(227, 88, 88, 0.2705882353), white 98%);
}

.bg-cream {
  background-color: #fdf7f2;
}

.bg-light-purp {
  background-color: rgba(186, 96, 221, 0.168627451);
}

.bg-purp {
  background-color: rgba(199, 103, 191, 0.3254901961);
}

.bg-light-blue {
  background-color: #b2d4e6;
}

.filter-white {
  filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(330deg) brightness(102%) contrast(104%);
}

.filter-black {
  filter: invert(12%) sepia(86%) saturate(0%) hue-rotate(184deg) brightness(103%) contrast(84%);
}

.filter-gray {
  filter: invert(71%) sepia(0%) saturate(1637%) hue-rotate(286deg) brightness(80%) contrast(87%);
}

.filter-darkgray {
  filter: invert(22%) sepia(7%) saturate(4%) hue-rotate(314deg) brightness(106%) contrast(88%);
}

.filter-white--tap {
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(110%) contrast(100%);
}

.filter-lightgray {
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(93%) contrast(92%);
}

.filter-red {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}

.filter-green {
  filter: invert(72%) sepia(52%) saturate(383%) hue-rotate(77deg) brightness(90%) contrast(91%);
}

.filter-green-light {
  filter: invert(46%) sepia(79%) saturate(1657%) hue-rotate(95deg) brightness(97%) contrast(101%);
}

.filter-theme {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}

.filter-theme--hover {
  filter: invert(60%) sepia(48%) saturate(628%) hue-rotate(339deg) brightness(104%) contrast(93%);
}

.filter-img-lightgray img {
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(93%) contrast(92%);
}

.filter-img-gray img {
  filter: invert(71%) sepia(0%) saturate(1637%) hue-rotate(286deg) brightness(80%) contrast(87%);
}

.filter-img-red img {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}

.filter-img-theme img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}

.filter-img-green img {
  filter: invert(72%) sepia(52%) saturate(383%) hue-rotate(77deg) brightness(90%) contrast(91%);
}

.filter-registration-open {
  filter: invert(53%) sepia(29%) saturate(906%) hue-rotate(174deg) brightness(100%) contrast(90%);
}

.filter-registration-registered {
  filter: invert(50%) sepia(39%) saturate(504%) hue-rotate(77deg) brightness(102%) contrast(89%);
}

.filter-registration-pending {
  filter: invert(59%) sepia(93%) saturate(466%) hue-rotate(358deg) brightness(95%) contrast(108%);
}

.filter-registration-almost-full {
  filter: invert(24%) sepia(94%) saturate(6518%) hue-rotate(354deg) brightness(96%) contrast(102%);
}

.filter-registration-full {
  filter: invert(24%) sepia(94%) saturate(6518%) hue-rotate(354deg) brightness(96%) contrast(102%);
}

.filter-blue {
  filter: invert(70%) sepia(80%) saturate(600%) hue-rotate(154deg) brightness(85%) contrast(100%);
}

.filter-none {
  filter: none !important;
}

.tag-profile-img {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 50%;
}

.tag-remove-btn-img {
  right: 6px;
  height: 14px;
  transition: transform 0.25s ease-in;
  min-width: 28px;
}

.account-profile-img {
  min-width: 164px;
  max-width: 164px;
  min-height: 164px;
  max-height: 164px;
  border-radius: 50%;
}

.institutional-profile-img {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  margin-right: 6px;
}

.registration-profile-img {
  min-width: 54px;
  max-width: 54px;
  min-height: 54px;
  max-height: 54px;
  border-radius: 50%;
}

.support-profile-image {
  max-height: 124px;
  min-height: 124px;
  max-width: 124px;
  min-width: 124px;
  border-radius: 95%;
  object-fit: cover;
}
.support-profile-image img {
  object-fit: fill;
}
@media (max-width: 480px) {
  .support-profile-image {
    margin: auto;
  }
}

.support-profile-image--small {
  min-width: 54px;
  max-width: 54px;
  min-height: 54px;
  max-height: 54px;
  border-radius: 50%;
}

.ev-org-profile-img {
  min-width: 64px;
  max-width: 64px;
  min-height: 64px;
  max-height: 64px;
  border-radius: 50%;
}

.banners__notice {
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 0, 0.151);
  padding: 10px;
  display: flex;
}
.banners__notice:focus-visible {
  outline-offset: 6px;
  transition: none;
  outline: 3px solid blue;
}

.attention-banner {
  display: flex;
  font-size: 18px;
  background-color: rgba(255, 255, 0, 0.171);
  padding: 6px;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.dispatches-table {
  width: 100%;
  border-collapse: collapse;
}
.dispatches-table th {
  border: 1px solid #c7c6c6;
  padding: 8px 24px;
  text-align: center;
  background-color: #f4f4f4;
  font-size: 18px;
}
.dispatches-table td {
  border: 1px solid #c7c6c6;
  padding: 8px 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  height: 74px;
}
.dispatches-table tr {
  width: 100%;
}

.apiStatus-modal__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  right: 0;
  width: fit-content;
  z-index: 10;
  max-width: 40%;
  top: 10%;
  flex-direction: column-reverse;
}
@media (max-width: 480px) {
  .apiStatus-modal__wrapper {
    max-width: 80%;
  }
}
@media (max-width: 320px) {
  .apiStatus-modal__wrapper {
    padding: 8px;
  }
}

.modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: fit-content;
  max-width: 396px;
  background-color: white;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans", sans-serif;
  padding: 16px;
}
@media (max-width: 1280px) {
  .modal {
    max-width: 80%;
  }
}
.modal .modal__container {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.modal .modal__container .modal-close-btn {
  display: flex;
  align-items: center;
  border-radius: 50%;
}
@media (hover: hover) {
  .modal .modal__container .modal-close-btn:hover {
    background-color: rgba(128, 128, 128, 0.1);
  }
}
.modal .modal__container p {
  margin-right: 8px;
}

.modal-disable {
  pointer-events: none;
}

.modal-icon__container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
}
.modal-icon__container .modal-checkmark-icon {
  height: 18px;
  width: 18px;
}
.modal-icon__container .modal-error-icon {
  height: 14px;
  width: 14px;
}
.modal-icon__container .modal-warning-icon {
  height: 21px;
  width: 21px;
  margin-bottom: 3px;
}
.modal-icon__container .countdown-timer__container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
@media (hover: hover) {
  .modal-icon__container .countdown-timer__container:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.modal-icon__container .countdown-timer__container .countdown-timer {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: 40px;
  width: 40px;
  text-align: center;
}
.modal-icon__container .countdown-timer__container svg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}
.modal-icon__container .countdown-timer__container svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  fill: none;
  animation: countdown 5200ms linear infinite forwards;
}
.modal-icon__container .countdown-timer__container .stroke-green {
  stroke: #64c071;
}
.modal-icon__container .countdown-timer__container .stroke-red {
  stroke: #dc2c2c;
}
.modal-icon__container .countdown-timer__container .stroke-lightgray {
  stroke: #ccc;
}
@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

.modal-dropdown {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  width: fit-content;
  z-index: inherit;
  padding-right: 14px;
}
.modal-dropdown img {
  filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(330deg) brightness(102%) contrast(104%);
  height: 9px;
  margin: 0px 14px;
  max-width: 18px;
}
@media (max-width: 1280px) {
  .modal-dropdown {
    display: none;
  }
}
.modal-dropdown--footer {
  align-items: center;
  border-radius: 10px;
  display: flex;
  padding: 4px 12px;
  position: relative;
  min-height: 44px;
  border: 2px solid rgba(255, 255, 255, 0.75);
  padding-left: 24px;
}
.modal-dropdown--footer .arrow {
  filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(330deg) brightness(102%) contrast(104%);
}

.modal-dropdown__button {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 18px 0px;
  position: absolute;
  white-space: pre-wrap;
  width: 124px;
  list-style-type: none;
}
.modal-dropdown__button button {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 14px;
  margin-bottom: 2px;
  color: #333333;
  width: 124px;
}
@media (hover: hover) {
  .modal-dropdown__button button:hover {
    background-color: #f6974e;
    color: #ffffff;
  }
}
.modal-dropdown__button .selected {
  background-color: #f67512;
  color: #ffffff;
}
.modal-dropdown__button--header {
  top: 92px;
}
.modal-dropdown__button--footer {
  bottom: 224px;
  z-index: 9;
}

.modal-bottom-spacer {
  min-height: 72px;
}
@media (max-width: 480px) {
  .modal-bottom-spacer {
    min-height: 164px;
  }
}

.modal-bottom-spacer--safari {
  min-height: 72px;
}
@media (max-width: 480px) {
  .modal-bottom-spacer--safari {
    min-height: 196px;
  }
}

.selections__wrapper {
  position: relative;
  display: flex;
  max-width: 840px;
}
.selections__wrapper .selections__container {
  list-style-type: none;
}
.selections__wrapper .selections__container .selection-btn {
  border-radius: 10px;
  border: 1.4px solid rgba(0, 0, 0, 0.3);
  box-shadow: 1px 2px 1px #ececec;
  color: #333333;
  height: 44px;
  min-width: 120px;
  padding: 4px 12px;
  transition-timing-function: ease-in;
  font-size: 16px;
  font-weight: 600;
  margin-inline: 12px;
  margin-bottom: 24px;
}
@media (hover: hover) {
  .selections__wrapper .selections__container .selection-btn:hover {
    border: 1px solid rgba(0, 0, 0, 0);
    color: #ffffff;
    transition-timing-function: ease-in;
    transition: 200ms;
    background-color: #f6974e;
    box-shadow: 1px 2px 1px #e48d4a;
  }
}
.selections__wrapper .selections__container .selection-btn--active {
  background-color: #f67512;
  border: 1px solid rgba(0, 0, 0, 0);
  box-shadow: 1px 2px 1px #d86913;
  color: #ffffff;
  transition-timing-function: ease-in;
  transition: 0.1s;
}
@media (hover: hover) {
  .selections__wrapper .selections__container .selection-btn--active:hover {
    background-color: #f67512;
    box-shadow: 1px 2px 1px #d86913;
  }
}
.selections__wrapper .selections__container--horizontal-view {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 480px) {
  .selections__wrapper .selections__container--horizontal-view {
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
}
.selections__wrapper .selections__container--vertical-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.selections__wrapper .selections__container--vertical-view:first-child {
  margin-top: 0px;
}
@media (max-width: 480px) {
  .selections__wrapper .selections__container--vertical-view {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 320px) {
  .selections__wrapper .selections__container--vertical-view {
    grid-template-columns: 1fr;
  }
}
.selections__wrapper .selections__container--single-column {
  display: flex;
  flex-direction: column;
}
@media (max-width: 480px) {
  .selections__wrapper .selections__container--double-column--ph {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.remove-button {
  display: flex;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  width: fit-content;
}
@media (max-width: 480px) {
  .remove-button {
    font-size: 14px;
    padding-bottom: 0px;
    margin: auto;
  }
}
@media (hover: hover) {
  .remove-button:hover {
    color: #f23648;
  }
}

.margin-right-auto {
  margin-left: 0px;
  margin-right: auto;
}

.checkbox__wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 480px) {
  .checkbox__wrapper {
    justify-content: flex-start;
    width: 100%;
    margin-left: 24px;
  }
}

.checkbox__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: fit-content;
  max-width: fit-content;
  cursor: pointer;
  min-height: fit-content;
  -webkit-tap-highlight-color: transparent;
}
@media (hover: hover) {
  .checkbox__container:hover input[type=checkbox], .checkbox__container:active input[type=checkbox] {
    outline: 2px solid #f67512;
  }
}
.checkbox__container label {
  padding-left: 12px;
  height: 100%;
  cursor: pointer;
  min-height: 54px;
  display: flex;
  align-items: center;
}
.checkbox__container input[type=checkbox] {
  cursor: pointer;
  height: 44px;
  min-width: 44px;
  max-width: 44px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  border-radius: 5px;
  transform: translateY(-2px);
  display: grid;
  place-content: center;
  font: inherit;
}
.checkbox__container input[type=checkbox]::before {
  content: "";
  width: 21px;
  height: 21px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #ff480f;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  margin-left: 2px;
  opacity: 0.8;
}
.checkbox__container input[type=checkbox]:checked::before {
  transform: scale(1);
}

.add-category-list {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 12px;
  padding-right: 12px;
  column-gap: 24px;
  white-space: nowrap;
}
@media (max-width: 480px) {
  .add-category-list {
    grid-template-columns: 1fr;
    padding-left: 0px;
    padding-right: 0px;
    column-gap: 0px;
  }
}

.textarea__wrapper {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 0px 12px 0px 12px;
  min-height: fit-content;
  cursor: text;
  z-index: 2;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.35);
}
@media (hover: hover) {
  .textarea__wrapper:hover {
    border: 1px solid #f67512;
  }
}
.textarea__wrapper .textarea-label {
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  font-size: 18px;
  font-weight: 400;
  justify-content: space-between;
  padding-top: 12px;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  cursor: text;
}
.textarea__wrapper .textarea-label .highlight {
  color: #f67512;
  font-size: 18px;
}
.textarea__wrapper .textarea-label .maximum {
  color: #dc2c2c;
  font-weight: 600;
}
.textarea__wrapper .textarea-label .charCounter {
  margin-right: 21px;
}
.textarea__wrapper .textarea {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  overflow-y: hidden;
  resize: none;
  z-index: 3;
  outline: none;
  border: none;
  margin-top: 42px;
  margin-bottom: 12px;
}
@media (max-width: 480px) {
  .textarea__wrapper .textarea {
    width: 100%;
  }
}
.textarea__wrapper .textarea::placeholder {
  opacity: 0.5;
}
.textarea__wrapper .textarea:focus {
  outline: none;
}
.textarea__wrapper .textarea-button {
  -webkit-tap-highlight-color: transparent;
  height: 84px;
  padding: 18px;
}
.textarea__wrapper .textarea-button .flag {
  height: 20px;
}
.textarea__wrapper .textarea-warning {
  border: 1px solid #df1b41;
  outline: 1px solid #df1b41;
}
.textarea__wrapper .textarea--phone {
  max-height: 84px;
}

.textarea__wrapper--focus {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}

.text-input__wrapper {
  border-radius: 4px;
  cursor: text;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.text-input__wrapper .text-input-label {
  color: rgba(0, 0, 0, 0.75);
  cursor: text;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  height: fit-content;
  justify-content: space-between;
  padding: 12px 12px 0px 12px;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.text-input__wrapper .text-input-label label {
  border-radius: 4px;
}
.text-input__wrapper .text-input-label .highlight {
  color: #f67512;
  font-size: 18px;
}
.text-input__wrapper .text-input-label .maximum {
  color: #dc2c2c;
  font-weight: 600;
}
.text-input__wrapper .show-password-btn {
  position: absolute;
  right: 12px;
  top: 44px;
}
.text-input__wrapper .show-password-btn img {
  margin-top: 4px;
  max-height: 18px;
  min-height: 18px;
}
.text-input__wrapper .visible-off {
  top: 42px;
}
.text-input__wrapper .visible-off img {
  max-height: 22px;
  min-height: 22px;
}
.text-input__wrapper .text-input {
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  height: 77px;
  overflow-y: hidden;
  padding: 42px 12px 12px 12px;
  resize: none;
}
@media (max-width: 480px) {
  .text-input__wrapper .text-input {
    width: 100%;
  }
}
.text-input__wrapper .text-input::placeholder {
  opacity: 0.5;
}
.text-input__wrapper .text-input .text-input-password {
  padding: 42px 50px 12px 12px;
}
.text-input__wrapper .text-input-button {
  -webkit-tap-highlight-color: transparent;
  height: 77px;
  padding: 18px;
}
.text-input__wrapper .text-input-button .flag {
  height: 20px;
}
.text-input__wrapper .text-input-warning {
  border: 1px solid #df1b41;
  outline: 1px solid #df1b41;
}

.input-error__wrapper .input-error {
  color: #df1b41;
  font-size: 18px;
  font-weight: 500;
  height: 0;
  opacity: 0;
}
.input-error__wrapper .input-error-animation {
  animation-delay: 0.5s;
  animation: fadeInSlideDown 0.5s ease forwards;
  height: fit-content;
  margin-top: 5px;
}
.input-error__wrapper .input-error-hide {
  animation: fadeOutSlideUp 0.5s ease forwards;
  display: block;
}

.input-password-strength__wrapper .input-password-strength {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
}
@media (max-width: 480px) {
  .input-password-strength__wrapper .input-password-strength {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.input-password-strength__wrapper .input-password-strength .strength-level {
  border-radius: 10px;
  width: 25%;
  margin-right: 12px;
  height: 8px;
  background-color: #ccc;
}
.input-password-strength__wrapper .input-password-strength .strength-level:last-child {
  margin-right: 0px;
}
.input-password-strength__wrapper .input-password-strength .strength-level--weak {
  background-color: #dc2c2c;
}
.input-password-strength__wrapper .input-password-strength .strength-level--medium {
  background-color: rgb(230, 230, 0);
}
.input-password-strength__wrapper .input-password-strength .strength-level--strong {
  background-color: #64c071;
}
.input-password-strength__wrapper .strenth-level-text {
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  padding-top: 12px;
}

.strength-level-hide {
  opacity: 0;
  height: 0;
  margin: 0px;
}

.inputfield-validation-list {
  position: relative;
  color: #ccc;
  list-style: none;
}
.inputfield-validation-list li {
  margin-left: 36px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.inputfield-validation-list .valid {
  color: #64c071;
}
.inputfield-validation-list .invalid {
  color: #dc2c2c;
}
.inputfield-validation-list img {
  position: absolute;
  left: 0;
  margin-top: 5px;
  margin-right: 12px;
  margin-left: 12px;
}

.autocomplete__wrapper {
  position: relative;
  width: 100%;
}
.autocomplete__wrapper .input-label {
  position: absolute;
  left: 2px;
  top: 4px;
  height: 44px;
}
.autocomplete__wrapper .location {
  padding: 9px;
  height: 48px;
  width: 51px;
}
.autocomplete__wrapper .autocomplete-input {
  height: 52px;
  width: 100%;
  border: #8f8f8f solid 1px;
  outline: 1px solid rgba(0, 0, 0, 0);
  font-size: 18px;
  padding-left: 52px;
  padding-right: 15px;
  border-radius: 4px;
  z-index: 8;
}
@media (hover: hover) {
  .autocomplete__wrapper .autocomplete-input:hover {
    border: 1px solid #ff480f;
  }
}
.autocomplete__wrapper .autocomplete-input:focus {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}
.autocomplete__wrapper .autocomplete-input--active:focus {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.autocomplete__wrapper .autocomplete-input::placeholder {
  font-size: 16px;
}
.autocomplete__wrapper .dropdown-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.autocomplete__wrapper .dropdown-wrapper .dropdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 8;
}
.autocomplete__wrapper .dropdown-wrapper .dropdown-row {
  display: flex;
  align-items: left;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 6px 10px 6px 10px;
  width: 100%;
  cursor: pointer;
  transition: 100ms;
  z-index: 8;
  -webkit-tap-highlight-color: transparent;
}
.autocomplete__wrapper .dropdown-wrapper .dropdown-row:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0);
}
.autocomplete__wrapper .dropdown-wrapper .dropdown-row:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.autocomplete__wrapper .dropdown-wrapper .dropdown-row--selected {
  background-color: #f67512;
  color: #ffffff;
  border-top: 1px solid #f67512;
  border-left: 1px solid #f67512;
  border-right: 1px solid #f67512;
}
.autocomplete__wrapper .dropdown-wrapper .dropdown-row--selected:last-child {
  border-bottom: 1px solid #f67512;
}
.autocomplete__wrapper .dropdown-wrapper .dropdown-row--tap {
  background-color: #ffffff;
  color: #333333;
}

.counter__wrapper {
  display: flex;
  flex-direction: row;
}
.counter__wrapper .count__wrapper {
  position: relative;
  display: flex;
  justify-content: space-evenly;
}
.counter__wrapper .count__wrapper .count__container {
  display: flex;
  height: 44px;
  min-width: 124px;
  max-width: 124px;
  z-index: 2;
  background-color: white;
  padding-top: 1px;
}
.counter__wrapper .count__wrapper .count__container::-webkit-outer-spin-button, .counter__wrapper .count__wrapper .count__container::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.counter__wrapper .count__wrapper .count__container[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.counter__wrapper .count__wrapper .count__container--distance {
  text-align: right;
}
@media (max-width: 480px) {
  .counter__wrapper .count__wrapper .count__container--distance {
    text-align: center;
  }
}
.counter__wrapper .count__wrapper .unit {
  position: absolute;
  right: 12px;
  top: 10px;
  background-color: transparent;
  font-size: 19px;
  padding-left: 8px;
  z-index: 3;
}
.counter__wrapper .counter-button {
  min-width: 44px;
  color: #333333;
  font-size: 24px;
  border-radius: 4px;
  background-color: white;
}
.counter__wrapper .counter-button:first-child {
  margin-right: 12px;
}
.counter__wrapper .counter-button:last-child {
  margin-left: 12px;
}

.date-input {
  height: 52px;
  width: 100%;
  border: 1px solid #8f8f8f;
  outline: 1px solid rgba(0, 0, 0, 0);
  font-size: 18px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  z-index: 8;
}
@media (hover: hover) {
  .date-input:hover {
    border: 1px solid #ff480f;
    outline: 1px solid rgba(0, 0, 0, 0);
  }
}
.date-input:focus {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}

.number-input {
  height: 52px;
  width: 100%;
  border: 1px solid #8f8f8f;
  outline: 1px solid rgba(0, 0, 0, 0);
  font-size: 18px;
  padding-left: 12px;
  border-radius: 4px;
  z-index: 8;
}
@media (hover: hover) {
  .number-input:hover {
    border: 1px solid #ff480f;
    outline: 1px solid rgba(0, 0, 0, 0);
  }
}
.number-input:focus {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}

.stripe-input__wrapper {
  color: #333;
  font-size: 18.6px;
  height: fit-content;
  width: 100%;
}
.stripe-input__wrapper .title {
  font-weight: 500;
  margin-bottom: 5px;
}
.stripe-input__wrapper .stripe-error {
  color: #df1b41;
  font-weight: 500;
  height: 0;
  opacity: 0;
}
.stripe-input__wrapper .stripe-error-animation {
  animation-delay: 0.5s;
  animation: fadeInSlideDown 0.5s ease forwards;
  height: fit-content;
  margin-top: 5px;
}
.stripe-input__wrapper .stripe-error-hide {
  animation: fadeOutSlideUp 0.5s ease forwards;
  display: block;
}
.stripe-input__wrapper .stripe-input {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  color: #333;
  font-size: 20px;
  height: 55px;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: auto;
  margin-top: auto;
  padding: 15px;
  text-align: left;
  width: 100%;
}
@media (hover: hover) {
  .stripe-input__wrapper .stripe-input:hover {
    border: 1px solid #f67512;
  }
}
.stripe-input__wrapper .stripe-input::placeholder {
  font-size: 20px;
}
.stripe-input__wrapper .stripe-input:focus {
  border: 1px solid #f67512;
  outline: 1px solid #f67512;
}
.stripe-input__wrapper .stripe-input--phone {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  color: #333;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  height: 55px;
  letter-spacing: 0.5px;
  line-height: 28px;
  margin-bottom: auto;
  margin-top: auto;
  overflow-y: hidden;
  padding: 12px 12px 12px 12px;
  resize: none;
  text-align: left;
  width: 100%;
}
@media (hover: hover) {
  .stripe-input__wrapper .stripe-input--phone:hover {
    border: 1px solid #f67512;
  }
}
.stripe-input__wrapper .stripe-input--phone::placeholder {
  font-size: 20px;
  margin-bottom: 12px;
  padding-bottom: 12px;
}
.stripe-input__wrapper .stripe-input--phone:focus {
  border: 1px solid #f67512;
  outline: 1px solid #f67512;
}
.stripe-input__wrapper .stripe-input-warning {
  border: 1px solid #df1b41;
  outline: 1px solid #df1b41;
}
.stripe-input__wrapper .stripe-phone-button {
  -webkit-tap-highlight-color: transparent;
  border-radius: 4px;
  height: 55px;
  padding: 18px;
}
.stripe-input__wrapper .stripe-phone-button .flag {
  height: 20px;
  max-width: 40px;
  width: 40px;
}

.float-form__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
}
.float-form__wrapper .ff-col-row--mob {
  display: flex;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-col-row--mob {
    flex-direction: column;
    width: 100%;
  }
}
.float-form__wrapper .ff-row-col--mob {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-row-col--mob {
    flex-direction: row;
    width: 100%;
  }
}
.float-form__wrapper .ff-space-between--mob {
  justify-content: space-between;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-space-between--mob {
    justify-content: center;
  }
}
.float-form__wrapper .ff-space-evenly--mob {
  justify-content: space-evenly;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-space-evenly--mob {
    justify-content: flex-start;
  }
}
.float-form__wrapper .ff-align-left--mob {
  align-items: left;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-align-left--mob {
    align-items: center;
  }
}
.float-form__wrapper .ff-align-right--mob {
  align-items: right;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-align-right--mob {
    align-items: center;
  }
}
.float-form__wrapper .ff-h24--mob {
  height: 0;
  width: 100%;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-h24--mob {
    height: 24px;
  }
}
.float-form__wrapper .ff-h36--mob {
  height: 0;
  width: 100%;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-h36--mob {
    height: 36px;
  }
}
.float-form__wrapper .ff-h48--mob {
  height: 0;
  width: 100%;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-h48--mob {
    height: 48px;
  }
}
.float-form__wrapper .ff-h24-h48--mob {
  height: 24px;
  width: 100%;
}
@media (max-width: 1280px) {
  .float-form__wrapper .ff-h24-h48--mob {
    height: 48px;
  }
}
.float-form__wrapper .float-form__container {
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  max-width: 1036px;
  min-width: 1036px;
  padding: 48px 78px 48px 78px;
  position: relative;
}
@media (max-width: 1280px) {
  .float-form__wrapper .float-form__container {
    width: 100%;
  }
}
.float-form__wrapper .float-form__container:focus-visible {
  outline: 3px solid blue;
  outline-offset: 6px;
}
@media (max-width: 1280px) {
  .float-form__wrapper .float-form__container {
    min-width: 85vw;
    max-width: 85vw;
    padding: 36px;
  }
}
@media (max-width: 480px) {
  .float-form__wrapper .float-form__container {
    padding: 12px 0px 36px 0px;
    padding-left: 2vw;
    padding-right: 2vw;
    min-width: 100vw;
    max-width: 100vw;
    border: 2px solid rgba(0, 0, 0, 0);
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0);
  }
}
.float-form__wrapper .float-form__container .label {
  position: absolute;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  font-size: 21px;
  top: -16px;
  left: 27px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: #ffffff;
}
.float-form__wrapper .float-form__container .label:focus-visible {
  outline: 3px solid blue;
}
@media (max-width: 480px) {
  .float-form__wrapper .float-form__container .label {
    position: relative;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
    left: 0px;
  }
}
.float-form__wrapper .float-form__container .no-mrg {
  margin: 0px;
}
.float-form__wrapper .float-form__container .no-padding {
  padding: 0px;
}

.basic-text-input__wrapper {
  border-radius: 4px;
  display: flex;
  position: relative;
  width: 100%;
  padding: 0px 12px 0px 12px;
  min-height: fit-content;
  cursor: text;
  z-index: 2;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.35);
}
@media (hover: hover) {
  .basic-text-input__wrapper:hover {
    border: 1px solid #f67512;
  }
}
.basic-text-input__wrapper .basic-text-input__container {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  overflow-y: hidden;
  resize: none;
  z-index: 3;
  outline: none;
  border: none;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-right: 72px;
}
@media (max-width: 480px) {
  .basic-text-input__wrapper .basic-text-input__container {
    width: 100%;
  }
}
.basic-text-input__wrapper .basic-text-input__container::placeholder {
  opacity: 0.5;
}
.basic-text-input__wrapper .basic-text-input__container:focus {
  outline: none;
}

.basic-text-input__wrapper--focus {
  outline: 1px solid #f67512;
  border: 1px solid #f67512;
}

.calendar__wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 100%;
  min-width: fit-content;
}
.calendar__wrapper h2 {
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
}
@media (max-width: 480px) {
  .calendar__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
  }
}

.react-calendar {
  outline: 1px solid rgba(0, 0, 0, 0.4);
  border: none !important;
}
@media (max-width: 480px) {
  .react-calendar {
    min-width: 100%;
  }
}
.react-calendar:hover {
  outline: 1px solid #f67512;
}
.react-calendar__navigation {
  place-content: center;
  background: #f67512;
  padding-bottom: 3px;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f67512;
  cursor: not-allowed;
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: #f67512;
}
.react-calendar__navigation__label {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  max-width: 136px;
  color: #ffffff;
  padding-top: 4px;
}
.react-calendar__navigation__next-button {
  font-size: 30px;
  color: #ffffff;
}
.react-calendar__navigation__prev-button {
  font-size: 30px;
  color: #ffffff;
}
.react-calendar__month-view__weekdays {
  font-size: 12px;
}

.react-calendar__navigation__arrow:focus-visible {
  outline: 3px solid blue;
  outline-offset: -6px;
}

.react-calendar__tile:disabled {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.15);
  cursor: default;
}
.react-calendar__tile:enabled:hover {
  background-color: #f6974e;
  color: #ffffff;
}
.react-calendar__tile:enabled:focus {
  background-color: #f67512;
  color: #ffffff;
}
.react-calendar__tile--now {
  background: white;
}
.react-calendar__tile--active {
  background: #f67512;
  color: #ffffff !important;
  outline: 2px solid #f67512;
}
.react-calendar__tile button:disabled {
  cursor: not-allowed;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.15);
}

.react-calendar__month-view__days__day {
  font-family: "Open Sans", sans-serif;
  color: #333333;
  font-weight: 600;
}
.react-calendar__month-view__days__day--weekend {
  font-family: "Open Sans", sans-serif;
  color: #f67512;
  font-weight: 600;
}

.calendar__output h1,
.calendar__output h2 {
  padding-top: 10px;
  padding-bottom: 16px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.calendar__output h2 {
  padding-left: 10px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  cursor: default;
}

.dropdown-multiple {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 350px;
  max-width: 350px;
}
@media (max-width: 480px) {
  .dropdown-multiple {
    width: 100%;
    min-width: 0px;
  }
}

.dropdown__wrapper {
  display: flex;
  flex-direction: row;
  width: fit-content;
  color: #333333;
}
@media (max-width: 480px) {
  .dropdown__wrapper {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.dropdown__wrapper--fw {
  min-width: 100%;
}
.dropdown__wrapper .dropdown__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}
.dropdown__wrapper .dropdown__container play-bt label {
  font-weight: 600;
  font-size: 18px;
}
@media (max-width: 480px) {
  .dropdown__wrapper .dropdown__container {
    width: 100%;
    min-width: 100%;
  }
}
.dropdown__wrapper .dropdown__button {
  align-items: center;
  border-radius: 4px;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 44px;
  padding: 12px 20px 12px 20px;
  position: relative;
}
@media (max-width: 480px) {
  .dropdown__wrapper .dropdown__button {
    min-width: 100%;
  }
}
.dropdown__wrapper .dropdown__button--fw {
  min-width: 100%;
}
.dropdown__wrapper .dropdown__button-enabled {
  border: 1px solid #f67512;
}
.dropdown__wrapper .dropdown__button .arrow {
  height: 9px;
  margin-left: 6px;
  opacity: 0.7;
}
.dropdown__wrapper .dropdown__button .arrow img {
  filter: invert(12%) sepia(86%) saturate(0%) hue-rotate(184deg) brightness(103%) contrast(84%);
}
.dropdown__wrapper .remove-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 6px;
  top: 0;
  bottom: 0;
  min-height: 44px;
  max-height: 44px;
  min-width: 44px;
  max-width: 44px;
  z-index: 8;
  color: #333333;
}
.dropdown__wrapper .remove-btn img {
  padding: 2px;
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(93%) contrast(92%);
  height: 24px;
}
@media (hover: hover) {
  .dropdown__wrapper .remove-btn:hover img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}
.dropdown__wrapper .remove-btn:focus-visible img {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}
.dropdown__wrapper .remove-btn--dropdown-multiple {
  top: 5px;
}
.dropdown__wrapper .remove-btn--standalone {
  top: 0;
  bottom: 0;
  min-height: 100%;
}
.dropdown__wrapper .highlight {
  background-color: #f67512;
  color: #ffffff;
}

.dropdown-min-width {
  min-width: 350px;
}

.dropdown-multiple--full-width {
  min-width: 100%;
  max-width: 100%;
}

.showopts-wrapper {
  position: relative;
  display: flex;
  place-content: center;
  width: 100%;
}

.showopts {
  background-color: white;
  position: absolute;
  max-height: 154px;
  min-height: 154px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  scroll-behavior: smooth;
  z-index: inherit;
}
.showopts h1 {
  font-size: 18px;
}
.showopts button {
  margin-right: 10px;
  width: 100%;
  font-weight: 400;
  font-size: 20px;
  z-index: 8;
  padding-left: 18px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: #333333;
}

.showopts::-webkit-scrollbar {
  min-width: 24px;
}

.showopts::-webkit-scrollbar-thumb {
  background-color: #f6974e;
}

.showopts::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.dropdown-tz__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: fit-content;
}
@media (max-width: 480px) {
  .dropdown-tz__wrapper {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.dropdown-tz__wrapper--fw {
  width: 100%;
}
.dropdown-tz__wrapper .dropdown-tz__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 480px) {
  .dropdown-tz__wrapper .dropdown-tz__container {
    width: 100%;
  }
}
.dropdown-tz__wrapper .dropdown-tz__container--fw {
  width: 100%;
}
.dropdown-tz__wrapper .dropdown-tz__button {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px 12px 20px;
  min-height: 52px;
  color: #333333;
}
@media (max-width: 480px) {
  .dropdown-tz__wrapper .dropdown-tz__button {
    min-width: 100%;
  }
}
.dropdown-tz__wrapper .dropdown-tz__button--fw {
  min-width: 100%;
}
.dropdown-tz__wrapper .dropdown-tz__button .arrow {
  height: 9px;
  margin-left: 6px;
  opacity: 0.7;
}
.dropdown-tz__wrapper .dropdown-tz__button .arrow img {
  filter: invert(12%) sepia(86%) saturate(0%) hue-rotate(184deg) brightness(103%) contrast(84%);
}
.dropdown-tz__wrapper .search-tz-wrapper {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: white;
  top: -80px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.dropdown-tz__wrapper .search-tz {
  display: flex;
  align-items: center;
  min-width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  z-index: inherit;
  color: #333333;
}
.dropdown-tz__wrapper .search-tz input {
  min-height: 44px;
  outline: 1px solid rgba(0, 0, 0, 0.35);
  width: 85%;
  margin: 12px;
  padding-left: 14px;
}
@media (hover: hover) {
  .dropdown-tz__wrapper .search-tz input:hover {
    outline: 1px solid #f67512;
  }
}
.dropdown-tz__wrapper .search-tz input:focus-visible {
  border: 1px solid #f67512;
  outline: 1px solid #f67512;
}
@media (max-width: 320px) {
  .dropdown-tz__wrapper .search-tz input {
    font-size: 14px;
  }
}
.dropdown-tz__wrapper .search-tz img {
  margin-left: 12px;
  z-index: inherit;
}
@media (max-width: 320px) {
  .dropdown-tz__wrapper .search-tz img {
    width: 21px;
  }
}
.dropdown-tz__wrapper .showopts-tz-wrapper {
  display: flex;
  flex-direction: column;
  place-content: center;
  width: 100%;
}
.dropdown-tz__wrapper .showopts-tz-wrapper .showopts-tz {
  background-color: white;
  max-height: 278px;
  min-height: 278px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
}
.dropdown-tz__wrapper .showopts-tz-wrapper .showopts-tz button {
  margin-right: 10px;
  width: 100%;
  min-height: 44px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  text-align: left;
  padding: 8px 9px 8px 18px;
  color: #333333;
}
.dropdown-tz__wrapper .showopts-tz-wrapper .showopts-tz .highlight {
  background-color: #f67512;
  color: #ffffff;
}
.dropdown-tz__wrapper .showopts-tz-wrapper .showopts-tz::-webkit-scrollbar {
  min-width: 24px;
}
.dropdown-tz__wrapper .showopts-tz-wrapper .showopts-tz::-webkit-scrollbar-thumb {
  background-color: #f6974e;
}
.dropdown-tz__wrapper .showopts-tz-wrapper .showopts-tz::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

.file-uploader__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  max-width: fit-content;
  -webkit-tap-highlight-color: transparent;
}
.file-uploader__wrapper .file-uploader__container {
  background-size: cover;
  background: url(../assets/icons/camera_cropper.svg) no-repeat center;
  cursor: pointer;
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(93%) contrast(92%);
  min-width: 100%;
  outline: 2px solid #000;
}
@media (hover: hover) {
  .file-uploader__wrapper .file-uploader__container:hover {
    filter: invert(60%) sepia(48%) saturate(628%) hue-rotate(339deg) brightness(104%) contrast(93%);
    outline: 2px solid invert(60%) sepia(48%) saturate(628%) hue-rotate(339deg) brightness(104%) contrast(93%);
  }
}
.file-uploader__wrapper .file-uploader__container:focus-visible, .file-uploader__wrapper .file-uploader__container:active {
  filter: invert(60%) sepia(48%) saturate(628%) hue-rotate(339deg) brightness(104%) contrast(93%);
  outline: 2px solid invert(60%) sepia(48%) saturate(628%) hue-rotate(339deg) brightness(104%) contrast(93%);
}
@media (max-width: 480px) {
  .file-uploader__wrapper .file-uploader__container {
    max-width: 100%;
  }
}

.file-uploader__wrapper--profile {
  background-image: url(../assets/images/account_img_default.png);
  background-size: contain;
  border-radius: 50%;
}

.file-uploader__container--profile {
  border-radius: 50%;
}

.file-uploader__wrapper--event {
  background-image: url(../assets/images/event_img_default.png);
  background-size: cover;
}
@media (max-width: 480px) {
  .file-uploader__wrapper--event {
    max-width: 100%;
    background-position: center;
  }
}

.file-uploader__wrapper--speaker {
  background: url(../assets/images/banner_img_default.png) no-repeat center;
  background-size: cover;
}
@media (max-width: 480px) {
  .file-uploader__wrapper--speaker {
    max-width: 100%;
    background-position: center;
  }
}

.image-cropper__wrapper {
  position: relative;
  flex-direction: column;
  display: flex;
}
@media (max-width: 480px) {
  .image-cropper__wrapper {
    max-width: 100%;
    margin: auto;
    align-items: center;
  }
}

.cropper-center {
  align-items: center;
}

.cropper__container__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cropper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.profile-circle-frame {
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 264px;
  min-height: 264px;
  max-width: 264px;
  max-height: 264px;
  position: absolute;
  opacity: 0.7;
  pointer-events: none;
}

.loading-frame__wrapper {
  display: flex;
  outline: 2px solid rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (hover: hover) {
  .loading-frame__wrapper:hover {
    outline: 2px solid #f67512;
  }
}

.pac-container {
  position: absolute;
  top: 100%;
  left: 0;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: none;
  margin-left: 2px;
  max-height: fit-content;
}

.pac-item {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  color: #3d3d3d;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  white-space: nowrap;
  background: none;
  border: 0;
  cursor: pointer;
}

.pac-item:hover,
.pac-item:focus {
  color: #fff;
  text-decoration: none;
  background-color: #f67512;
}

.pac-item-selected {
  text-decoration: none;
  background-color: #f67512;
}

.pac-icon {
  display: none;
}

.pac-item-query {
  font-size: 16px;
  color: #2e2e2e;
  font-weight: bold;
  margin-left: 1em;
}

.pac-container:after {
  background-image: none;
  height: 0;
}

.whatsthis__wrapper {
  border-radius: 18px;
  min-height: 44px;
  white-space: pre-wrap;
}
.whatsthis__wrapper .heading {
  margin-top: 2px;
}
.whatsthis__wrapper--active {
  padding: 6px;
  line-height: 26px;
}
.whatsthis__wrapper--active .heading {
  font-weight: 600;
}
@media (max-width: 480px) {
  .whatsthis__wrapper {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.whatsthis {
  position: relative;
  display: flex;
  width: fit-content;
  flex-direction: column;
  padding: 6px 12px;
}
.whatsthis .info {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  min-width: 25px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0.25);
  transition: 0.25s;
  margin-right: 6px;
  color: rgba(0, 0, 0, 0.55);
}
.whatsthis .active {
  background-color: #f67512;
  color: #ffffff;
  border: 0px;
}

.drag-drop-ghost {
  position: absolute;
  height: 40px;
  width: 250px;
  background-color: rgb(194, 228, 194);
  border: 1px solid rgba(0, 0, 0, 0.5);
  top: -5000px;
  left: -5000px;
}

.drag-drop__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}
.drag-drop__wrapper .memoized-item__wrapper {
  min-width: 100%;
  padding: 18px 12px;
  list-style-type: none;
  border-radius: 4px;
  cursor: grab;
  overflow-anchor: none;
}
@media (max-width: 1280px) {
  .drag-drop__wrapper .memoized-item__wrapper {
    cursor: default;
  }
}
@media (max-width: 320px) {
  .drag-drop__wrapper .memoized-item__wrapper {
    padding-right: 6px;
  }
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item--selected {
  visibility: hidden;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-anchor: none;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .move-arrows {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 36px;
  align-items: center;
  padding-right: 6px;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .move-arrows .arrow-down {
  width: 16px;
  transform: rotate(180deg);
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .move-arrows .arrow-up {
  width: 16px;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .move-arrows .hidden {
  visibility: hidden;
  cursor: default;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .memoized-item-fields__container {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
}
@media (max-width: 824px) {
  .drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .memoized-item-fields__container {
    margin-bottom: 12px;
  }
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .clock-container {
  display: flex;
  align-items: center;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .clock-container p {
  min-width: 96px;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .agenda-icon-row {
  display: flex;
  align-items: center;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .agenda-icon-row .ddl-select-icon-btn {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: white;
  align-items: center;
  justify-content: center;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .agenda-icon-row .ddl-select-icon-btn img {
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
}
@media (max-width: 824px) {
  .drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .agenda-icon-row .ddl-select-icon-btn {
    margin-left: 0px;
  }
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .textarea {
  position: relative;
  display: flex;
  flex-grow: 1;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .textarea textarea {
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  resize: none;
  overflow-y: hidden;
  padding: 10px;
  padding-right: 64px;
  width: 100%;
  height: 40px;
  margin-right: 12px;
}
@media (max-width: 824px) {
  .drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .textarea textarea {
    margin-right: 0px;
  }
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .textarea .charCount {
  position: absolute;
  right: 20px;
  top: 12.5px;
  color: rgba(0, 0, 0, 0.5);
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .minutes-input {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .minutes-input input {
  height: 40px;
  width: 52px;
  padding: 10px;
  padding-top: 12px;
  cursor: text;
  overflow-y: hidden;
  text-align: right;
  border-radius: 4px;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .minutes-input input::-webkit-outer-spin-button, .drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .minutes-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .minutes-input input[type=number] {
  -moz-appearance: textfield;
}
@media (max-width: 824px) {
  .drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .minutes-input input {
    margin-left: 24px;
  }
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .clocks-dash {
  height: 2px;
  background-color: #333333;
  width: 24px;
  margin-left: 12px;
  margin-right: 12px;
  opacity: 0.5;
  border-radius: 20%;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper .clock__container {
  position: relative;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper input {
  cursor: text;
  height: 40px;
  border-radius: 4px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  padding: 2px 12px 0px 12px;
}
.drag-drop__wrapper .memoized-item__wrapper .memoized-item__container .memoized-item-fields__wrapper input[type=time]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(60%) sepia(48%) saturate(628%) hue-rotate(339deg) brightness(104%) contrast(93%);
  transform: scale(1.2);
}
.drag-drop__wrapper .memoized-item__wrapper .trashbin-ddl {
  height: 44px;
  width: 44px;
  padding: 6px;
  margin-left: auto;
}
.drag-drop__wrapper .placeholder {
  border: 3px dashed green;
  opacity: 0.5;
  height: 139px;
}

.btn-switch__wrapper {
  display: flex;
  font-weight: 400;
  text-align: left;
  white-space: pre-wrap;
  align-items: flex-start;
  flex-direction: column-reverse;
}
.btn-switch__wrapper .btn-switch__description {
  cursor: pointer;
  font-size: 18px;
  margin-left: 20px;
  margin-bottom: 12px;
  margin-left: 0px;
  white-space: pre-wrap;
  -webkit-tap-highlight-color: transparent;
}
@media (max-width: 320px) {
  .btn-switch__wrapper .btn-switch__description {
    font-size: 16px;
  }
}
@media (hover: hover) {
  .btn-switch__wrapper .btn-switch__description:hover {
    color: #f67512;
  }
}
.btn-switch__wrapper .btn-switch {
  align-items: center;
  background-color: #aaaaaa;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  justify-content: flex-end;
  min-height: 36px;
  min-width: 76px;
  position: relative;
}
.btn-switch__wrapper .btn-switch__text-btn {
  margin-right: 10px;
}
.btn-switch__wrapper .btn-switch--active {
  background-color: #f67512;
  justify-content: flex-start;
}
.btn-switch__wrapper .btn-switch--active p {
  margin-left: 10px;
}
.btn-switch__wrapper .btn-switch .btn-switch__circle {
  background-color: white;
  border-radius: 50%;
  display: flex;
  height: 28px;
  position: absolute;
  right: 55%;
  width: 28px;
}
.btn-switch__wrapper .btn-switch .btn-switch__circle--active {
  background-color: white;
  right: 5%;
}

.btn-switch-horizontal {
  align-items: center;
  flex-direction: row;
}
.btn-switch-horizontal .btn-switch__description {
  margin-left: 20px;
  margin-bottom: 0px;
}

.btn-toggle__wrapper {
  display: flex;
  font-weight: 400;
  white-space: pre-wrap;
  flex-direction: row;
  align-items: center;
  min-height: 44px;
}
.btn-toggle__wrapper .btn-toggle__description {
  cursor: pointer;
  font-size: 18px;
  white-space: pre-wrap;
  -webkit-tap-highlight-color: transparent;
}
@media (max-width: 320px) {
  .btn-toggle__wrapper .btn-toggle__description {
    font-size: 16px;
  }
}
@media (hover: hover) {
  .btn-toggle__wrapper .btn-toggle__description:hover {
    color: #f67512;
  }
}
.btn-toggle__wrapper .btn-toggle {
  align-items: center;
  border-radius: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  justify-content: flex-end;
  min-height: 36px;
  min-width: 76px;
  position: relative;
  background-color: #aaaaaa;
}
.btn-toggle__wrapper .btn-toggle__text-btn {
  margin-right: 10px;
}
.btn-toggle__wrapper .btn-toggle--active {
  justify-content: flex-start;
}
.btn-toggle__wrapper .btn-toggle--active p {
  margin-left: 10px;
}
.btn-toggle__wrapper .btn-toggle .btn-toggle__circle {
  background-color: white;
  border-radius: 50%;
  display: flex;
  height: 28px;
  position: absolute;
  right: 55%;
  width: 28px;
}
.btn-toggle__wrapper .btn-toggle .btn-toggle__circle--active {
  background-color: white;
  right: 5%;
}

.item-button-ghost {
  align-items: center;
  background-color: #eee;
  border-radius: 4px;
  outline: 1px solid rgba(0, 0, 0, 0.4);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  min-height: 52px;
  max-height: fit-content;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  color: #333333;
}

.item-button {
  align-items: center;
  background-color: #eee;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  min-height: 52px;
  max-height: 52px;
  justify-content: center;
  padding: 10px;
  color: #333333;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (max-width: 480px) {
  .item-button {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    min-width: 100%;
  }
}
.item-button .plus {
  font-size: 24px;
  margin-right: 10px;
}

.item-button--bg-blue {
  background-color: #3c94f3;
  color: white;
  font-weight: 600;
}

.item-button-standard {
  max-width: 350px;
  min-width: 350px;
}
@media (max-width: 480px) {
  .item-button-standard {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    min-width: 100%;
  }
}

.item-button-short {
  max-width: 240px;
  min-width: 240px;
}
@media (max-width: 480px) {
  .item-button-short {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    min-width: 100%;
  }
}

.item-button-full {
  max-width: 100%;
  min-width: 100%;
}

.item-button-flex {
  max-width: 350px;
  width: 100%;
}
@media (max-width: 480px) {
  .item-button-flex {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    min-width: 100%;
  }
}

.item-white {
  background-color: #ffffff;
}

.trashbin {
  background-color: invert(98%) sepia(98%) saturate(0%) hue-rotate(330deg) brightness(102%) contrast(104%);
}

.button-meta__container {
  position: relative;
  display: flex;
  flex-direction: row;
  min-width: 100%;
  align-items: center;
}
@media (max-width: 860px) {
  .button-meta__container {
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 480px) {
  .button-meta__container {
    flex-direction: column;
  }
}

.button-meta-width {
  max-width: 240px;
  min-width: 164px;
}
@media (max-width: 860px) {
  .button-meta-width {
    min-width: 100%;
  }
}
@media (max-width: 480px) {
  .button-meta-width {
    max-width: 100%;
  }
}

.button-meta {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  padding: 12px;
  position: relative;
  max-height: 54px;
  min-height: 54px;
  width: fit-content;
  background-color: white;
}
.button-meta p {
  white-space: wrap;
}
.button-meta img {
  height: 24px;
}
@media (max-width: 860px) {
  .button-meta {
    margin-bottom: 36px;
  }
  .button-meta:last-child {
    margin-bottom: 0px;
  }
}
.button-meta--active {
  border: 2px solid #f67512;
  color: #f67512;
}
.button-meta .button-meta-dot {
  position: absolute;
  pointer-events: none;
  background-color: #f67512;
  opacity: 0.9;
  height: 8px;
  width: 8px;
  margin-top: 1px;
  margin-left: 12px;
  border-radius: 50%;
  bottom: -24px;
}

.confirm-selection__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 1280px) {
  .confirm-selection__wrapper {
    flex-direction: column;
  }
}
.confirm-selection__wrapper .confirm-selection__btn-container {
  display: flex;
  align-items: center;
  max-width: fit-content;
  justify-content: center;
  column-gap: 24px;
  row-gap: 24px;
}
@media (max-width: 1280px) {
  .confirm-selection__wrapper .confirm-selection__btn-container {
    flex-wrap: wrap;
  }
}
.confirm-selection__wrapper .confirm-selection__btn-container button {
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  justify-content: center;
  max-height: 48px;
  min-height: 48px;
  width: fit-content;
  min-width: 148px;
  padding: 0px 12px;
}
@media (max-width: 480px) {
  .confirm-selection__wrapper .confirm-selection__btn-container {
    flex-direction: column-reverse;
    justify-content: center;
    min-width: 0px;
  }
}

.confirm-selection__max-width {
  max-width: 280px;
}

.confirmation--pos {
  border: 2px solid #64c071;
}
@media (hover: hover) {
  .confirmation--pos:hover {
    color: #ffffff !important;
  }
}

.confirmation--neg {
  border: 2px solid #dc2c2c;
}

.switch-option__container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: fit-content;
  color: #333333;
}
@media (max-width: 480px) {
  .switch-option__container {
    white-space: pre-wrap;
  }
}
.switch-option__container img {
  max-height: 18px;
}

.new-window-button {
  align-items: center;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.35);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  color: #777777;
  display: flex;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  min-height: 44px;
  opacity: 0.9;
  padding: 6px 18px;
  width: fit-content;
  color: #333333;
}
.new-window-button img {
  filter: invert(71%) sepia(0%) saturate(1637%) hue-rotate(286deg) brightness(80%) contrast(87%);
  height: 21px;
  margin-left: 12px;
}
@media (hover: hover) {
  .new-window-button:hover {
    background-color: #f67512;
    border: 2px solid #f67512;
    color: #ffffff;
  }
  .new-window-button:hover img {
    filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(330deg) brightness(102%) contrast(104%);
  }
}
@media (max-width: 480px) {
  .new-window-button {
    font-size: 16px;
    margin: 0px 12px;
    white-space: break-spaces;
    width: fit-content;
  }
}

.radio-input {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.radio-input .radio-dot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.radio-input .radio-dot::after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
}
.radio-input input[type=radio] {
  display: none;
}
.radio-input input[type=radio]:checked,
.radio-input .radio-dot::after {
  opacity: 1;
}

.btn-action {
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  color: #777777;
  display: flex;
  font-size: 21px;
  font-weight: 700;
  justify-content: center;
  min-width: 298px;
  padding: 12px;
  width: fit-content;
  border: 3px solid #777777;
  max-height: fit-content;
  min-height: 52px;
  align-items: center;
}
@media (max-width: 480px) {
  .btn-action {
    font-size: 18px;
    min-width: 220px;
  }
}
@media (max-width: 320px) {
  .btn-action {
    min-width: 196px;
  }
}

.remove-button {
  display: flex;
  justify-content: center;
  min-width: 100%;
  min-height: 44px;
  min-width: 44px;
  align-items: center;
}
@media (hover: hover) {
  .remove-button:hover img, .remove-button:focus-visible img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}

.text-btn {
  font-weight: 600;
  border-radius: 10px;
  width: fit-content;
  border: 2px solid rgba(0, 0, 0, 0.35);
  color: #777777;
  font-size: 16px;
}

.follow-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6px;
  font-weight: 600;
  border-radius: 10px;
  width: fit-content;
  color: #777777;
  font-size: 16px;
  min-height: 44px;
  max-height: 44px;
  padding: 0px 10px;
  transition: 0ms ease-in-out;
}

.button-save {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 16px;
  min-width: 148px;
  max-width: fit-content;
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  color: #ccc;
  max-height: 45px;
  min-height: 45px;
  border: 1px solid #ccc;
  outline: 1px solid rgba(0, 0, 0, 0);
  cursor: default;
}
@media (max-width: 480px) {
  .button-save {
    margin-right: auto;
  }
}
.button-save--active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 16px;
  min-width: 148px;
  max-width: fit-content;
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #f67512;
  outline: 1px solid #f67512;
  max-height: 45px;
  min-height: 45px;
  color: #f67512;
  cursor: pointer;
}
@media (hover: hover) {
  .button-save--active:hover {
    background-color: #f67512;
    color: #ffffff;
  }
}

.change-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 16px;
  min-width: 148px;
  max-width: fit-content;
  font-family: "Poppins", sans-serif;
  border-radius: 4px;
  color: #777777;
  max-height: 45px;
  min-height: 45px;
}
@media (max-width: 480px) {
  .change-btn {
    margin-right: auto;
  }
}

.dropdown-arrow {
  min-height: 44px;
  min-width: 44px;
  padding: 14px;
}

.order-btn {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: auto;
  margin-top: auto;
  text-align: center;
  border-radius: 10px;
  padding: 2px 8px;
  transition: transform 500ms ease-in-out;
  white-space: nowrap;
}
.order-btn img {
  height: 18px;
  margin-left: 6px;
}

.button-text-simple {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  padding: 6px 12px;
  white-space: nowrap;
  min-height: 44px;
  color: #777777;
  min-width: fit-content;
  width: fit-content;
}

.button-text-simple__spinner-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.button-text-simple-border {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  outline: 1px solid rgba(0, 0, 0, 0.35);
}
.button-text-simple-border:focus-visible {
  outline: 3px solid blue;
  border: 1px solid #f67512;
  transition: none;
  outline-offset: 6px;
}
@media (hover: hover) {
  .button-text-simple-border:hover {
    border: 1px solid #f67512;
    outline: 1px solid #f67512;
  }
}

.button-text-simple-border--tap {
  border-radius: 10px;
  border: 1px solid #f67512;
  outline: 1px solid #f67512;
}

.block-btn {
  width: 100%;
  height: 100%;
  min-height: 80px;
  background-color: white;
}

.scrollToComponent__container {
  position: relative;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  height: 100%;
}
.scrollToComponent__container li {
  width: fit-content;
}

.scrollToComponent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 12px;
  border-radius: 18px;
  max-width: fit-content;
  align-items: center;
  text-align: left;
  margin-bottom: 18px;
  color: #333333;
}
.scrollToComponent img {
  max-height: 18px;
  transform: scaleX(-1);
}
@media (max-width: 480px) {
  .scrollToComponent {
    margin-bottom: 24px;
  }
}

.explorer-window {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: normal;
  max-width: inherit;
  -webkit-tap-highlight-color: transparent;
}
.explorer-window .explorer-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 136px;
}
@media (max-width: 320px) {
  .explorer-window .explorer-column {
    min-width: 40%;
  }
}
.explorer-window .explorer-heading {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
@media (hover: hover) {
  .explorer-window .explorer-heading:hover {
    background-color: #e9f3ff;
  }
}
.explorer-window .table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: fit-content;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 6px 12px;
  z-index: 3;
  text-align: left;
}
@media (max-width: 480px) {
  .explorer-window .table-row {
    flex-direction: column;
  }
}
@media (max-width: 320px) {
  .explorer-window .table-row {
    padding: 6px;
  }
}
.explorer-window h1 {
  font-size: 18px;
  font-weight: 600;
  padding: 6px 0px 6px 12px;
  white-space: nowrap;
}
@media (max-width: 320px) {
  .explorer-window h1 {
    font-size: 16px;
    padding: 6px 0px 6px 6px;
  }
}
.explorer-window .sort {
  height: 8px;
  padding-left: 12px;
  padding-right: 12px;
}
@media (max-width: 320px) {
  .explorer-window .sort {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.explorer-window .sort-ghost {
  min-width: 26px;
}
.explorer-window .active {
  background-color: #e9f3ff;
}
.explorer-window .selected {
  background-color: #d5e8ff;
}
.explorer-window .trash__container {
  display: flex;
  flex-direction: row;
  min-width: 44px;
  max-width: 44px;
  max-height: 44px;
  min-height: 44px;
  align-items: center;
  justify-content: center;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  margin: 6px;
}
@media (max-width: 480px) {
  .explorer-window .trash__container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (hover: hover) {
  .explorer-window .trash__container:hover img {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}
.explorer-window .trash__container:focus-visible img {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}
.explorer-window .trash__container--mobile {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  max-height: 44px;
  min-height: 44px;
  align-items: center;
  justify-content: center;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 6px;
}
@media (hover: hover) {
  .explorer-window .trash__container--mobile:hover {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}
.explorer-window .trash__container--mobile:focus-visible .trashbin {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}
.explorer-window .trashbin-selection-list {
  max-height: 21px;
}
.explorer-window .trashbin-selection-list {
  cursor: pointer;
  height: 21px;
  width: 21px;
}

.explorer-divider {
  width: 2px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  cursor: col-resize;
  background-color: rgba(0, 0, 0, 0.3);
}

.multi-selection-list {
  list-style-type: none;
  min-width: 396px;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 0px 24px;
}
@media (max-width: 480px) {
  .multi-selection-list {
    min-width: 100%;
    margin-top: 12px;
  }
}
@media (max-width: 320px) {
  .multi-selection-list {
    padding: 12px 12px 0px 12px;
  }
}
.multi-selection-list .multi-selection-item {
  align-items: center;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  margin-bottom: 12px;
  padding: 12px;
  transition-duration: 0.15s;
  width: 100%;
  text-align: left;
  color: #333333;
  position: relative;
}
.multi-selection-list .multi-selection-item img {
  max-height: 21px;
  position: absolute;
  right: 12px;
}
@media (hover: hover) {
  .multi-selection-list .multi-selection-item:hover {
    border: 2px solid #f67512;
    transform: scale(1.02);
  }
}
.multi-selection-list .multi-selection-item--selected {
  border: 2px solid #f67512;
  transform: scale(0.98);
}
.multi-selection-list .multi-selection-item--selected:active {
  border: 2px solid #f67512;
  transform: scale(0.98);
}

.code-input-wrapper {
  position: relative;
}
.code-input-wrapper .character-count {
  position: absolute;
  font-size: 18px;
  bottom: 12px;
  right: 12px;
}
.code-input-wrapper .code-input {
  background-color: transparent;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 24px 0px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.35);
  cursor: pointer;
  font-size: 28px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 24px;
  text-align: center;
}
@media (hover: hover) {
  .code-input-wrapper .code-input:hover {
    border: 2px solid #f67512;
  }
}
@media (max-width: 480px) {
  .code-input-wrapper .code-input {
    font-size: 21px;
  }
}
@media (max-width: 320px) {
  .code-input-wrapper .code-input {
    font-size: 14px;
    padding: 18px 0px;
  }
}
.code-input-wrapper .code-input:focus {
  border: 2px solid rgba(0, 0, 0, 0);
  outline: 2px solid #f67512;
  outline-offset: -2px;
}

.nav-arrows button {
  font-size: 24px;
}
@media (max-width: 480px) {
  .nav-arrows button {
    font-size: 21px;
  }
}
@media (max-width: 480px) {
  .nav-arrows .finish {
    font-size: 21px;
  }
}

.arrow {
  display: flex;
  align-items: center;
  height: 40px;
}
@media (max-width: 480px) {
  .arrow img {
    width: 64px;
  }
}
@media (max-width: 320px) {
  .arrow img {
    width: 52px;
  }
}

.focus-skip {
  position: absolute;
  border-radius: 18px;
  width: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  height: 44px;
  opacity: 0;
  z-index: 0;
  right: 10%;
  background-color: #f67512;
  color: #ffffff;
  z-index: inherit;
  cursor: default;
}
.focus-skip:focus {
  opacity: 1;
  cursor: pointer;
}

.dropdown-tags__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  max-width: 100%;
}
.dropdown-tags__wrapper .bg-icon {
  height: 21px;
  margin-right: 12px;
}
.dropdown-tags__wrapper .tags__container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 12px;
  row-gap: 12px;
  list-style: none;
}
.dropdown-tags__wrapper .tags__container li {
  display: flex;
  max-width: 100%;
}
@media (max-width: 480px) {
  .dropdown-tags__wrapper .tags__container {
    flex-wrap: nowrap;
    max-width: 90vw;
    flex-direction: column;
    min-width: 100%;
  }
}
.dropdown-tags__wrapper .tag__list-item {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 6px 12px;
  min-height: 44px;
  align-items: center;
  transition: transform 0.2s ease-in-out;
  width: fit-content;
  min-width: 44px;
  position: relative;
  background-color: white;
}
.dropdown-tags__wrapper .tag__list-item p {
  text-align: left;
  word-wrap: break-word;
  padding-right: 18px;
  max-width: 100%;
  color: #333333;
  pointer-events: none;
}
@media (max-width: 480px) {
  .dropdown-tags__wrapper .tag__list-item {
    min-width: 100%;
  }
}
.dropdown-tags__wrapper .tag__list-item--add {
  border: 2px solid #64c071;
}
@media (hover: hover) {
  .dropdown-tags__wrapper .tag__list-item--add:hover {
    border: 2px solid #64c071;
  }
}
.dropdown-tags__wrapper .tag__list-item--no-btn {
  display: flex;
  border-radius: 10px;
  padding: 12px;
  min-height: 44px;
  align-items: center;
  width: fit-content;
  min-width: 44px;
  background-color: white;
}
.dropdown-tags__wrapper .tag__list-item--no-btn p {
  text-align: left;
  word-wrap: break-word;
  padding-right: 32px;
  max-width: 100%;
  color: #333333;
  pointer-events: none;
}
@media (max-width: 480px) {
  .dropdown-tags__wrapper .tag__list-item--no-btn {
    min-width: 100%;
  }
}

.new-tag {
  border: 2px solid #f67512 !important;
}
.new-tag:hover {
  border: 2px solid #f67512;
}

.tg {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  list-style: none;
  max-width: 90vw;
  -webkit-tap-highlight-color: transparent;
  background-color: white;
}
.tg .tg__wrapper {
  border-radius: 10px;
  cursor: pointer;
}
.tg .tg__wrapper--active {
  border: 2px solid #64c071;
}
.tg .tg__container {
  display: flex;
  column-gap: 12px;
  row-gap: 12px;
  margin-left: 12px;
  margin-right: 12px;
  flex-wrap: wrap;
  max-width: 100%;
}
.tg .tg__container .tg__tag {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  min-height: 44px;
  min-width: 44px;
  padding: 6px 12px;
  position: relative;
  transition: transform 0.2s ease-in-out;
  width: fit-content;
}
.tg .tg__container .tg__tag p {
  width: 100%;
  text-align: left;
  word-wrap: break-word;
  padding-right: 32px;
  color: #333333;
}
.tg .tg__container .tg__tag img {
  position: absolute;
  right: 6px;
  margin-left: 12px;
  height: 14px;
  transition: transform 0.25s ease-in;
  min-width: 28px;
}
@media (max-width: 480px) {
  .tg .tg__container .tg__tag {
    min-width: 100%;
  }
}
.tg .tg__container .tg__tag--add {
  border: 2px solid #64c071;
}
.tg .tg__container .tg__tag--add:hover {
  border: 2px solid #64c071;
}
.tg .tg__container .tG-summary-tags__container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  list-style: none;
}
.tg .tg__container .tG-summary-tags__container .summary-tag {
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  margin: 6px;
  padding: 8px;
  max-width: 100%;
}
.tg .tg__container .tG-summary-tags__container .summary-tag li {
  word-wrap: break-word;
}
.tg .tg__container .tG-summary-tags__container .summary-tag p {
  width: 100%;
  word-wrap: break-word;
  color: #333333;
}
.tg .tg__container .tg-arrow {
  max-height: 48px;
  max-width: 48px;
  filter: invert(85%) sepia(0%) saturate(1813%) hue-rotate(184deg) brightness(93%) contrast(92%);
}
.tg .tg__container .tg-button {
  min-height: 44px;
  min-width: 44px;
  background-color: invert(98%) sepia(98%) saturate(0%) hue-rotate(330deg) brightness(102%) contrast(104%);
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  color: #333333;
}
.tg .tg__container .tg-button img {
  height: 28px;
  width: 28px;
}
.tg .tg__container .tg-button .edit {
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
  max-width: 32px;
}
.tg .tg__container .tg-button .trash {
  margin-top: 2px;
}
.tg .tg-heading {
  display: flex;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 12px;
  min-height: 64px;
  max-height: fit-content;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
@media (max-width: 480px) {
  .tg .tg-heading {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
  }
}
.tg .tg-heading .heading-arrow {
  margin-left: 24px;
  width: 21px;
}
@media (max-width: 480px) {
  .tg .tg-heading .heading-arrow {
    margin-left: 12px;
  }
}
.tg .tg-heading .title-tags-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}
@media (max-width: 480px) {
  .tg .tg-heading .title-tags-row {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.tg .tg-heading .group-name {
  display: flex;
  align-items: center;
  white-space: pre-wrap;
}
@media (max-width: 480px) {
  .tg .tg-heading .group-name {
    display: none;
  }
}
.tg .tg-heading .group-name-ph {
  display: none;
  margin-left: 12px;
}
@media (max-width: 480px) {
  .tg .tg-heading .group-name-ph {
    display: flex;
    align-items: center;
  }
}
.tg .tg-heading input {
  height: 36px;
  width: 100%;
  border-bottom: 1px solid #8f8f8f;
  padding-right: 12px;
}
.tg .tg-heading input:hover {
  outline: none;
  border-bottom: 1px solid #f67512;
}
.tg .tg-heading input:focus {
  outline: none;
  border-bottom: 2px solid #f67512;
}
.tg .tg-heading--active {
  border-bottom: 2px solid #64c071;
}
.tg .tg-heading--opened {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.tg .tg-heading--inactive {
  border-bottom: 0px solid #8f8f8f;
}

.custom-tags-list__wrapper {
  position: relative;
  width: 100%;
  color: #333333;
}
.custom-tags-list__wrapper .custom-tags-input {
  resize: none;
  max-height: 52px;
  width: 100%;
  outline: #8f8f8f solid 1px;
  padding-left: 48px;
  padding-top: 14px;
  padding-right: 15px;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
}
@media (hover: hover) {
  .custom-tags-list__wrapper .custom-tags-input:hover {
    outline: 1px solid #ff480f;
  }
}
.custom-tags-list__wrapper .custom-tags-input:focus {
  outline: 2px solid #f67512;
}
.custom-tags-list__wrapper .input-label {
  height: 28px;
  margin-right: 12px;
}
.custom-tags-list__wrapper .dropdown__hash {
  position: absolute;
  left: 18px;
  top: 12px;
  font-size: 21px;
  height: 48px;
}

.drag-and-drop-area {
  border: 2px dashed rgba(0, 0, 0, 0.2);
  width: 100%;
  min-height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.drag-and-drop-area label {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
}

.drag-and-drop--dragOver {
  border: 2px dashed #f67512;
  color: #f67512;
}
.drag-and-drop--dragOver img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}

.keywords-file-input {
  opacity: 0;
  cursor: pointer;
  height: 0;
}

.keywords-list__wrapper {
  position: relative;
  width: 100%;
  color: #333333;
}
.keywords-list__wrapper .keywords-input {
  resize: none;
  max-height: 52px;
  width: 100%;
  outline: #8f8f8f solid 1px;
  padding-left: 16px;
  padding-top: 14px;
  padding-right: 16px;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
}
@media (hover: hover) {
  .keywords-list__wrapper .keywords-input:hover {
    outline: 1px solid #ff480f;
  }
}
.keywords-list__wrapper .keywords-input:focus {
  outline: 2px solid #f67512;
}
.keywords-list__wrapper .input-label {
  position: absolute;
  left: 2px;
  top: 2px;
  height: 48px;
}
.keywords-list__wrapper .keywords-list__loading-bar {
  height: 8px;
  min-width: 100%;
  border-radius: 8px;
  display: flex;
  background-color: #ccc;
  position: relative;
  z-index: 3;
}
.keywords-list__wrapper .keywords-list__loading-bar--progress {
  position: absolute;
  background-color: #64c071;
  border-radius: 8px;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 4;
  transition: width 0.5s ease-out;
}

.generic-modal--transitions {
  transition: opacity 250ms ease-in;
}

.generic-modal--fade-out {
  opacity: 0;
  visibility: hidden;
}

.generic-modal--fade-in {
  opacity: 1;
  visibility: visible;
}

.generic-modal {
  position: fixed;
  z-index: 8;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
}
.generic-modal .generic-modal-overlay {
  position: fixed;
  background-color: black;
  min-width: 100%;
  opacity: 0.55;
  display: flex;
  min-height: 100%;
  top: 0;
  right: 0;
  z-index: inherit;
}
.generic-modal .generic-modal__container {
  height: fit-content;
  max-height: 100%;
  z-index: 9;
  margin-top: auto;
  margin-bottom: auto;
}
@media (max-width: 480px) {
  .generic-modal .generic-modal__container {
    position: fixed;
    bottom: 0;
    top: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.generic-modal .generic-modal__container-mobilefloat {
  height: fit-content;
  max-height: 100%;
  z-index: 9;
  margin-top: auto;
  margin-bottom: auto;
}

.modal-basic__wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  max-height: 820px;
  z-index: inherit;
  max-width: 512px;
}
@media (max-width: 480px) {
  .modal-basic__wrapper {
    border-radius: 0px;
    width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
  }
}
.modal-basic__wrapper .nav-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  min-height: 44px;
}
.modal-basic__wrapper .heading {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
  display: flex;
  min-height: 54px;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  margin-bottom: 2px;
  width: 100%;
  z-index: 10;
}
@media (max-width: 480px) {
  .modal-basic__wrapper .heading {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    top: 0;
    background-color: #ffffff;
    width: 100%;
  }
}
.modal-basic__wrapper .heading__text-input {
  display: flex;
  align-items: center;
  min-width: 100%;
  height: 54px;
  margin-bottom: 12px;
}
.modal-basic__wrapper .heading__text-input input {
  align-items: center;
  justify-content: center;
  min-height: 44px;
  outline: 1px solid rgba(0, 0, 0, 0.35);
  margin: 12px;
  padding-left: 14px;
  min-width: 85%;
}
.modal-basic__wrapper .heading__text-input input:hover {
  outline: 1px solid #f67512;
}
.modal-basic__wrapper .heading__text-input input:focus {
  outline: 2px solid #f67512;
}
@media (max-width: 320px) {
  .modal-basic__wrapper .heading__text-input img {
    width: 21px;
  }
}
.modal-basic__wrapper .modal-basic__container {
  list-style-type: none;
  min-width: 396px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 0px 24px;
  height: 100vh;
}
@media (max-width: 480px) {
  .modal-basic__wrapper .modal-basic__container {
    min-width: 100%;
    margin-top: 12px;
  }
}
@media (max-width: 320px) {
  .modal-basic__wrapper .modal-basic__container {
    padding: 12px 12px 0px 12px;
  }
}
.modal-basic__wrapper .modal-basic__container .item {
  align-items: center;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  margin-bottom: 12px;
  padding: 12px;
  width: 100%;
  text-align: left;
  color: #333333;
}
@media (hover: hover) {
  .modal-basic__wrapper .modal-basic__container .item:hover {
    border: 2px solid #f67512;
    transform: scale(1.02);
  }
}
.modal-basic__wrapper .modal-basic__container .item--w-subText {
  min-height: 68px;
  max-height: 68px;
}
.modal-basic__wrapper .modal-basic__container .item-selected-multiple {
  border: 2px solid #f67512;
  transform: scale(1.02);
}
.modal-basic__wrapper .modal-basic__container .item-selected-multiple:active {
  border: 2px solid #f67512;
  transform: scale(1.02);
}
.modal-basic__wrapper .modal-basic__container .item-selected {
  border: 2px solid #f67512;
  transform: scale(0.98);
}
.modal-basic__wrapper .modal-basic__container .item-selected:active {
  border: 2px solid #f67512;
  transform: scale(0.98);
}
.modal-basic__wrapper .modal-basic__container--text-input-mrg {
  margin-top: 142px;
}
.modal-basic__wrapper .modal-warning-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(255, 226, 64);
  padding: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
}
.modal-basic__wrapper .close-btn img {
  margin-top: 3px;
  min-width: 44px;
  padding: 12px;
}
.modal-basic__wrapper .mobile-transition-modal-selection {
  border: 2px solid #f67512;
  transform: scale(1.02);
}
.modal-basic__wrapper .valid {
  color: #64c071;
}
.modal-basic__wrapper .invalid {
  color: #dc2c2c;
}
.modal-basic__wrapper .modal-basic__footer {
  align-items: center;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.35);
  display: flex;
  min-height: 54px;
  max-height: fit-content;
  width: 100%;
  min-width: fit-content;
  word-wrap: nowrap;
  z-index: 1;
  min-width: 100%;
}
@media (max-width: 480px) {
  .modal-basic__wrapper .modal-basic__footer {
    position: fixed;
    background-color: #ffffff;
    bottom: 0;
  }
}
.modal-basic__wrapper .modal-basic__footer button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  min-width: 44px;
  padding-left: 12px;
  padding-right: 12px;
  min-width: 100%;
}
.modal-basic__wrapper .contacted-speakers-modal .contacted-speakers-modal--profile {
  max-height: 32px;
  max-width: 32px;
  border-radius: 50%;
}
.modal-basic__wrapper .contacted-speakers-modal .item {
  height: 64px;
}
.modal-basic__wrapper .import-events-data .item {
  min-height: 90px;
  max-height: fit-content;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.float-modal {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
  width: 396px;
}
@media (max-width: 480px) {
  .float-modal {
    width: 80vw;
  }
}
.float-modal .confirmation-btn {
  align-items: center;
  border-radius: 4px;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  justify-content: center;
  max-height: 48px;
  max-width: 164px;
  min-width: 124px;
  min-height: 48px;
  padding: 0px 24px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}
.float-modal .close-btn {
  width: 44px;
}
.float-modal .close-btn img {
  margin-top: 3px;
  min-width: 44px;
  padding: 12px;
}

.float-modal--profile {
  min-width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
}
@media (min-width: 480px) {
  .float-modal--profile {
    border-radius: 10px;
  }
}
.select-icons-modal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.select-icons-modal button {
  border-radius: 10px;
  padding: 12px;
  margin-right: 12px;
  margin-left: 12px;
  margin-bottom: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  max-width: fit-content;
  min-height: fit-content;
  max-height: fit-content;
}
.select-icons-modal:last-child {
  margin-right: auto;
}
.select-icons-modal .select-agenda-icon {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
}
.select-icons-modal .agenda-icon-selection-dot__container {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -20px;
}
.select-icons-modal .agenda-icon-selection-dot__container .agenda-icon-selection-dot {
  height: 8px;
  width: 8px;
  z-index: inherit;
  border-radius: 50%;
  outline-offset: 2px;
  background-color: #f67512;
  outline: 2px solid #f67512;
}

.participant__wrapper {
  position: relative;
  display: flex;
}
.participant__wrapper .participant-info {
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}
.participant__wrapper .participant-img__container {
  max-height: 124px;
  min-height: 124px;
  max-width: 124px;
  min-width: 124px;
  object-fit: cover;
}
.participant__wrapper .participant-img__container img {
  object-fit: fill;
}
@media (max-width: 480px) {
  .participant__wrapper .participant-img__container {
    margin: auto;
  }
}
.participant__wrapper .participant-img__container .participant-img {
  object-fit: contain;
  max-height: 124px;
  min-height: 124px;
  max-width: 124px;
  min-width: 124px;
  display: flex;
  border-radius: 50%;
}
.participant__wrapper .participant-img__container .participant-img--skeleton {
  object-fit: contain;
  display: flex;
  background: darkgray;
  max-height: 124px;
  min-height: 124px;
  max-width: 124px;
  min-width: 124px;
  border-radius: 50%;
}
.participant__wrapper .participant-img__container .social-links {
  display: flex;
}
.participant__wrapper .participant-img__container .social-links a {
  display: flex;
  align-items: center;
  min-height: 44px;
  min-width: 44px;
  margin-left: 12px;
}
.participant__wrapper .participant-img__container .social-links a:first-child {
  margin-left: 0px;
}
.participant__wrapper .participant-img__container .social-links img {
  margin-left: 6px;
  margin-right: 6px;
  height: 32px;
}
.participant__wrapper .participant-img__container .social-links img:first-child {
  margin-left: 0px;
}

.follow-tag-button {
  position: relative;
}
.follow-tag-button p {
  margin-left: 36px;
}
@media (max-width: 480px) {
  .follow-tag-button {
    width: 100%;
  }
}
.follow-tag-button .tag-profile-community {
  position: absolute;
  left: 8px;
  top: 6px;
  z-index: 1;
}

.account-tag-community-group {
  position: relative;
  height: 44px;
  align-items: center;
  display: flex;
}
.account-tag-community-group p {
  margin-left: 42px;
  margin-top: 7px;
  height: 44px;
}
@media (max-width: 480px) {
  .account-tag-community-group {
    width: 100%;
    margin-left: 0px;
  }
}
.account-tag-community-group .tag-profile-community {
  position: absolute;
  left: 2px;
  top: 4px;
}

.search-db-input {
  height: 52px;
  width: 100%;
  position: relative;
}
.search-db-input input {
  height: 52px;
  width: 100%;
  border: 1px solid #8f8f8f;
  padding-left: 68px;
  padding-right: 15px;
  border-radius: 4px;
}
.search-db-input input:hover {
  border: 1px solid #ff480f;
}
.search-db-input input:focus {
  border: 1px solid #f67512;
  outline: 1px solid #f67512;
}
.search-db-input--active input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.search-db-input .search-icon {
  top: 13px;
  left: 21px;
  position: absolute;
}

.search-db-dropdown {
  align-items: left;
  background-color: #ffffff;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  list-style: none;
  position: relative;
  min-width: 100%;
  z-index: 8;
  -webkit-tap-highlight-color: transparent;
}
.search-db-dropdown:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0);
}
.search-db-dropdown:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.search-db-dropdown-item {
  width: 100%;
  align-items: left;
  justify-content: flex-start;
  display: flex;
}
.search-db-dropdown-item .search-result {
  min-width: 100%;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 6px 10px 6px 10px;
}
.search-db-dropdown-item .search-result-add {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.search-db-dropdown-item .search-result-add-btn {
  display: flex;
  margin-left: auto;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  border-radius: 10px;
  min-height: 44px;
  min-width: fit-content;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.search-db-dropdown-item .search-result-add-btn p {
  padding: 6px 14px;
}
@media (hover: hover) {
  .search-db-dropdown-item .search-result-add-btn:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.search-db-dropdown-item .highlight {
  background-color: #f67512;
}

.status-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 14px;
  margin-right: 6px;
  min-height: 27px;
  background-color: #ffffff;
}
.status-bar img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}

.status-bar-organizer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 18px;
  margin-right: 6px;
  min-height: 27px;
  background-color: #ffffff;
  margin: 12px 0px;
  font-weight: 600;
}

.status-color-registration-neutral {
  border: 2px solid #ccc;
}

.status-color-registration-open {
  color: #5a96e4;
  border: 2px solid #5a96e4;
}

.status-color-registered {
  color: #008f30;
  border: 2px solid #008f30;
}

.status-color-pending {
  color: #fca400;
  border: 2px solid #fca400;
}

.status-color-almost-full {
  color: #f61212;
  border: 2px solid #f61212;
}

.status-color-full {
  color: #f61212;
  border: 2px solid #f61212;
}

.status-color-draft {
  color: #f61212;
  border: 2px solid #f61212;
}

.nav-menu__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 245, 247);
  min-width: 320px;
}
.nav-menu__wrapper .more-btn {
  position: fixed;
  min-height: 56px;
  max-height: 56px;
  white-space: nowrap;
  min-width: 320px;
  max-width: 320px;
  z-index: 4;
  background-color: rgb(244, 245, 247);
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  border-top: 2px solid rgba(0, 0, 0, 0.25);
  padding-left: 36px;
}
@media (hover: hover) {
  .nav-menu__wrapper .more-btn:hover {
    background-color: rgb(223, 223, 223);
  }
}
.nav-menu__wrapper .more-btn img {
  margin-right: 12px;
  height: 24px;
  min-width: 24px;
  max-width: 24px;
}
.nav-menu__wrapper .more-arrow-btn {
  position: fixed;
  top: 90px;
  left: 274px;
  height: 28px;
  width: 28px;
  z-index: 50;
  transition: top 0.25s ease-out;
}
.nav-menu__wrapper .more-arrow-btn--top {
  position: fixed;
  top: 90px;
  left: 274px;
  height: 28px;
  width: 28px;
  z-index: 4;
  transition: top 0.25s ease-out;
  opacity: 0.5;
}
.nav-menu__wrapper .more-arrow-btn--top--repositionSelectionContainerActive {
  top: 24px;
}
.nav-menu__wrapper .more-arrow-btn--bottom {
  position: fixed;
  bottom: 24px;
  left: 274px;
  height: 28px;
  width: 28px;
  z-index: 4;
  transition: top 0.25s ease-out;
  opacity: 0.5;
}
.nav-menu__wrapper .nav-menu__container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgb(244, 245, 247);
  transition: top 0.25s ease-out;
  width: 320px;
  color: #333333;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 100%;
  scrollbar-width: none;
  direction: rtl;
}
.nav-menu__wrapper .nav-menu__container .primary-heading {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 56px;
  max-height: 56px;
  padding-left: 36px;
  white-space: nowrap;
  min-width: 320px;
  z-index: 3;
  justify-content: flex-end;
}
@media (hover: hover) {
  .nav-menu__wrapper .nav-menu__container .primary-heading:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.nav-menu__wrapper .nav-menu__container .primary-heading .primary-heading-icon {
  height: 24px;
  min-width: 24px;
  max-width: 24px;
}
.nav-menu__wrapper .nav-menu__container .primary-heading .primary-heading-icon--speaker {
  height: 32px;
  width: 24px;
}
.nav-menu__wrapper .nav-menu__container .selected {
  background-color: rgba(0, 0, 0, 0.06);
}
.nav-menu__wrapper .nav-menu__container .sub-selection__container {
  display: flex;
  flex-direction: row;
  margin: 18px 0px 18px 44px;
  justify-content: flex-end;
}
@media (max-width: 1280px) {
  .nav-menu__wrapper .nav-menu__container .sub-selection__container {
    margin-left: 36px;
  }
}
.nav-menu__wrapper .nav-menu__container .sub-selection__container .progress-track {
  position: relative;
  display: flex;
  justify-content: center;
  width: 6px;
  background-color: rgb(228, 228, 228);
  border-radius: 10px;
}
.nav-menu__wrapper .nav-menu__container .sub-selection__container .progress-track .progress-bar-fill {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  border-radius: 10px;
  background-color: #f67512;
  max-height: 100%;
}
.nav-menu__wrapper .nav-menu__container .sub-selection__container .sub-selections {
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 245, 247);
}
.nav-menu__wrapper .nav-menu__container .sub-selection__container .sub-selections li {
  list-style-type: none;
  margin-bottom: 12px;
  margin-right: auto;
}
.nav-menu__wrapper .nav-menu__container .sub-selection__container .sub-selections li:last-child {
  margin-bottom: 0px;
}
.nav-menu__wrapper .nav-menu__container .sub-selection__container .sub-selections .secondary-heading {
  color: #333333;
  cursor: pointer;
  border: none;
  font-size: 18px;
  text-align: left;
  margin-left: 21px;
  min-height: 36px;
  min-width: 182px;
}
@media (max-width: 1280px) {
  .nav-menu__wrapper .nav-menu__container .sub-selection__container .sub-selections .secondary-heading {
    margin-left: 12px;
    min-width: 166px;
  }
}
@media (hover: hover) {
  .nav-menu__wrapper .nav-menu__container .sub-selection__container .sub-selections .secondary-heading:hover {
    color: #f67512;
  }
}

.nav-menu--mobile {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 245, 247);
  z-index: 6;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.1);
}
.nav-menu--mobile .heading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 60px;
  width: 100%;
  white-space: nowrap;
  font-weight: 700;
  font-size: 21px;
  color: rgba(0, 0, 0, 0.6);
}
@media (max-width: 320px) {
  .nav-menu--mobile .heading {
    height: 52px;
  }
}
.nav-menu--mobile .heading--active {
  background-color: rgba(0, 0, 0, 0.03);
}
.nav-menu--mobile .heading img {
  opacity: 0.6;
  height: 28px;
  margin-left: 12px;
  margin-right: 12px;
  max-width: 24px;
  min-width: 24px;
}
@media (max-width: 320px) {
  .nav-menu--mobile .heading img {
    height: 24px;
  }
}
.nav-menu--mobile .heading .arrow {
  height: 12px;
  opacity: 0.6;
}
@media (max-width: 320px) {
  .nav-menu--mobile .heading .arrow {
    height: 10px;
  }
}
.nav-menu--mobile .nav-menu-mobile-divider {
  border-right: 2px solid rgba(0, 0, 0, 0.5);
  opacity: 0.4;
  margin-top: 12px;
  margin-bottom: 12px;
  min-height: 100%;
}
.nav-menu--mobile .nav-menu__container--mobile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  opacity: 0.6;
  z-index: 5;
  width: 50%;
  color: #333333;
}
.nav-menu--mobile .nav-menu__container--mobile button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
}
@media (hover: hover) {
  .nav-menu--mobile .nav-menu__container--mobile button:hover {
    color: #f67512;
  }
}
.nav-menu--mobile .nav-menu__container--mobile img {
  height: 24px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12px;
  max-width: 24px;
  min-width: 24px;
}
.nav-menu--mobile .nav-menu__container--mobile .highlight-bar {
  min-height: 12px;
  min-width: 12px;
  border-radius: 50%;
  background-color: #f67512;
  border-radius: 10px;
  margin: auto;
  margin-left: 8px;
}
.nav-menu--mobile .sub-selections {
  position: relative;
  display: flex;
  flex-direction: column;
  opacity: 0.6;
  width: 50%;
  padding-bottom: 6px;
  padding-top: 6px;
  list-style-type: none;
}
@media (max-width: 320px) {
  .nav-menu--mobile .sub-selections {
    border-left: 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    padding-top: 24px;
    margin-top: 0px;
    margin-bottom: 12px;
  }
}
.nav-menu--mobile .sub-selections button {
  font-size: 16px;
  font-weight: 700;
  padding: 6px;
  border-radius: 10px;
  margin-left: 16px;
  margin-bottom: 12px;
  white-space: normal;
  text-align: left;
  min-width: 90%;
}
@media (hover: hover) {
  .nav-menu--mobile .sub-selections button:hover {
    color: #f67512;
  }
}
@media (max-width: 320px) {
  .nav-menu--mobile .sub-selections button {
    margin-left: 0px;
  }
}

.radial-button-list__wrapper {
  display: flex;
  flex-direction: column;
}
.radial-button-list__wrapper .radial-button__container {
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
}
.radial-button-list__wrapper .radial-button__container input[type=radio] {
  margin-right: 8px;
  cursor: pointer;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  accent-color: gray;
}
.radial-button-list__wrapper .radial-button__container label {
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  min-height: 44px;
}

.table-component {
  overflow-x: auto;
  font-size: 18px;
  border-collapse: collapse;
  max-height: 100px;
  position: relative;
  max-height: 1024px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  max-width: 100%;
  min-width: 100%;
}
.table-component th {
  border: 2px solid #ccc;
  padding: 12px;
  min-width: 120px;
  position: relative;
}
.table-component .resize-handle {
  position: absolute;
  top: 0;
  right: -12px;
  bottom: 0;
  min-height: 100%;
  width: 2px;
  cursor: col-resize;
  opacity: 0.5;
  border-radius: 20px;
  padding: 12px;
  z-index: 6;
}
.table-component .data-live-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
.table-component td {
  min-width: 90px;
  border: 2px solid #ccc;
  padding: 8px;
  white-space: wrap;
  word-break: break-word;
}
.table-component .column-resizer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  background-color: #eee;
  cursor: col-resize;
  background-color: red;
}
.table-component th:hover .column-resizer {
  background-color: #ddd;
}

.stripeElement {
  font-size: 30px;
}

.stripeElement-custom {
  font-size: 30px;
}

.folder-selection__wrapper {
  display: flex;
  max-width: 100%;
  min-width: 100%;
  font-size: 18px;
  min-height: fit-content;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
  border: 2px solid rgba(0, 0, 0, 0.35);
}

.folder-selection__container {
  min-height: 128px;
  width: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.35);
}
.folder-selection__container .folder-selection__box {
  border: 2px solid rgba(0, 0, 0, 0.35);
  width: 80px;
  height: 80px;
  border-radius: 10px;
  margin: 12px;
}
.folder-selection__container .folder-selection__box img {
  margin: auto;
}

.tags-wrapper {
  display: flex;
  align-items: center;
  margin-right: auto;
  flex-wrap: nowrap;
  min-width: 100%;
}
.tags-wrapper .tags-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  list-style: none;
}
.tags-wrapper .tags-container .tag {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 6px;
  padding: 8px;
  max-width: 100%;
}
.tags-wrapper .tags-container .tag li {
  word-wrap: break-word;
}
.tags-wrapper .tags-container .tag p {
  width: 100%;
  word-wrap: break-word;
}
.tags-wrapper .tags-container .tag img {
  right: 6px;
  margin-left: 12px;
  height: 14px;
  min-width: 28px;
}
.tags-wrapper .tags-container .tag:first-child {
  margin-left: 0px;
}

.bT {
  height: 32px;
  margin-top: auto;
  margin-bottom: auto;
}

.tT {
  height: 36px;
  margin-top: auto;
  margin-bottom: auto;
}

.mynd-pattern {
  position: absolute;
  top: -200px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  overflow: visible;
  width: 100%;
  transition: opacity 150ms ease-in;
}
@media (max-width: 768px) {
  .mynd-pattern {
    top: -100px;
  }
}

.mynd-pattern-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: transform 0.8s ease-out;
  overflow: visible;
  min-width: 100px;
  opacity: 0.3;
}
@media (max-width: 768px) {
  .mynd-pattern-column {
    min-width: 60px;
  }
}

.myndfull-pattern-icon__container {
  width: 100px;
  height: 400px;
  background-size: contain;
  background-repeat: repeat-y;
  background-position: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .myndfull-pattern-icon__container {
    width: 60px;
  }
}

/* Animation for continuous movement */
@keyframes loopUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}
@keyframes loopDown {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0);
  }
}
.mynd-pattern-column--loop-up .mynd-pattern-content {
  animation: loopUp 60s linear infinite;
}

.mynd-pattern-column--loop-down .mynd-pattern-content {
  animation: loopDown 60s linear infinite;
}

.show-card {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none;
  transition: box-shadow, z-index 500ms ease-in-out;
}

.show-card-horizontal-btn {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  outline: none;
  transition: box-shadow, z-index 500ms ease-in-out;
}
@media (hover: hover) {
  .show-card-horizontal-btn:hover {
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.35);
  }
}

.carousel__wrapper {
  position: relative;
  min-width: 100%;
  overflow: hidden;
  transition: transform 0.5s ease-in-out;
}

.carousel__container {
  display: flex;
  align-items: center;
}

.carousel-slide {
  min-width: 100%;
  transition: opacity 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.carousel-slide.active {
  opacity: 1;
  position: relative;
}

.carousel-image {
  width: 100%;
}

.carousel-button {
  display: flex;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  width: 50%;
  height: 54px;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}
@media (max-width: 480px) {
  .carousel-button {
    height: 44px;
  }
}
@media (hover: hover) {
  .carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.15);
  }
}

.carousel-button--left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-right: 1px;
}

.carousel-button--right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: 1px;
}

.carousel-button--tap {
  background-color: rgba(0, 0, 0, 0.3);
}

.carousel-selection-bar__container {
  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.carousel-selection-bar__container .carousel-selection-bar {
  height: 5px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.carousel-selection-bar__container .carousel-selection-bar--selected {
  height: 5px;
  width: 100%;
  background-color: #f67512;
}

.layout {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  min-height: 100%;
}

.bg-blur {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px);
  flex-grow: 1;
  min-width: 100%;
  min-height: 100%;
}

.panel-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  max-height: fit-content;
  z-index: 1;
}

.panel {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  max-width: 1096px;
  opacity: 0.9;
  padding: 64px;
  position: relative;
}
.panel:focus-visible {
  outline: 3px solid blue;
  outline-offset: -6px;
}
@media (max-width: 1296px) {
  .panel {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .panel {
    margin-bottom: 24px;
    margin-top: 24px;
    padding: 32px;
  }
}
@media (max-width: 320px) {
  .panel {
    padding: 12px;
  }
}

.form-template {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1096px;
  min-width: 624px;
  padding: 64px;
  position: relative;
  z-index: 3;
  outline: none;
  height: fit-content;
}
@media (max-width: 680px) {
  .form-template {
    min-width: 0px;
    width: 94%;
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .form-template {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
@media (max-width: 320px) {
  .form-template {
    padding: 24px 12px;
  }
}
.form-template .form-container {
  max-width: 580px;
  min-width: 580px;
  outline: none;
}
@media (max-width: 680px) {
  .form-template .form-container {
    min-width: 100%;
  }
}
@media (max-width: 480px) {
  .form-template .form-container {
    max-width: 80vw;
  }
}
.form-template .content-label {
  color: #777777;
  font-weight: 700;
  font-size: 21px;
  width: fit-content;
}
.form-template .content-label:focus-visible {
  outline: 3px solid blue;
  outline-offset: 6px;
}
@media (max-width: 320px) {
  .form-template .content-label {
    font-size: 18px;
  }
}
.form-template .description {
  text-align: left;
  font-size: 16px;
  flex-wrap: wrap;
}

.btn-call-to-action {
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.35);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  color: #777777;
  display: flex;
  font-size: 21px;
  font-weight: 700;
  justify-content: center;
  min-width: 298px;
  padding: 12px 12px;
  width: fit-content;
}
@media (max-width: 480px) {
  .btn-call-to-action {
    font-size: 18px;
    min-width: 220px;
  }
}
@media (max-width: 320px) {
  .btn-call-to-action {
    min-width: 196px;
  }
}

.btn-form {
  border-radius: 10px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  color: #777777;
  display: flex;
  font-size: 21px;
  font-weight: 700;
  justify-content: center;
  min-width: 298px;
  padding: 12px 12px;
  width: fit-content;
}
@media (max-width: 480px) {
  .btn-form {
    font-size: 18px;
    min-width: 220px;
  }
}
@media (max-width: 320px) {
  .btn-form {
    min-width: 196px;
  }
}
.btn-form--active {
  border: 2px solid #f67512;
  color: #f67512;
}
.btn-form--inactive {
  cursor: not-allowed;
  border: 2px solid #ccc;
  color: #ccc;
}

.logo__color1 {
  color: #333333;
}

.logo__color2 {
  color: #ffffff;
}

.myndfull {
  font-family: "Decoy", sans-serif !important;
  font-weight: 400 !important;
  padding-top: 6px;
}

.error-page__wrapper {
  display: flex;
  flex-grow: 1;
  max-width: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-page__wrapper img {
  height: 280px;
}
@media (max-width: 480px) {
  .error-page__wrapper img {
    height: 24vh;
  }
}
.error-page__wrapper .error-page__container {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (max-width: 480px) {
  .error-page__wrapper .error-page__container {
    flex-direction: column;
  }
}
.error-page__wrapper .error-page__container .error-page-text {
  max-width: 26%;
}
@media (max-width: 480px) {
  .error-page__wrapper .error-page__container .error-page-text {
    max-width: 100%;
  }
}

.error-divider {
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  min-height: 120px;
  flex-grow: 1;
  min-width: 100%;
}

.navbar {
  align-items: center;
  background: linear-gradient(45deg, rgba(186, 96, 221, 0.8274509804) 0%, #c767bf 10%, #e35858 70%);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding-left: 148px;
  padding-right: 148px;
  width: 100%;
  z-index: 6;
  align-items: center;
}
@media (max-width: 1280px) {
  .navbar {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media (max-width: 800px) {
  .navbar {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 480px) {
  .navbar {
    height: 64px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 4px;
  }
}
.navbar .navbar__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.navbar .nav__links {
  display: flex;
  white-space: nowrap;
}
.navbar .nav__links a,
.navbar .nav__links Link {
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  height: 44px;
  justify-content: center;
  max-width: 90%;
  padding: 0px 20px 0px 0px;
  place-content: center;
  position: relative;
}
.navbar .nav__links a:first-child,
.navbar .nav__links Link:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.navbar .nav__links a:last-child,
.navbar .nav__links Link:last-child {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}
.navbar .nav__links a .icon,
.navbar .nav__links Link .icon {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 60px;
}
.navbar .nav__links a .icon img,
.navbar .nav__links Link .icon img {
  min-height: 25px;
  max-height: 25px;
}
.navbar .nav__links a .icon .profile-image,
.navbar .nav__links Link .icon .profile-image {
  border-radius: 95%;
  max-height: 38px;
  min-height: 38px;
  min-width: 38px;
  max-width: 38px;
  margin-top: 4px;
}
.navbar .nav__links a .icon .active,
.navbar .nav__links Link .icon .active {
  -webkit-transition: all 0.2s;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  display: flex;
  height: 48px;
  place-content: center;
  position: absolute;
  transition: all 0.2s;
  width: 48px;
}
.navbar .nav__links a .icon .inactive,
.navbar .nav__links Link .icon .inactive {
  -webkit-transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  height: 0px;
  place-content: center;
  position: absolute;
  transition: all 0.3s;
  width: 0px;
}
.navbar .logo__link {
  align-items: center;
  align-items: flex-end;
  display: flex;
  font-family: "Decoy", sans-serif;
  font-size: 30px;
  font-weight: 500;
  justify-content: center;
  min-height: 44px;
}
@media (max-width: 480px) {
  .navbar .logo__link {
    padding-bottom: 3px;
  }
}
.navbar button.hamburger {
  min-height: 44px;
  max-height: 44px;
  min-width: 44px;
  max-width: 44px;
}
.navbar button.hamburger img {
  min-height: 44px;
  max-height: 44px;
  min-width: 44px;
  max-width: 44px;
  padding: 3px;
}
@media (max-width: 320px) {
  .navbar button.hamburger {
    margin-top: 5px;
  }
}

.footer {
  align-items: center;
  background: linear-gradient(45deg, rgba(186, 96, 221, 0.8274509804) 0%, #c767bf 10%, #e35858 70%);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px 0px;
  width: 100%;
  z-index: 7;
  max-width: 100vw;
}
.footer .footer__links {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 12px;
}
.footer .footer__links button {
  min-height: 44px;
}
@media (max-width: 480px) {
  .footer .footer__links {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.footer .footer__links .footer-dot {
  margin-right: 12px;
  margin-left: 12px;
  pointer-events: none;
  background-color: #ffffff;
  opacity: 0.9;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}
.footer .footer__links .footer-dot--no-mrg-right {
  margin-right: 0px;
}
.footer .social-media {
  min-height: 18px;
  max-height: 44px;
  padding: 12px;
}

.slidemenu {
  background: linear-gradient(75deg, rgba(186, 96, 221, 0.8274509804), #c767bf, #e35858);
  display: flex;
  flex-direction: column;
  height: 200vh;
  overflow: hidden;
  padding: 0px 24px;
  position: fixed;
  right: 0;
  transform: translate3d(300vw, 0, 0);
  transition-timing-function: ease-in-out;
  transition: 0.4s;
  width: fit-content;
  z-index: 12;
  min-width: 256px;
}
@media (max-width: 480px) {
  .slidemenu {
    min-width: 196px;
    padding: 0px 18px;
  }
}
@media (max-width: 320px) {
  .slidemenu {
    max-width: fit-content;
  }
}
.slidemenu__open {
  transform: translate3d(0vw, 0, 0);
  transition: 0.4s;
}
.slidemenu .slidemenu__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
}
.slidemenu .slidemenu__container .nav__links {
  position: relative;
  display: flex;
  flex-direction: column;
}
.slidemenu .slidemenu__container .nav__links Link,
.slidemenu .slidemenu__container .nav__links a {
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  min-height: 76px;
  border: 3px solid rgba(255, 255, 255, 0.5);
  padding: 0px 12px 0px 24px;
  transition: all 0.1s ease-in-out;
  justify-content: space-around;
  margin-bottom: 24px;
  -webkit-tap-highlight-color: transparent;
  min-width: 240px;
}
@media (max-width: 320px) {
  .slidemenu .slidemenu__container .nav__links Link,
  .slidemenu .slidemenu__container .nav__links a {
    min-height: 64px;
  }
}
@media (hover: hover) {
  .slidemenu .slidemenu__container .nav__links Link:hover,
  .slidemenu .slidemenu__container .nav__links a:hover {
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.25);
    transform: scale(1.05);
    transition-duration: 0.1s;
  }
}
@media (hover: hover) and (max-width: 480px) {
  .slidemenu .slidemenu__container .nav__links Link:hover,
  .slidemenu .slidemenu__container .nav__links a:hover {
    transform: scale(1);
  }
}
.slidemenu .slidemenu__container .nav__links Link img,
.slidemenu .slidemenu__container .nav__links a img {
  height: 36px;
  width: 36px;
}
@media (max-width: 680px) {
  .slidemenu .slidemenu__container .nav__links Link img,
  .slidemenu .slidemenu__container .nav__links a img {
    height: 32px;
    width: 32px;
  }
}
@media (max-width: 480px) {
  .slidemenu .slidemenu__container .nav__links Link img,
  .slidemenu .slidemenu__container .nav__links a img {
    height: 26px;
    width: 26px;
  }
}
.slidemenu .slidemenu__container .nav__links Link .profile-image,
.slidemenu .slidemenu__container .nav__links a .profile-image {
  border-radius: 95%;
  height: 42px;
  width: 42px;
  object-fit: cover;
}
.slidemenu .nav-link--clicked {
  transform: scale(0.95);
}
.slidemenu__exit {
  align-items: center;
  background-color: black;
  cursor: pointer;
  display: flex;
  min-height: 200vh;
  justify-content: left;
  opacity: 70%;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.slidemenu__exit-button {
  display: none;
}
@media (max-width: 480px) {
  .slidemenu__exit-button {
    align-items: center;
    display: flex;
    height: 5%;
    margin-left: 10%;
    margin: left;
  }
}
.slidemenu__footer {
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: max(12vh, 120px);
  justify-content: center;
  margin-bottom: 1.5vh;
  margin-top: 1vh;
  position: relative;
}
@media (max-width: 480px) {
  .slidemenu__footer {
    margin-bottom: 4vh;
  }
}
.slidemenu__footer .social-media {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.slidemenu__footer .social-media img {
  cursor: pointer;
}
.slidemenu__footer .copyright {
  color: #ffffff;
  display: flex;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  justify-content: center;
  margin: auto;
  text-align: center;
}
@media (max-width: 480px) {
  .slidemenu__footer .copyright {
    font-size: 12px;
    line-height: 25px;
  }
}
.slidemenu .slidemenu__footer--slide {
  position: absolute;
  left: 0;
  right: 0;
  top: 84vh;
  z-index: 20;
}

.loading-screen {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading-screen img {
  height: 128px;
  margin-bottom: 20px;
}
.loading-screen .loading-screen-logo {
  border-radius: 50%;
  min-height: 16vh;
}
.loading-screen .loading-bar__container {
  max-width: 100%;
  height: 4px;
  width: 100px;
  background-color: #ccc;
  border-radius: 4px;
  z-index: 4;
}
.loading-screen .loading-bar__container .loading-bar {
  height: 4px;
  border-radius: 4px;
  z-index: 5;
  width: 0;
  transition: width 0.5s ease-in-out;
}

.loading-dots {
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  align-items: center;
}

.loading-dots div {
  position: absolute;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loading-dots--regular div {
  width: 14px;
  height: 14px;
}

.loading-dots--small div {
  width: 12px;
  height: 12px;
}

.loading-dots--pink div {
  background: #ff8585;
}

.loading-dots--white div {
  background: #ffffff;
}

.loading-dots div:nth-child(1) {
  left: 9px;
  animation: loading-dots1 0.3s infinite;
}

.loading-dots div:nth-child(2) {
  left: 9px;
  animation: loading-dots2 0.3s infinite;
}

.loading-dots div:nth-child(3) {
  left: 33px;
  animation: loading-dots2 0.3s infinite;
}

.loading-dots div:nth-child(4) {
  left: 58px;
  animation: loading-dots3 0.3s infinite;
}

@keyframes loading-dots1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-dots3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-dots2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.landing-show-card-horizontal-btn__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.landing-show-card-horizontal-btn {
  min-height: 124px;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  transition: box-shadow 500ms ease-in-out;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
}
@media (max-width: 1280px) {
  .landing-show-card-horizontal-btn h1 {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 480px) {
  .landing-show-card-horizontal-btn {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 24px;
  }
}

.landing-show-card-horizontal-btn--text {
  margin-left: 24px;
  margin-right: auto;
  font-size: 24px;
}
@media (max-width: 380px) {
  .landing-show-card-horizontal-btn--text {
    font-size: 21px;
  }
}
@media (max-width: 320px) {
  .landing-show-card-horizontal-btn--text {
    font-size: 18px;
  }
}

.landing-show-card-horizontal-btn--subText {
  font-size: 18px;
}
@media (max-width: 480px) {
  .landing-show-card-horizontal-btn--subText {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .landing-show-card-horizontal-btn--subText {
    font-size: 14px;
  }
}

.landing-show-card-horizontal-btn--image {
  max-width: 100px;
  margin-bottom: 4px;
  max-height: 100px;
}
@media (max-width: 480px) {
  .landing-show-card-horizontal-btn--image {
    max-width: 85px;
  }
}

.landing-show-card-horizontal-btn__image-wrapper {
  align-items: center;
  display: flex;
  margin-left: auto;
}
@media (max-width: 480px) {
  .landing-show-card-horizontal-btn__image-wrapper {
    margin-left: 0px;
  }
}

.landing-show-card-horizontal-btn--button-arrow {
  box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.2);
  height: fit-content;
  opacity: 0.85;
  min-height: 44px;
  min-width: 44px;
  width: 44px;
  border-radius: 50%;
}
@media (max-width: 480px) {
  .landing-show-card-horizontal-btn--button-arrow {
    min-height: 38px;
    min-width: 38px;
    width: 38px;
  }
}

.landing-show-card {
  max-height: 546px;
  max-width: 420px;
  min-width: 420px;
  padding: 64px;
  z-index: 2;
  margin-top: 42px;
  align-items: center;
  justify-content: center;
}
.landing-show-card img {
  image-rendering: auto;
  -ms-interpolation-mode: bicubic;
}
@media (max-width: 480px) {
  .landing-show-card {
    min-width: 80vw;
    max-width: 80vw;
    padding: 32px;
  }
}
.landing-show-card h1 {
  color: #777777;
  font-weight: 700;
  font-size: 24px;
}
.landing-show-card .landing-show-card-main-image {
  max-height: 300px;
  height: 300px;
}
@media (max-width: 480px) {
  .landing-show-card .landing-show-card-main-image {
    width: 100%;
    height: 100%;
  }
}
.landing-show-card .landing-show-card-action-btn {
  height: fit-content;
  opacity: 0.8;
  min-height: 44px;
  min-width: 44px;
  width: 44px;
  border-radius: 50%;
  outline-offset: 12px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
}
.landing-show-card .landing-show-card-action-btn img {
  min-height: 44px;
  min-width: 44px;
}

@keyframes moveOutAndBackRight {
  0% {
    transform: rotate(4deg) translateX(0);
    z-index: 2;
  }
  50% {
    transform: rotate(4deg) translateX(30px);
    z-index: 2;
  }
  100% {
    transform: rotate(4deg) translateX(0);
    z-index: 6;
  }
}
@keyframes moveBackAndResetRight {
  0% {
    transform: rotate(4deg) translateX(0);
    z-index: 6;
  }
  50% {
    transform: rotate(4deg) translateX(30px);
    z-index: 6;
  }
  100% {
    transform: rotate(4deg) translateX(0);
    z-index: 2;
  }
}
.show-card--right {
  transform: rotate(4deg);
  transition: box-shadow 500ms ease-in-out, z-index 500ms ease-in-out;
}
.show-card--right h1 {
  transition: transform 0.3s ease-in-out;
}

.show-card--right--animationStart {
  animation: moveOutAndBackRight 0.6s ease-in-out forwards;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.35);
}
.show-card--right--animationStart h1 {
  transform: scale(1.5);
  rotate: 3deg;
}

.show-card--right--animationReverse {
  animation: moveBackAndResetRight 0.6s ease-in-out forwards;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
}
.show-card--right--animationReverse h1 {
  transform: scale(1);
  rotate: 0deg;
}

@keyframes moveOutAndBackLeft {
  0% {
    transform: rotate(-4deg) translateX(0);
    z-index: 2;
  }
  50% {
    transform: rotate(-4deg) translateX(-30px);
    z-index: 2;
  }
  100% {
    transform: rotate(-4deg) translateX(0);
    z-index: 6;
  }
}
@keyframes moveBackAndResetLeft {
  0% {
    transform: rotate(-4deg) translateX(0);
    z-index: 6;
  }
  50% {
    transform: rotate(-4deg) translateX(-30px);
    z-index: 6;
  }
  100% {
    transform: rotate(-4deg) translateX(0);
    z-index: 2;
  }
}
.show-card--left {
  transform: rotate(-4deg);
  transition: box-shadow 500ms ease-in-out, z-index 500ms ease-in-out;
}
.show-card--left h1 {
  transition: transform 0.3s ease-in-out;
}

.show-card--left--animationStart {
  animation: moveOutAndBackLeft 0.6s ease-in-out forwards;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.35);
}
.show-card--left--animationStart h1 {
  transform: scale(1.5);
  rotate: -3deg;
}

.show-card--left--animationReverse {
  animation: moveBackAndResetLeft 0.6s ease-in-out forwards;
  box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.2);
}
.show-card--left--animationReverse h1 {
  transform: scale(1);
  rotate: 0deg;
}

.explore__wrapper {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}
.explore__wrapper h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 64px;
  text-align: center;
}
@media (max-width: 1280px) {
  .explore__wrapper h1 {
    font-size: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (max-width: 700px) {
  .explore__wrapper h1 {
    font-size: 36px;
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper h1 {
    font-size: 32px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper h1 {
    font-size: 24px;
  }
}
.explore__wrapper .explore-base-text p {
  font-size: 28px;
  color: #383838;
  font-family: "Inter", sans-serif;
  line-height: 46px;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-base-text p {
    font-size: 28px;
    line-height: 42px;
  }
}
@media (max-width: 650px) {
  .explore__wrapper .explore-base-text p {
    font-size: 24px;
    line-height: 36px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-base-text p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-base-text p {
    font-size: 16px;
  }
}
.explore__wrapper .explore-base-text h2 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  color: #383838;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-base-text h2 {
    font-size: 32px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-base-text h2 {
    font-size: 28px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-base-text h2 {
    font-size: 21px;
  }
}
.explore__wrapper .explore-base-text--small p {
  font-size: 24px;
  color: #383838;
  font-family: "Inter", sans-serif;
  line-height: 46px;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-base-text--small p {
    font-size: 21px;
    line-height: 42px;
  }
}
@media (max-width: 650px) {
  .explore__wrapper .explore-base-text--small p {
    font-size: 24px;
    line-height: 36px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-base-text--small p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-base-text--small p {
    font-size: 16px;
  }
}
.explore__wrapper .explore-base-text--small h2 {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  color: #383838;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-base-text--small h2 {
    font-size: 32px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-base-text--small h2 {
    font-size: 28px;
  }
}
@media (max-width: 375px) {
  .explore__wrapper .explore-base-text--small h2 {
    font-size: 24px;
  }
}
.explore__wrapper .explore-base-text--small h3 {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  margin-right: auto;
  color: #383838;
}
@media (max-width: 480px) {
  .explore__wrapper .explore-base-text--small h3 {
    font-size: 21px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-base-text--small h3 {
    font-size: 18px;
  }
}
.explore__wrapper .explore-button {
  background-color: #1e5de6; /* Tailwind's blue-600 */
  color: white;
  font-size: 24px;
  font-weight: 600;
  padding: 16px 24px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease, transform 0.2s ease;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-button {
    font-size: 21px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-button {
    font-size: 18px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-button {
    font-size: 16px;
  }
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-button--translations {
    padding: 8px 21px;
    font-size: 18px;
  }
}
.explore__wrapper .explore-button:hover {
  background-color: #1d4ed8; /* Tailwind's blue-700 */
}
.explore__wrapper .explore-button:active {
  background-color: #1e40af; /* Tailwind's blue-800 */
}
.explore__wrapper .explore-heading {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  position: relative;
}
.explore__wrapper .explore-heading .explore-signUp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 48px;
  padding-bottom: 48px;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-heading .explore-signUp {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-heading .explore-signUp {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
.explore__wrapper .explore-heading .explore-heading-img__wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-heading .explore-heading-img__wrapper {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
.explore__wrapper .explore-heading .explore-heading-img__wrapper .explore-header-img--mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  pointer-events: none;
  margin-bottom: 12px;
  z-index: 3;
  left: 0;
  max-height: 496px;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-heading .explore-heading-img__wrapper .explore-header-img--mobile {
    max-width: 30%;
  }
}
.explore__wrapper .explore-heading .explore-heading-img__wrapper .explore-header-img--desktop {
  margin-left: 36px;
  left: 210px;
  max-height: 496px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  pointer-events: none;
  z-index: 2;
  max-height: 496px;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-heading .explore-heading-img__wrapper .explore-header-img--desktop {
    margin-left: 24px;
    max-width: 65%;
  }
}
.explore__wrapper .explore-myndfull-introduction {
  padding-top: 136px;
  padding-bottom: 136px;
  display: flex;
  padding-left: 20%;
  padding-right: 20%;
  flex-direction: column;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-myndfull-introduction {
    padding-top: 106px;
    padding-bottom: 106px;
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-myndfull-introduction {
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-myndfull-introduction {
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.explore__wrapper .explore-community {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}
@media (max-width: 1000px) {
  .explore__wrapper .explore-community {
    flex-direction: column;
    align-items: center;
  }
}
.explore__wrapper .explore-community .explore-community-img {
  object-fit: cover;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-community .explore-community-img {
    object-fit: contain;
    max-width: 100%;
  }
}
@media (max-width: 1300px) {
  .explore__wrapper .explore-community .explore-community-img {
    max-width: 50%;
    min-height: 100%;
  }
}
@media (max-width: 1000px) {
  .explore__wrapper .explore-community .explore-community-img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    min-width: 100%;
    max-height: 400px;
  }
}
.explore__wrapper .explore-community .explore-community__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.explore__wrapper .explore-community .explore-community__container .explore-community-text-box {
  font-size: 24px;
  color: #383838;
  font-weight: 400;
  line-height: 42px;
  max-width: 500px;
  width: 100%;
  font-family: "Inter", sans-serif;
  border-right: 2px solid rgba(0, 0, 0, 0.1);
  padding: 48px;
  z-index: 3;
  background-color: white;
}
@media (max-width: 1700px) {
  .explore__wrapper .explore-community .explore-community__container .explore-community-text-box {
    border-right: 0px solid rgba(0, 0, 0, 0);
    max-width: 600px;
  }
}
@media (max-width: 1300px) {
  .explore__wrapper .explore-community .explore-community__container .explore-community-text-box {
    padding: 0px 24px;
    font-size: 21px;
  }
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-community .explore-community__container .explore-community-text-box {
    max-width: 80%;
    font-size: 21px;
    line-height: 36px;
  }
}
@media (max-width: 1060px) {
  .explore__wrapper .explore-community .explore-community__container .explore-community-text-box {
    font-size: 18px;
  }
}
@media (max-width: 1000px) {
  .explore__wrapper .explore-community .explore-community__container .explore-community-text-box {
    font-size: 24px;
    line-height: 38px;
    padding: 48px 0px;
  }
}
@media (max-width: 580px) {
  .explore__wrapper .explore-community .explore-community__container .explore-community-text-box {
    font-size: 18px;
    line-height: 34px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-community .explore-community__container .explore-community-text-box {
    line-height: 28px;
  }
}
.explore__wrapper .explore-banner {
  font-family: "Inter", sans-serif;
  min-height: 200px;
  width: 100%;
  padding-top: 96px;
  padding-bottom: 96px;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: column;
}
@media (max-width: 1500px) {
  .explore__wrapper .explore-banner {
    padding-left: 15%;
    padding-right: 15%;
  }
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-banner {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media (max-width: 700px) {
  .explore__wrapper .explore-banner {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-banner {
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-banner {
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.explore__wrapper .explore-banner .explore-banner-heading {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 680px) {
  .explore__wrapper .explore-banner .explore-banner-heading {
    flex-direction: column;
  }
}
.explore__wrapper .explore-banner h3 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  color: #383838;
  text-align: center;
}
@media (max-width: 1500px) {
  .explore__wrapper .explore-banner h3 {
    font-size: 28px;
  }
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-banner h3 {
    font-size: 28px;
    line-height: 42px;
  }
}
@media (max-width: 600px) {
  .explore__wrapper .explore-banner h3 {
    font-size: 24px;
    line-height: 38px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-banner h3 {
    font-size: 21px;
  }
}
.explore__wrapper .explore-features {
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  padding-top: 96px;
  padding-bottom: 96px;
  padding-left: 20%;
  padding-right: 20%;
}
@media (max-width: 1500px) {
  .explore__wrapper .explore-features {
    padding-left: 15%;
    padding-right: 15%;
  }
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-features {
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media (max-width: 1100px) {
  .explore__wrapper .explore-features {
    padding-left: 36px;
    padding-right: 36px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-features {
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-features {
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 6px;
    padding-right: 6px;
  }
}
.explore__wrapper .explore-features .explore-features-btns {
  list-style-type: none;
  display: flex;
  row-gap: 24px;
  column-gap: 24px;
  width: 100%;
}
.explore__wrapper .explore-features .explore-features-btns li {
  flex: 1;
  width: 100%;
}
@media (max-width: 900px) {
  .explore__wrapper .explore-features .explore-features-btns {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.explore__wrapper .explore-features .explore-features-btns .explore-features-button {
  position: relative;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border: 4px solid rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.5);
  color: #383838;
  font-size: 24px;
  font-weight: 600;
  padding: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex: 1;
}
.explore__wrapper .explore-features .explore-features-btns .explore-features-button img {
  filter: invert(22%) sepia(7%) saturate(4%) hue-rotate(314deg) brightness(106%) contrast(88%);
  margin-right: 12px;
  height: 24px;
}
@media (max-width: 900px) {
  .explore__wrapper .explore-features .explore-features-btns .explore-features-button {
    width: 40%;
    font-size: 21px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 680px) {
  .explore__wrapper .explore-features .explore-features-btns .explore-features-button {
    width: 60%;
    font-size: 21px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-features .explore-features-btns .explore-features-button {
    width: 80%;
    font-size: 18px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-features .explore-features-btns .explore-features-button {
    width: 100%;
  }
}
.explore__wrapper .explore-features .explore-features-btns .explore-features-button--active {
  border: 4px solid #f67512;
}
.explore__wrapper .explore-features .carousel-button--explore-scheme {
  background-color: #f67512;
  opacity: 0.75;
  transition: background-color, 200ms ease-out;
  height: 44px;
  max-width: 400px;
}
@media (hover: hover) {
  .explore__wrapper .explore-features .carousel-button--explore-scheme:hover {
    opacity: 1;
  }
}
.explore__wrapper .explore-features .explore-features-page-summary {
  display: flex;
  margin-top: 12px;
  white-space: nowrap;
  font-size: 24px;
  color: #383838;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-features .explore-features-page-summary {
    margin-left: 36px;
    margin-right: 36px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-features .explore-features-page-summary {
    font-size: 18px;
  }
}
.explore__wrapper .explore-features .features__wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  column-gap: 36px;
}
.explore__wrapper .explore-features .features__wrapper .features__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-features .features__wrapper .features__container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}
.explore__wrapper .explore-features .features__wrapper .features-title {
  min-height: 44px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  color: #383838;
  font-size: 24px;
}
.explore__wrapper .explore-features .features__wrapper .features-description {
  font-size: 21px;
  line-height: 32px;
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 24px;
}
.explore__wrapper .explore-logo {
  height: 100px;
  width: 100px;
  margin-right: 24px;
}
@media (max-width: 680px) {
  .explore__wrapper .explore-logo {
    margin-bottom: 24px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-logo {
    height: 80px;
    width: 80px;
  }
}
@media (max-width: 680px) {
  .explore__wrapper .explore-logo--launch {
    margin-bottom: 0px;
  }
}
.explore__wrapper .features-checklist {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 21px;
  list-style-type: none;
  padding: 12px 24px 24px 24px;
  justify-content: center;
}
.explore__wrapper .features-checklist img {
  height: 24px;
  margin-bottom: auto;
  margin-top: 2px;
  max-width: 24px;
}
.explore__wrapper .explore-myndfull-translations {
  padding-top: 136px;
  padding-bottom: 136px;
  display: flex;
  padding-left: 20%;
  padding-right: 20%;
  flex-direction: column;
}
@media (max-width: 1700px) {
  .explore__wrapper .explore-myndfull-translations {
    padding-top: 106px;
    padding-bottom: 106px;
    padding-left: 10%;
    padding-right: 10%;
  }
}
@media (max-width: 1400px) {
  .explore__wrapper .explore-myndfull-translations {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-myndfull-translations {
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 18px;
    padding-right: 18px;
  }
}
@media (max-width: 320px) {
  .explore__wrapper .explore-myndfull-translations {
    padding-top: 15%;
    padding-bottom: 15%;
    padding-left: 12px;
    padding-right: 12px;
  }
}
.explore__wrapper .explore-myndfull-translations h2 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  color: #383838;
}
@media (max-width: 1280px) {
  .explore__wrapper .explore-myndfull-translations h2 {
    font-size: 28px;
  }
}
@media (max-width: 480px) {
  .explore__wrapper .explore-myndfull-translations h2 {
    font-size: 24px;
  }
}
.explore__wrapper .explore-myndfull-translations .explore-myndfull-translations-img__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.explore__wrapper .explore-myndfull-translations .explore-myndfull-translations-img__container .explore-myndfull-translations-img {
  height: 500px;
}
@media (max-width: 1024px) {
  .explore__wrapper .explore-myndfull-translations .explore-myndfull-translations-img__container .explore-myndfull-translations-img {
    height: 300px;
  }
}
.explore__wrapper .explore-myndfull-accessibility-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.explore__wrapper .accessibility-card-demo img {
  height: 530px;
}
@media (max-width: 480px) {
  .explore__wrapper .accessibility-card-demo img {
    max-height: 420px;
  }
}
.explore__wrapper .explore-myndfull-more-links {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}
@media (max-width: 1450px) {
  .explore__wrapper .explore-myndfull-more-links {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
}
@media (max-width: 1450px) {
  .explore__wrapper .explore-myndfull-more-links .follow-us {
    margin-top: 48px;
  }
}
.explore__wrapper .explore-myndfull-more-links .explore-myndfull-more-email {
  display: flex;
  flex-direction: column;
  max-width: 496px;
}

.demo-toolbar-highlight {
  transition: 300ms ease-in-out;
  outline: 4px dashed rgba(255, 0, 0, 0);
  outline-offset: 12px;
  border-radius: 4px;
}
@media (hover: hover) {
  .demo-toolbar-highlight:hover {
    outline: 4px dashed rgba(255, 0, 0, 0.75);
    outline-offset: 12px;
  }
}

.demo-toolbar-highlight--mobile {
  transition: 300ms ease-in-out;
  outline: 4px dashed rgba(255, 0, 0, 0.75);
  outline-offset: 4px;
  border-radius: 4px;
}

.demo-toolbar-highlight--mobile--extended {
  transition: 300ms ease-in-out;
  outline: 4px dashed rgba(255, 0, 0, 0.75);
  outline-offset: 8px;
  border-radius: 4px;
}

.demo-toolbar-highlight-description {
  min-height: 214px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 12px 24px;
}
.demo-toolbar-highlight-description h4 {
  font-family: "Inter", sans-serif;
  font-size: 21px;
  line-height: 36px;
  font-weight: 600;
}
.demo-toolbar-highlight-description p {
  font-family: "Inter", sans-serif;
  font-size: 21px;
  line-height: 36px;
}
@media (max-width: 320px) {
  .demo-toolbar-highlight-description p {
    font-size: 18px;
    line-height: 30px;
  }
}

.demo-toolbar-highlight-description--active {
  outline: 5px dashed rgba(255, 0, 0, 0.75);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
}
@media (max-width: 480px) {
  .demo-toolbar-highlight-description--active {
    outline: 4px dashed rgba(255, 0, 0, 0.75);
  }
}

.demo-summary-heading {
  display: flex;
  margin-left: 148px;
  margin-right: 148px;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  min-height: 52px;
}
.demo-summary-heading .tags-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  max-height: 54px;
}
.demo-summary-heading .tags-toggle-arrow {
  height: 21px;
  width: 21px;
  opacity: 0.5;
  margin-right: 12px;
}
.demo-summary-heading .divider {
  width: 2px;
  min-height: 28px;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
}
.demo-summary-heading .tags-sum {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.demo-search-summary {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  flex-wrap: wrap;
  min-height: 26px;
}
.demo-search-summary p {
  text-align: center;
  word-wrap: break-word;
  font-size: 18px;
}
@media (max-width: 800px) {
  .demo-search-summary {
    padding-left: 24px;
  }
}
@media (max-width: 775px) {
  .demo-search-summary {
    white-space: nowrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
}
@media (max-width: 480px) {
  .demo-search-summary {
    margin-left: 3vw;
    margin-right: 3vw;
    max-width: 94vw;
    min-width: 100%;
  }
}

.demo-events-padding {
  padding: 24px;
}
@media (max-width: 420px) {
  .demo-events-padding {
    padding: 12px;
  }
}
@media (max-width: 390px) {
  .demo-events-padding {
    padding: 6px;
  }
}

.demo-events-grid__wrapper {
  display: flex;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.demo-events-grid__wrapper .demo-events-grid {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  row-gap: 36px;
  column-gap: 36px;
  margin-bottom: 24px;
  margin-top: 48px;
  max-width: 1008px;
}
@media (max-width: 1685px) {
  .demo-events-grid__wrapper .demo-events-grid {
    max-width: 520px;
  }
}
.demo-events-grid__wrapper .demo-events-grid .demo-ec-skeleton__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 207px;
  height: 282px;
  box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 20px;
}
@media (max-width: 1685px) {
  .demo-events-grid__wrapper .demo-events-grid .demo-ec-skeleton__container {
    width: 103px;
    height: 141px;
    border-radius: 10px;
  }
}
.demo-events-grid__wrapper .demo-events-grid .demo-ec-skeleton__container .demo-ec__image {
  width: 100%;
  background: rgb(217, 217, 217);
  min-height: 96px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
@media (max-width: 1685px) {
  .demo-events-grid__wrapper .demo-events-grid .demo-ec-skeleton__container .demo-ec__image {
    min-height: 48px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
}
.demo-events-grid__wrapper .demo-events-grid .demo-ec-skeleton__container .demo-ec__data {
  margin-top: 12px;
  margin-bottom: 10px;
  height: 12px;
  width: 94%;
  border-radius: 5px;
  background: rgb(217, 217, 217);
}
.demo-events-grid__wrapper .demo-events-grid .demo-ec-skeleton__container .demo-ec__icon {
  display: flex;
  flex-direction: row;
  width: 10px;
  height: 10px;
  margin: 10px;
  margin-left: 14px;
  margin-right: auto;
}
.demo-events-grid__wrapper .demo-events-grid .demo-ec__ghost {
  position: relative;
  width: 207px;
  height: 282px;
}
@media (max-width: 1685px) {
  .demo-events-grid__wrapper .demo-events-grid .demo-ec__ghost {
    width: 103px;
    height: 141px;
    border-radius: 10px;
  }
}

.registration-offscreen {
  position: fixed;
  left: -9999px;
  width: 0px;
  pointer-events: none;
}

.registration-container {
  transition: opacity 400ms ease-in-out;
  opacity: 1;
  visibility: visible;
}

.registration-container--fade-out {
  opacity: 0;
  visibility: hidden;
}

.registration-container--fade-in {
  opacity: 1;
  visibility: visible;
}

.register {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  padding-top: 3vh;
  padding-bottom: 3vh;
  max-height: fit-content;
  z-index: 1;
  transition: opacity 500ms ease-in-out;
}
@media (max-width: 480px) {
  .register {
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .register .celebrate-GIF {
    max-width: 100%;
  }
}
.register .sign-up-header {
  font-size: 24px;
  font-weight: 600;
  font-family: "Decoy", sans-serif;
  text-align: center;
}
.register .selection-card__container {
  position: relative;
  display: flex;
  justify-content: space-evenly;
}
@media (max-width: 1280px) {
  .register .selection-card__container {
    flex-direction: column;
    min-width: 100%;
    justify-content: center;
  }
}
.register .selection-card__container li:first-child {
  margin-right: 24px;
}
@media (max-width: 480px) {
  .register .selection-card__container li:first-child {
    margin-right: 0px;
    margin-bottom: 24px;
  }
}
.register .selection-card__container .selection-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid rgba(0, 0, 0, 0.35);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
@media (max-width: 1280px) {
  .register .selection-card__container .selection-card {
    min-width: 100%;
    min-height: 0px;
  }
}
.register .selection-card__container .selection-card img {
  max-width: 70%;
  max-height: 70%;
  background-size: cover;
  background-position: center;
}
@media (max-width: 1280px) {
  .register .selection-card__container .selection-card img {
    max-height: 320px;
  }
}
.register .selection-card__container .selection-card h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  color: #777777;
}
@media (max-width: 480px) {
  .register .selection-card__container .selection-card h1 {
    font-size: 18px;
  }
}
@media (hover: hover) {
  .register .selection-card__container .selection-card:hover {
    box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.2);
  }
}
.register .selection-card__container .selection-card--active {
  border: 3px solid #f67512;
}
.register .selection-card__container .selection-card--active h1 {
  color: #f67512;
}
.register .selection-card__container .selection-card .divider {
  height: 2px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  width: 80%;
}
.register .selection-card__container .selection-card .divider--active {
  border: 1px solid #f67512;
}
.register .selection-card__container .info-panel {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  padding: 12px;
  text-align: left;
  flex-grow: 1;
  min-width: 100%;
  row-gap: 6px;
}
@media (max-width: 1280px) {
  .register .selection-card__container .info-panel {
    min-width: 0px;
  }
}
.register .selection-card__container .info-panel .info-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 24px;
  padding-left: 48px;
}
.register .selection-card__container .info-panel .info-row img {
  padding-right: 12px;
  margin-bottom: auto;
  margin-top: 6px;
  height: 14px;
}
@media (max-width: 1280px) {
  .register .selection-card__container .info-panel .info-row {
    padding-left: 24px;
  }
}

.registration-progress-tracker {
  width: 100%;
}
.registration-progress-tracker .progress-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.registration-progress-tracker .progress-col .progress-col-icon {
  height: 24px;
  width: 24px;
}
.registration-progress-tracker .dot {
  position: relative;
  pointer-events: none;
  opacity: 0.9;
  height: 13px;
  width: 13px;
  margin-top: 1px;
  border-radius: 50%;
}

.room-key-img {
  display: flex;
  height: 160px;
}
@media (max-width: 1280px) {
  .room-key-img {
    margin-left: auto;
    margin-right: auto;
    width: 120px;
    height: 100%;
  }
}

.tr-description-list {
  list-style-type: disc;
  padding: 0;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  padding-right: 24px;
}
.tr-description-list li {
  list-style-position: inside;
  margin-bottom: 12px;
  text-indent: -24px;
}
@media (max-width: 480px) {
  .tr-description-list {
    margin-bottom: 24px;
  }
}

.login-layout {
  position: absolute;
  left: 0;
  right: 0;
  transition: transform, 500ms ease-in;
  transform: scale(0.9);
}
@media (max-width: 1550px) {
  .login-layout {
    transform: scale(0.8);
  }
}
@media (max-width: 1350px) {
  .login-layout {
    transform: scale(0.7);
  }
}
@media (max-width: 1150px) {
  .login-layout {
    transform: scale(0.6);
  }
}
@media (max-width: 1250px) {
  .login-layout {
    transform: scale(1);
    position: relative;
  }
}

.login-mobile__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-top: 48px;
  min-width: 624px;
  max-width: 624px;
}
@media (max-width: 800px) {
  .login-mobile__wrapper {
    margin-top: 24px;
    min-width: 90vw;
    max-width: 90vw;
  }
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login .login-box-shadow-hover {
  transition: box-shadow 500ms ease-in-out;
}
@media (hover: hover) {
  .login .login-box-shadow-hover:hover {
    box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.35);
  }
}
.login .login__container {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1096px;
  min-width: 624px;
  padding: 64px;
  position: relative;
  z-index: 3;
  outline: none;
  height: fit-content;
}
@media (max-width: 1280px) {
  .login .login__container {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 600px) {
  .login .login__container {
    padding: 32px;
  }
}
@media (max-width: 480px) {
  .login .login__container {
    min-width: 90vw;
    max-width: 90vw;
    padding: 24px;
  }
}
.login .login-input {
  height: 58px;
  width: 100%;
  border-radius: 10px;
  padding-left: 24px;
  padding-right: 24px;
  border: 2px solid #aaaaaa;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}
@media (hover: hover) {
  .login .login-input:active {
    outline: 2px solid #f67512;
    outline-offset: -3px;
    border: 2px solid #f67512;
  }
  .login .login-input:hover {
    outline-offset: -3px;
    border: 2px solid #f67512;
  }
}
.login .login-input:focus-visible {
  outline: 3px solid #f67512;
  outline-offset: -3px;
  border: 2px solid #f67512;
}
.login .login-btn__wrapper {
  height: fit-content;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.login .login-btn {
  background: #dc2c2c;
  border: 3px solid rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 12px 0px;
  box-shadow: 0 0 0 2px #dc2c2c;
  white-space: nowrap;
  min-width: 100%;
  height: 64px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .login-btn:hover {
  background: #e03445;
  transition: all 0.6s ease;
}
@media (max-width: 480px) {
  .login .login-btn {
    font-size: 21px;
    text-align: center;
    height: 54px;
  }
}
.login .login-additional {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.login .login-additional button {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 6px;
}
.login .login-additional img {
  margin-top: auto;
  margin-bottom: auto;
  max-height: 24px;
  min-width: 27px;
}
.login .login-additional label {
  cursor: pointer;
}
.login .login-additional .login__checkbox {
  display: flex;
  align-items: center;
  height: fit-content;
}
.login .login-additional .login__checkbox input[type=checkbox] {
  height: 28px;
  min-width: 28px;
  max-width: 28px;
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  font: inherit;
  border: 2px solid rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  transform: translateY(-2px);
  display: grid;
  place-content: center;
  margin: 0px;
  padding-left: 24px;
  margin-top: 4px;
  cursor: pointer;
}
.login .login-additional .login__checkbox input[type=checkbox]::before {
  content: "";
  width: 18px;
  height: 18px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #ff480f;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  margin-right: 24px;
  opacity: 0.8;
  cursor: pointer;
}
.login .login-additional .login__checkbox input[type=checkbox]:checked::before {
  transform: scale(1);
  cursor: pointer;
}

.forgotpassword {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  padding-top: 3vh;
  padding-bottom: 3vh;
  max-height: fit-content;
  z-index: 1;
}
@media (max-width: 480px) {
  .forgotpassword {
    padding: 0px;
  }
}

.account {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-width: 100vw;
}
@media (max-width: 840px) {
  .account {
    flex-direction: column;
  }
}
.account .account-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  min-width: 596px;
  max-width: 596px;
  margin-left: 64px;
}
@media (max-width: 1024px) {
  .account .account-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 496px;
    margin-top: 64px;
  }
}
@media (max-width: 580px) {
  .account .account-content {
    margin-left: 5vw;
    margin-right: 5vw;
    min-width: 0px;
  }
}
.account .account-content .account-code {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  font-size: 16px;
  opacity: 0.6;
  margin-left: auto;
  margin-top: 24px;
}
@media (max-width: 480px) {
  .account .account-content .account-code {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
  }
}
.account .account-content .section {
  display: flex;
  flex-direction: column;
  max-width: 596px;
}
@media (max-width: 1024px) {
  .account .account-content .section {
    margin-left: auto;
    margin-right: auto;
    max-width: 496px;
    min-width: 100%;
  }
}
.account .account-content .section .ignored-event__container {
  display: flex;
  flex-direction: column;
}
.account .account-content .section .ignored-event__container .ignored-event {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  padding: 12px 20px;
}
.account .account-content .section .ignored-event__container .ignored-event p {
  width: fit-content;
}
.account .account-content .section .ignored-event__container .ignored-event button {
  display: flex;
}
.account .account-content .section .ignored-event__container .ignored-event button img {
  max-height: 22px;
  margin-bottom: 2px;
}
.account .account-content .section .install-app {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  width: 100%;
  padding: 12px 20px;
}
.account .account-content .section .install-app:hover {
  border: 1px solid #f67512;
}
.account .account-content .section .textarea-row {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
}
@media (max-width: 480px) {
  .account .account-content .section .textarea-row {
    flex-direction: column;
    row-gap: 24px;
  }
}
.account .account-content .section .profile-image {
  max-height: 264px;
  min-height: 264px;
  max-width: 264px;
  min-width: 264px;
  border-radius: 95%;
  object-fit: cover;
}
.account .account-content .section .profile-image img {
  object-fit: fill;
}
@media (max-width: 480px) {
  .account .account-content .section .profile-image {
    margin: auto;
  }
}

.social-media-links {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
}
.social-media-links li {
  align-items: center;
  justify-content: center;
  min-height: 44px;
  min-width: 44px;
  max-height: 44px;
  max-width: 44px;
}
.social-media-links .social-media-btn {
  min-height: 48px;
  min-width: 48px;
  max-height: 48px;
  max-width: 48px;
  align-items: center;
  justify-content: center;
}
.social-media-links .social-media-logo {
  min-width: 100%;
  min-height: 100%;
  padding: 6px;
}
.social-media-links .highlight {
  height: 8px;
  width: 8px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f67512;
  border-radius: 50%;
  opacity: 0.8;
}
.social-media-links .highlight-placeholder {
  height: 6px;
  width: 100%;
}

.social-media-filled-marker {
  max-height: 21px;
  max-width: 21px;
  margin-bottom: 10px;
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}

.badges-row {
  display: flex;
  flex-direction: row;
  max-height: 38px;
  min-height: 38px;
}

.account-card__wrapper {
  width: 100%;
}
.account-card__wrapper .account-card__container {
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  position: relative;
  font-size: 18px;
}
@media (max-width: 1280px) {
  .account-card__wrapper .account-card__container {
    max-width: 512px;
    padding: 24px;
  }
}
.account-card__wrapper .account-card__container:focus-visible {
  outline: 3px solid blue;
  outline-offset: 6px;
}
.account-card__wrapper .payment-method-logo {
  height: 48px;
}
.account-card__wrapper .visa {
  height: 24px;
}
.account-card__wrapper .content-label {
  color: #777777;
  font-weight: 700;
  font-size: 21px;
  width: fit-content;
}
.account-card__wrapper .content-label:focus-visible {
  outline: 3px solid blue;
  outline-offset: 6px;
}
@media (max-width: 320px) {
  .account-card__wrapper .content-label {
    font-size: 18px;
  }
}

.cycle-card-row {
  display: flex;
  flex-direction: row;
  overflow-x: visible;
  min-height: fit-content;
  margin-bottom: 48px;
  min-width: 100%;
  flex-wrap: wrap;
}

.cycle-card-row--min-height {
  min-height: 400px;
  min-width: 100%;
}

.account-org-plan-card__wrapper {
  display: flex;
  max-width: 400px;
  position: relative;
}
.account-org-plan-card__wrapper .account-org-plan-card {
  color: #777777;
  border-radius: 10px;
}
.account-org-plan-card__wrapper .account-org-plan-card img {
  width: 100%;
}
.account-org-plan-card__wrapper .plan-summary {
  list-style-type: disc;
  width: 100%;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  flex-direction: column;
}
.account-org-plan-card__wrapper .plan-summary li {
  margin-left: 48px;
  text-align: left;
  margin-top: 4px;
}

.account-profile-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 724px;
}

.account-profile-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust thickness */
}

/* Style scrollbar track */
.account-profile-scrollbar::-webkit-scrollbar-track {
  background: transparent; /* Optional: Change track color */
}

/* Style scrollbar handle */
.account-profile-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3); /* Adjust color & transparency */
  border-radius: 4px; /* Rounded edges */
}

/* On hover, make scrollbar more visible */
.account-profile-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

.account-profile__wrapper {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 24px;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  z-index: 2;
}
@media (max-width: 480px) {
  .account-profile__wrapper {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 24px 12px;
  }
}
.account-profile__wrapper .account-profile-content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
}
.account-profile__wrapper .account-profile-content h1 {
  font-size: 18px;
  font-weight: 600;
}
.account-profile__wrapper .account-profile-content p {
  font-size: 18px;
  line-height: 1.6;
}
.account-profile__wrapper .account-profile-content .credential {
  margin-bottom: 36px;
}
.account-profile__wrapper .account-profile-content .credential:last-child {
  margin-bottom: 0px;
}
@media (max-width: 480px) {
  .account-profile__wrapper .account-profile-exit-btn {
    margin-bottom: 36px;
  }
}

.account-profile__wrapper--borders {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
@media (max-width: 480px) {
  .account-profile__wrapper--borders {
    border: 0px solid rgba(0, 0, 0, 0);
  }
}

.account-profile-social-link {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  min-height: 32px;
}

.account-profile-social-link--ghost {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 32px;
}

.account-profile-banner {
  background-color: transparent;
  object-fit: cover;
  object-position: top;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media (max-width: 480px) {
  .account-profile-banner {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
}

.account-profile-banner--image {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 96px;
  max-height: 96px;
  min-width: 512px;
  max-width: 512px;
  object-fit: contain;
  object-position: top;
}
@media (max-width: 480px) {
  .account-profile-banner--image {
    max-width: 100%;
    min-width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    min-height: fit-content;
    max-height: fit-content;
  }
}

.account-previewMode__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.account-previewMode__outline {
  outline: 2px dashed rgba(0, 0, 0, 0.25);
  outline-offset: 8px;
  position: relative;
}

.account-previewMode--outline-header {
  position: absolute;
  right: 6px;
  top: -21px;
  background-color: white;
  padding-left: 6px;
  padding-right: 6px;
}
.account-previewMode--outline-header p {
  font-size: 18px;
  opacity: 0.7;
}

.account-profile-lang-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}
.account-profile-lang-list li {
  margin-bottom: 6px;
}
.account-profile-lang-list li:last-child {
  margin-bottom: 0px;
}

.account-profile-interaction-btns__wrapper {
  display: flex;
  flex-direction: column;
  color: #777777;
  min-width: 100%;
  justify-content: center;
  align-items: center;
}
.account-profile-interaction-btns__wrapper .account-profile-interaction-btns__container {
  display: flex;
  min-width: 100vw;
  flex-wrap: wrap;
  height: fit-content;
  min-height: 0px;
  row-gap: 24px;
  column-gap: 12px;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
}
.account-profile-interaction-btns__wrapper .account-profile-interaction-btns__container img {
  max-height: 28px;
  max-width: 28px;
}
.account-profile-interaction-btns__wrapper .account-profile-interaction-btns__container .interaction-btn {
  max-height: 44px;
  min-height: 44px;
}

.account-profile-return-btn {
  position: absolute;
  top: 8px;
  left: 12px;
  border-radius: 50%;
  padding: 12px;
  z-index: 10;
  background-color: #f67512;
  opacity: 0.98;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
.account-profile-return-btn img {
  padding: 12px;
}

.account-profile__wrapper--empty {
  min-width: 512px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1280px) {
  .account-profile__wrapper--empty {
    min-width: 100%;
  }
}

.account-profile__container--empty {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.organize {
  flex-grow: 1;
  min-height: 100%;
}

.active-events {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.active-events .dot {
  height: 7px;
  width: 7px;
}

.current-grid-bar {
  align-items: center;
  display: flex;
  justify-content: center;
}
.current-grid-bar .heading {
  color: #777777;
  font-size: 18px;
  font-weight: 600;
  margin-left: 12px;
  margin-right: 12px;
}
.current-grid-bar .arrow {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 44px;
  max-width: 44px;
  min-height: 44px;
}
@media (max-width: 480px) {
  .current-grid-bar .arrow {
    padding: 6px;
  }
}

.organize-event-card__wrapper {
  border-radius: 10px;
  box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-width: 798px;
  min-width: 798px;
  transition: 0.45s ease-in-out;
}
@media (hover: hover) {
  .organize-event-card__wrapper:hover {
    box-shadow: 1px 1px 12px 2px rgba(0, 0, 0, 0.3);
  }
}
@media (max-width: 1280px) {
  .organize-event-card__wrapper {
    width: 80vw;
  }
}
@media (max-width: 480px) {
  .organize-event-card__wrapper {
    max-width: 100%;
    min-width: 0px;
    width: 90vw;
  }
}
.organize-event-card__wrapper .quick-info__container {
  align-items: center;
  display: flex;
  max-height: 198px;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .quick-info__container {
    max-height: fit-content;
  }
}
.organize-event-card__wrapper .quick-info__container .bottom-divider {
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}
.organize-event-card__wrapper .quick-info__container .title-row {
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  max-height: 50px;
  max-width: 446px;
  min-height: 50px;
  overflow: hidden;
  padding-left: 18px;
  padding-right: 18px;
  text-align: center;
  white-space: nowrap;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .quick-info__container .title-row {
    margin-top: 6px;
    max-height: fit-content;
  }
}
@media (max-width: 320px) {
  .organize-event-card__wrapper .quick-info__container .title-row {
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
.organize-event-card__wrapper .quick-info__container .title-row .title {
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.organize-event-card__wrapper .quick-info__container .event-status-icon {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #ffffff;
  border-radius: 50%;
  height: 36px;
  opacity: 0.9;
}
.organize-event-card__wrapper .quick-info__container .event-display {
  display: flex;
  margin-left: auto;
  position: relative;
}
.organize-event-card__wrapper .quick-info__container .event-display .event-image {
  border-top-right-radius: 10px;
  height: 100%;
  margin-left: auto;
  object-fit: contain;
  width: fit-content;
}
.organize-event-card__wrapper .quick-info__container .info-row {
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-top: 6px;
  min-height: 42px;
  padding-left: 18px;
  padding-right: 18px;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .quick-info__container .info-row {
    margin-top: 12px;
  }
}
.organize-event-card__wrapper .quick-info__container .info-row .address-content {
  overflow: hidden;
}
.organize-event-card__wrapper .quick-info__container .info-row .address-content p {
  display: block;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.organize-event-card__wrapper .quick-info__container .icon-container {
  height: 32px;
  margin-right: 12px;
  max-width: 32px;
  min-width: 32px;
}
.organize-event-card__wrapper .quick-info__container .icon-container-2 {
  height: 36px;
  margin-right: 6px;
  max-width: 36px;
  min-width: 36px;
}
.organize-event-card__wrapper .quick-info__container .organizer-profile-img {
  border-radius: 50%;
  max-height: 32px;
  max-width: 32px;
  min-height: 32px;
  min-width: 32px;
}
.organize-event-card__wrapper .card-actions__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .card-actions__wrapper {
    overflow-x: scroll;
  }
}
.organize-event-card__wrapper .card-actions__wrapper .fade-left {
  background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.3));
  height: 100%;
  opacity: 0.4;
  position: absolute;
  width: 26px;
  z-index: 4;
}
.organize-event-card__wrapper .card-actions__wrapper .fade-right {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.3));
  height: 100%;
  opacity: 0.4;
  position: absolute;
  right: 0;
  width: 26px;
  z-index: 4;
}
.organize-event-card__wrapper .card-actions__wrapper .card-actions__container {
  position: relative;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .card-actions__wrapper .card-actions__container {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
    margin-top: 24px;
    max-width: 100%;
  }
}
.organize-event-card__wrapper .card-actions__wrapper .actions {
  display: flex;
  list-style: none;
  padding: 24px 24px 24px 24px;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .card-actions__wrapper .actions {
    min-width: 100%;
    overflow-x: scroll;
    padding: 12px 0px;
  }
}
.organize-event-card__wrapper .card-actions__wrapper .actions::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
.organize-event-card__wrapper .card-actions__wrapper .actions .action {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;
  position: relative;
  transition: transform 50ms ease-in;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .card-actions__wrapper .actions .action {
    margin-left: 12px;
    margin-right: 12px;
  }
}
.organize-event-card__wrapper .card-actions__wrapper .actions .action img {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-height: 54px;
  max-width: 58px;
  min-height: 54px;
  min-width: 58px;
}
@media (max-width: 320px) {
  .organize-event-card__wrapper .card-actions__wrapper .actions .action img {
    transform: scale(0.9);
  }
}
.organize-event-card__wrapper .card-actions__wrapper .actions .selected img {
  border: 1px solid #ff8585;
  outline: 2px solid #ff8585;
}
.organize-event-card__wrapper .action-port {
  display: flex;
  height: fit-content;
  justify-content: center;
  width: 100%;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper h2 {
  margin: 12px 0px;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper {
    text-align: center;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .organizer-eventcard-registrations__container {
  display: flex;
  justify-content: space-evenly;
  min-height: 100%;
  padding-bottom: 12px;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .organizer-eventcard-registrations__container {
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column {
  align-items: center;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  min-height: fit-content;
  overflow: hidden;
  position: relative;
  width: 100%;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column:first-child {
    margin-bottom: 24px;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list-sub-actions {
  align-items: center;
  display: flex;
  max-height: 52px;
  min-height: 52px;
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  max-width: 398px;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list-sub-actions {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registrations-heading {
  align-items: center;
  border-radius: 10px;
  color: #333333;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: center;
  margin-left: 12px;
  margin-right: 12px;
  padding: 8px 6px;
  position: relative;
  width: 100%;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registrations-heading img {
  height: 18px;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registrations-heading p {
  max-width: fit-content;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registrations-heading {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
  }
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registrations-heading img {
    margin-right: 16px;
    height: 28px;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .fade-top {
  background: linear-gradient(to top, transparent, rgba(0, 0, 0, 0.3));
  display: flex;
  height: 36px;
  justify-content: center;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.4;
  position: absolute;
  right: 0;
  width: 94%;
  z-index: 4;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .fade-top img {
  height: 12px;
  margin-top: 8px;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .fade-top {
    width: 100%;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .fade-bottom {
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3));
  bottom: 0;
  display: flex;
  height: 36px;
  justify-content: center;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.4;
  position: absolute;
  right: 0;
  width: 94%;
  z-index: 4;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .fade-bottom img {
  height: 12px;
  margin-top: 16px;
  opacity: 1;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .fade-bottom {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column {
    border-left: 0px solid rgba(0, 0, 0, 0);
    min-height: 296px;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column:first-child {
  border-left: 0px;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: 399px;
  min-height: 196px;
  overflow-y: auto;
  width: 100%;
  z-index: 2;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list::-webkit-scrollbar {
  width: 0px;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list {
    min-height: fit-content;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .account-tag {
  background-color: #ffffff;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 13px;
  max-height: 120px;
  max-width: 375px;
  min-height: 120px;
  min-width: 100%;
  min-width: 375px;
  padding: 12px 12px 8px 12px;
  position: relative;
  z-index: 3;
}
@media (max-width: 480px) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .account-tag {
    min-width: 100%;
    max-width: 100%;
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .account-tag:nth-child(odd) {
  margin-left: 0px;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .account-tag .badges {
  align-items: center;
  display: flex;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .account-tag .badges img {
  height: 24px;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .account-tag .transfer-icon {
  height: 21px;
  filter: invert(50%) sepia(39%) saturate(504%) hue-rotate(77deg) brightness(102%) contrast(89%);
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .registration-action-btn {
  min-height: 22px;
  min-width: 22px;
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .registration-action-btn .remove-img {
  bottom: 4px;
  max-height: 44px;
  min-height: 44px;
  padding: 12px;
  position: absolute;
  right: 0;
}
@media (hover: hover) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .registration-action-btn .remove-img:hover {
    filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .registration-action-btn .approve-img {
  bottom: 4px;
  max-height: 44px;
  min-height: 44px;
  padding: 12px;
  position: absolute;
  right: 0;
}
@media (hover: hover) {
  .organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .registration-action-btn .approve-img:hover {
    filter: invert(72%) sepia(52%) saturate(383%) hue-rotate(77deg) brightness(90%) contrast(91%);
  }
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .approve-btn:focus-visible img {
  filter: invert(72%) sepia(52%) saturate(383%) hue-rotate(77deg) brightness(90%) contrast(91%);
}
.organize-event-card__wrapper .organizer-eventcard-registrations__wrapper .registrations-column .registration-list .remove-btn:focus-visible img {
  filter: brightness(0) saturate(100%) invert(22%) sepia(96%) saturate(7495%) hue-rotate(353deg) brightness(95%) contrast(93%);
}

.editActions-btns {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 24px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 24px;
}
@media (max-width: 480px) {
  .editActions-btns {
    width: 90%;
  }
}
.editActions-btns .action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30%;
  box-shadow: 0px 0px 4px 0.5px rgba(0, 0, 0, 0.15);
  min-width: 62px;
  min-height: 62px;
  transition: 50ms ease-in;
  background-color: #ffffff;
}
.editActions-btns .action-btn:focus-visible {
  outline: 3px solid blue;
  transition: none;
  outline-offset: 6px;
}

.participant__container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
}

.participant-list-item__wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  transition: 250ms ease-in;
  border: 2px solid rgba(0, 0, 0, 0);
  padding-left: 6px;
  padding-right: 6px;
  margin-right: 6px;
  margin-left: 6px;
}
@media (max-width: 480px) {
  .participant-list-item__wrapper {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.participant-list-item__wrapper .participant-list-item__container {
  display: flex;
  height: fit-content;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  width: 100%;
  cursor: default;
}
@media (max-width: 480px) {
  .participant-list-item__wrapper .participant-list-item__container {
    padding-top: 0px;
    padding-right: 0px;
  }
}
.participant-list-item__wrapper .participant-list-item__container .participant-contact--profile {
  max-height: 48px;
  max-width: 48px;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
}
.participant-list-item__wrapper .participant-list-item__container .participant-contact-view {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0;
  background-color: rgba(233, 230, 230, 0.35);
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 150ms;
}
.participant-list-item__wrapper .participant-list-item__container .participant-contact-view img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}
.participant-list-item__wrapper .participant-list-item__container .participant-name-status {
  display: flex;
  flex-direction: column;
}
.participant-list-item__wrapper .participant-contact__actions-container {
  display: flex;
}

@media (max-width: 480px) {
  .participant-list-item__wrapper--dispatches {
    margin: 0px;
    padding: 0px;
  }
}
@media (max-width: 480px) {
  .participant-list-item__wrapper--dispatches .participant-list-item__container--dispatches {
    padding: 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 0px;
  }
}

.participant-list-item__wrapper--border-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
}

@media (hover: hover) {
  .participant-list-item__wrapper--hover:hover {
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.25);
  }
}
.participant-list-item__wrapper--hover .participant-list-item__container {
  cursor: pointer;
}

.participant-list-item__status-box {
  display: flex;
  padding: 0px 6px;
  border-radius: 4px;
  margin-top: 4px;
  border: 2px solid rgba(0, 0, 0, 0.15);
  width: fit-content;
  background-color: white;
}
@media (max-width: 480px) {
  .participant-list-item__status-box {
    border: 2px solid rgba(0, 0, 0, 0);
    padding: 0px 0px;
  }
}

.participant-list-item__status-box--dispatches {
  display: flex;
  padding: 0px 6px;
  border-radius: 4px;
  margin-top: 4px;
  border: 2px solid rgba(0, 0, 0, 0.15);
  width: fit-content;
  background-color: white;
}

.participant-base__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.participant-base__container {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1000px) {
  .participant-base__container {
    flex-direction: column;
  }
}

.create-event__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  flex-grow: 1;
}

.create-event {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1280px) {
  .create-event {
    margin-top: 24px;
  }
}
.create-event .ce-col-row--mob {
  display: flex;
}
@media (max-width: 1280px) {
  .create-event .ce-col-row--mob {
    flex-direction: column;
    width: 100%;
  }
}
.create-event .ce-row-col--mob {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1280px) {
  .create-event .ce-row-col--mob {
    flex-direction: row;
    width: 100%;
  }
}
.create-event .ce-space-between--mob {
  justify-content: space-between;
}
@media (max-width: 1280px) {
  .create-event .ce-space-between--mob {
    justify-content: center;
  }
}
.create-event .ce-space-evenly--mob {
  justify-content: space-evenly;
}
@media (max-width: 1280px) {
  .create-event .ce-space-evenly--mob {
    justify-content: flex-start;
  }
}
.create-event .ce-align-left--mob {
  align-items: left;
}
@media (max-width: 1280px) {
  .create-event .ce-align-left--mob {
    align-items: center;
  }
}
.create-event .ce-align-right--mob {
  align-items: right;
}
@media (max-width: 1280px) {
  .create-event .ce-align-right--mob {
    align-items: center;
  }
}
.create-event .ce-h24--mob {
  height: 0;
  width: 100%;
}
@media (max-width: 1280px) {
  .create-event .ce-h24--mob {
    height: 24px;
  }
}
.create-event .ce-h24-h48--mob {
  height: 24px;
  width: 100%;
}
@media (max-width: 1280px) {
  .create-event .ce-h24-h48--mob {
    height: 48px;
  }
}
.create-event .fieldset {
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin-bottom: 96px;
  max-width: 1036px;
  min-width: 1036px;
  padding: 48px 78px 48px 78px;
  position: relative;
}
@media (max-width: 1280px) {
  .create-event .fieldset {
    width: 100%;
  }
}
.create-event .fieldset:focus-visible {
  outline: 3px solid blue;
  outline-offset: 6px;
}
@media (max-width: 1280px) {
  .create-event .fieldset {
    min-width: 85vw;
    max-width: 85vw;
    padding: 36px;
  }
}
@media (max-width: 480px) {
  .create-event .fieldset {
    padding: 0px 0px 0px 0px;
    padding-left: 2vw;
    padding-right: 2vw;
    min-width: 100vw;
    max-width: 100vw;
    border: 0px;
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}
.create-event .fieldset .label {
  position: absolute;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  font-size: 21px;
  top: -16px;
  left: 27px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: #ffffff;
}
.create-event .fieldset .label:focus-visible {
  outline: 3px solid blue;
}
@media (max-width: 480px) {
  .create-event .fieldset .label {
    position: relative;
    top: 0;
    left: 0;
    padding: 0px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    min-width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
  }
}
.create-event .fieldset .no-mrg {
  margin: 0px;
}
.create-event .fieldset .no-padding {
  padding: 0px;
}
.create-event .ready-status {
  position: absolute;
  font-size: 21px;
  font-weight: 600;
  top: -14px;
  right: 21px;
  background-color: white;
  padding-left: 6px;
  padding-right: 6px;
}
@media (max-width: 480px) {
  .create-event .ready-status {
    top: 6px;
    background-color: transparent;
    right: 12px;
  }
}

.create-speaker {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fafafa;
  border-radius: 4px;
  padding: 12px 24px;
  margin-bottom: 24px;
}
@media (max-width: 480px) {
  .create-speaker {
    padding: 12px 12px;
  }
}

.gmap-wrapper .map-instructions {
  display: none;
}
.gmap-wrapper .gm-style-cc {
  display: none;
}
.gmap-wrapper:hover .gmap {
  border: 1px solid #f67512 !important;
}
.gmap-wrapper:hover .map-instructions {
  display: flex;
  flex-direction: column;
  place-content: center;
  margin-top: 16px;
  margin-bottom: 0px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.appoint-organizers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 24px;
}
.appoint-organizers button {
  min-width: 198px;
  margin-right: 48px;
}
@media (max-width: 480px) {
  .appoint-organizers {
    flex-direction: column;
  }
  .appoint-organizers button {
    min-width: 100%;
    margin-bottom: 24px;
  }
  .appoint-organizers button:last-child {
    margin-bottom: 0px;
  }
}

.validations__wrapper {
  display: flex;
  flex-direction: column;
}
.validations__wrapper .validations-title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  max-width: fit-content;
}
@media (max-width: 480px) {
  .validations__wrapper .validations-title {
    margin-left: auto;
    margin-right: auto;
  }
}

.create-event-participants {
  list-style-type: none;
}
.create-event-participants .create-event-participant {
  display: flex;
  align-items: center;
  min-height: 44px;
  border: 2px solid rgba(0, 0, 0, 0.45);
  border-radius: 10px;
  padding: 6px;
  width: fit-content;
}
.create-event-participants .create-event-participant .participant-index {
  font-size: 18px;
  border-right: 2px solid rgba(0, 0, 0, 0.45);
  padding-right: 12px;
  padding-left: 6px;
}
.create-event-participants .create-event-participant .participant-type {
  font-size: 18px;
  border-right: 2px solid rgba(0, 0, 0, 0.45);
  padding-right: 12px;
}
.create-event-participants .create-event-participant .participant-status {
  font-size: 18px;
  border-right: 2px solid rgba(0, 0, 0, 0.45);
  padding-right: 12px;
}

.event-additional__wrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 36px;
  column-gap: 24px;
  position: relative;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  .event-additional__wrapper {
    row-gap: 0px;
    column-gap: 0px;
    flex-direction: column;
    align-items: center;
  }
}
.event-additional__wrapper .event-additional__container {
  width: 352px;
}
@media (max-width: 480px) {
  .event-additional__wrapper .event-additional__container {
    padding-bottom: 24px;
  }
}

.summary-heading {
  display: flex;
  margin-left: 148px;
  margin-right: 148px;
  justify-content: space-between;
  white-space: nowrap;
  align-items: center;
  min-height: 52px;
}
@media (max-width: 1280px) {
  .summary-heading {
    justify-content: center;
  }
}
.summary-heading .tags-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  max-height: 54px;
}
.summary-heading .tags-toggle-arrow {
  height: 21px;
  width: 21px;
  opacity: 0.5;
  margin-right: 12px;
}
.summary-heading .divider {
  width: 2px;
  min-height: 28px;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
}
.summary-heading .tags-sum {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}

.demo-summary-heading {
  justify-content: space-between;
}
@media (max-width: 1280px) {
  .demo-summary-heading {
    justify-content: space-between;
  }
}

.search-sort {
  display: flex;
  height: fit-content;
  justify-content: space-between;
  margin-left: 148px;
  margin-right: 148px;
}
.search-sort .filters-button {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  padding: 12px 24px;
}
.search-sort .filters {
  display: flex;
  column-gap: 14px;
  min-height: 54px;
  max-height: 54px;
}
.search-sort .filters ul {
  column-gap: 14px;
  list-style: none;
}
.search-sort .filters button {
  max-height: 54px;
}
.search-sort .filters .dot {
  width: 7px;
  height: 7px;
  pointer-events: none;
  opacity: 0.9;
  border-radius: 50%;
}
.search-sort .filters img {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  cursor: pointer;
  min-height: 54px;
  max-height: 54px;
  min-width: 58px;
  max-width: 58px;
}
.search-sort .filters img:last-child {
  margin-right: 0px;
}
.search-sort .filters .selected {
  outline: 3px solid #ff8585;
  border: 1px solid rgba(0, 0, 0, 0);
}
.search-sort .filters .divider {
  width: 2px;
  min-height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.2);
}

.view__container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 14px;
  list-style: none;
  margin-left: 4px;
  max-height: 54px;
}
.view__container button {
  max-height: 54px;
}
@media (max-width: 480px) {
  .view__container {
    column-gap: 0px;
    justify-content: space-evenly;
    margin-left: 0px;
  }
}
.view__container .view-img__container {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  cursor: pointer;
  min-height: 56px;
  max-height: 56px;
  min-width: 60px;
  max-width: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
}
@media (max-width: 320px) {
  .view__container .view-img__container {
    transform: scale(0.9);
  }
}
.view__container .selected {
  outline: 3px solid #ff8585;
  border: 1px solid rgba(0, 0, 0, 0);
}

.settings-tags__wrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 148px;
  margin-right: 148px;
}
@media (max-width: 1280px) {
  .settings-tags__wrapper {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.settings-tags__wrapper .settings-tags__container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  row-gap: 12px;
  max-width: 100%;
}
@media (max-width: 1280px) {
  .settings-tags__wrapper .settings-tags__container {
    row-gap: 16px;
  }
}
.settings-tags__wrapper .settings-tags__container li {
  max-width: 100%;
  height: fit-content;
}
.settings-tags__wrapper .settings-tags__container .settings-tag {
  align-items: center;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  font-size: 18px;
  justify-content: center;
  min-height: 52px;
  max-width: 50vw;
  max-width: 100%;
}
.settings-tags__wrapper .settings-tags__container .settings-tag p {
  max-width: 100%;
  text-align: left;
  word-wrap: break-word;
}
@media (max-width: 480px) {
  .settings-tags__wrapper .settings-tags__container .settings-tag {
    font-size: 16px;
  }
}
.settings-tags__wrapper .settings-tags__container .settings-tag .folder-img {
  height: 24px;
  margin-right: 6px;
}
.settings-tags__wrapper .settings-tags__container .settings-tag--large {
  min-height: 54px;
}
.settings-tags__wrapper .settings-tags__container .selected {
  border: 2px solid #ff8585;
}

.search-sort--mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
}

.filters-mobile {
  background: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 696px;
  min-width: 696px;
  z-index: 6;
  -webkit-tap-highlight-color: transparent;
}
@media (max-width: 800px) {
  .filters-mobile {
    max-width: 90vw;
    min-width: 90vw;
  }
}
.filters-mobile .arrow {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(0, 0, 0, 0.7);
}
.filters-mobile .top-btn-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  border-radius: 10px;
  max-width: 100%;
}
.filters-mobile .top-btn-bar .icon-summary {
  display: flex;
  align-items: center;
}
@media (max-width: 320px) {
  .filters-mobile .top-btn-bar .icon-summary h1 {
    font-size: 18px;
  }
  .filters-mobile .top-btn-bar .icon-summary .tT-icon {
    height: 48px;
    width: 48px;
  }
  .filters-mobile .top-btn-bar .icon-summary .tG-icon {
    height: 28px;
    width: 28px;
  }
}
.filters-mobile .filter-category {
  display: flex;
  list-style: none;
  column-gap: 12px;
  min-height: 48px;
  max-height: 48px;
}
.filters-mobile .filter-category button {
  min-height: 48px;
  max-height: 48px;
}
.filters-mobile .filter-category li {
  position: relative;
}
.filters-mobile .filter-category img {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  cursor: pointer;
  min-height: 48px;
  max-height: 48px;
  min-width: 52px;
  max-width: 52px;
}
@media (max-width: 480px) {
  .filters-mobile .filter-category img {
    margin: 0px 6px 0px 0px;
  }
}
@media (max-width: 320px) {
  .filters-mobile .filter-category img {
    min-height: 48px;
    max-height: 48px;
    min-width: 52px;
    max-width: 52px;
    margin: 0px 8px 0px 0px;
  }
}
.filters-mobile .filter-category .unavailable {
  cursor: not-allowed;
}
.filters-mobile .filter-category .selected {
  outline: 3px solid #ff8585;
  border: 1px solid rgba(0, 0, 0, 0);
}
.filters-mobile .filter-category .selected-tag {
  outline: 2px solid #ff8585;
  border: 1px solid rgba(0, 0, 0, 0);
}

.filters-mobile-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.filters-mobile-border:focus-visible {
  border: 1px solid #f67512;
}
@media (hover: hover) {
  .filters-mobile-border:hover {
    border: 1px solid #f67512;
  }
}

.demo-filters-mobile-width {
  max-width: 100%;
  min-width: 100%;
}

.filters-mobile-border--tap {
  border: 1px solid #f67512;
}

.search-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal;
  flex-wrap: wrap;
  min-height: 26px;
}
.search-summary p {
  text-align: center;
  word-wrap: break-word;
}
@media (max-width: 480px) {
  .search-summary {
    margin-left: 3vw;
    margin-right: 3vw;
    min-width: 94vw;
    max-width: 94vw;
  }
}

.translations {
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  min-height: 596px;
}
@media (max-width: 1280px) {
  .translations {
    max-width: 80%;
  }
}
@media (max-width: 1280px) {
  .translations {
    max-width: 98%;
  }
}

.translations__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 48px;
  margin-bottom: 48px;
  background-color: white;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 48px 24px;
  max-width: 496px;
  min-width: 496px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 520px) {
  .translations__container {
    max-width: 98%;
    min-width: 98%;
  }
}
@media (max-width: 480px) {
  .translations__container {
    margin-bottom: 48px;
  }
}

.translations__container--no-box-shadow {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}

@media (max-width: 480px) {
  .translations__container--no-bg--ph {
    background-color: transparent;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

.translations__container--tutorial {
  max-width: 796px;
  min-width: 796px;
  padding: 48px;
}
@media (max-width: 896px) {
  .translations__container--tutorial {
    max-width: 100%;
    min-width: fit-content;
    padding: 24px;
  }
}

.translations-room {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.translations-room .translations-max-width {
  max-width: 500px;
}
.translations-room .translations-room__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 1280px) {
  .translations-room .translations-room__container {
    flex-direction: column;
  }
}
.translations-room .translations-room__container .openSpeakerDetails {
  position: absolute;
  top: 2px;
  bottom: 0;
  left: 10px;
}
.translations-room .translations-room__container .translations-header-mobile {
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
}
.translations-room .translations-room__container .action-btns {
  min-width: 240px;
  max-width: 240px;
}
@media (max-width: 1280px) {
  .translations-room .translations-room__container .action-btns {
    min-width: 100%;
    max-width: 100%;
  }
}
.translations-room .participants-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
@media (max-width: 480px) {
  .translations-room .participants-container {
    flex-direction: column;
  }
}

.translations-room-margins {
  margin-left: 20%;
  margin-right: 20%;
}
@media (max-width: 1850px) {
  .translations-room-margins {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.play-btn {
  max-height: 75px;
  min-height: 75px;
  border-radius: 4px;
  color: #333333;
  background-color: white;
}
@media (max-width: 1280px) {
  .play-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.translations-selections {
  max-width: 396px;
  min-width: 396px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 480px) {
  .translations-selections {
    min-width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
}
.translations-selections .translations-speaker-btn__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  white-space: wrap;
  height: fit-content;
  word-break: break-word;
}
.translations-selections .translations-speaker-btn__container .translations-speaker-btn {
  min-width: 44px;
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.translations-selections .translations-speaker-btn__container .translations-speaker-btn img {
  min-height: 24px;
}

.translations-create-draft__wrapper {
  max-width: 498px;
  min-width: 498px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1280px) {
  .translations-create-draft__wrapper {
    width: 80vw;
  }
}
@media (max-width: 480px) {
  .translations-create-draft__wrapper {
    max-width: 100%;
    min-width: 0px;
    width: 90vw;
  }
}
.translations-create-draft__wrapper .content-heading-styled {
  background-color: rgba(0, 0, 0, 0.1);
  padding-left: 6px;
  padding-right: 6px;
  font-size: 21px;
  width: 100%;
}

.translations-room-code {
  display: flex;
  white-space: nowrap;
  font-size: 21px;
  font-weight: 600;
  align-items: center;
  flex-direction: column;
}

.translations-room-code-container {
  max-width: fit-content;
  align-items: center;
  display: flex;
  max-height: 80px;
}
@media (max-width: 1280px) {
  .translations-room-code-container {
    font-size: 21px;
  }
}

.translation-text-box {
  position: relative;
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.4);
  padding: 24px;
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  margin-top: 24px;
}
@media (max-width: 1280px) {
  .translation-text-box {
    padding: 12px;
  }
}
@media (max-width: 480px) {
  .translation-text-box {
    padding: 12px;
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .translation-text-box {
    padding: 6px;
  }
}
.translation-text-box li {
  display: flex;
  margin-bottom: 12px;
  width: fit-content;
  padding: 6px;
  list-style-type: none;
}
@media (max-width: 1280px) {
  .translation-text-box li {
    margin-bottom: 0px;
  }
}
@media (max-width: 480px) {
  .translation-text-box li {
    padding-left: 0px;
  }
}
.translation-text-box li:focus-visible {
  outline: 3px solid blue;
  transition: none;
}
.translation-text-box li:first-child {
  margin-top: 12px;
  padding-top: 12px;
}
@media (max-width: 480px) {
  .translation-text-box li:first-child {
    margin-top: 24px;
    padding-top: 0px;
  }
}
@media (max-width: 320px) {
  .translation-text-box li:first-child {
    padding-top: 6px;
  }
}
.translation-text-box .translation-text-box-warning {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: 12px;
  margin-left: 12px;
}
.translation-text-box .translation-text-box-warning p {
  font-size: 24px;
  margin-left: 12px;
}
@media (max-width: 480px) {
  .translation-text-box .translation-text-box-warning p {
    font-size: 18px;
  }
}
@media (max-width: 320px) {
  .translation-text-box .translation-text-box-warning p {
    font-size: 16px;
  }
}
.translation-text-box .translation-text-box-warning img {
  height: 36px;
}
@media (max-width: 320px) {
  .translation-text-box .translation-text-box-warning img {
    height: 28px;
  }
}

.translation-text-box--light {
  background-color: white;
  color: black;
}

.translation-text-box--dark {
  background-color: black;
  color: white;
}

.translation-text-box--inactive li {
  display: none;
}

.translation-box-btn {
  position: absolute;
  z-index: 3;
}

.translation-text-box--maximized {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 20;
  border: 0px solid rgba(0, 0, 0, 0.4);
  border-radius: 0px;
  margin-top: 0px;
  font-size: 32px;
  padding: 64px;
  padding-top: 160px;
  scrollbar-width: none;
}
@media (max-width: 480px) {
  .translation-text-box--maximized {
    padding-top: 180px;
    padding: 12px;
    font-size: 18px;
    padding-top: 75px;
  }
}

.translation-text-box-maximized-header {
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 48px;
  padding-bottom: 48px;
  align-items: center;
}
.translation-text-box-maximized-header p {
  margin-left: 12px;
}
@media (max-width: 1280px) {
  .translation-text-box-maximized-header {
    padding: 24px;
  }
}
@media (max-width: 480px) {
  .translation-text-box-maximized-header {
    padding: 12px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
  }
}
@media (max-width: 320px) {
  .translation-text-box-maximized-header p {
    margin-left: 6px;
  }
}
.translation-text-box-maximized-header .translation-text-box-logo-container {
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.translation-text-box-maximized-header .translation-text-box-logo-container p {
  font-size: 21px;
}
.translation-text-box-maximized-header .translation-text-box-logo-container img {
  max-height: 54px;
}
@media (max-width: 480px) {
  .translation-text-box-maximized-header .translation-text-box-logo-container p {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .translation-text-box-maximized-header .translation-text-box-logo-container p {
    font-size: 14px;
  }
}
.translation-text-box-maximized-header .translation-text-box-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.translation-text-box-maximized-header .translation-text-box-btn img {
  min-height: 44px;
  min-width: 44px;
  padding: 6px;
}
@media (max-width: 480px) {
  .translation-text-box-maximized-header .translation-text-box-btn img {
    padding: 8px;
  }
}
@media (max-width: 320px) {
  .translation-text-box-maximized-header .translation-text-box-btn img {
    padding: 0px;
    margin-right: 0px;
    min-height: 28px;
    max-height: 28px;
    min-width: 28px;
  }
}
.translation-text-box-maximized-header .translation-text-box-btn:first-child {
  margin-right: 24px;
}
@media (max-width: 480px) {
  .translation-text-box-maximized-header .translation-text-box-btn:first-child {
    margin-right: 0px;
  }
}
@media (max-width: 320px) {
  .translation-text-box-maximized-header .translation-text-box-btn:first-child {
    margin-top: 12px;
  }
  .translation-text-box-maximized-header .translation-text-box-btn:last-child {
    padding: 4px;
  }
}

.translations-btn__container {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  padding: 24px 12px;
  color: #333333;
}
@media (max-width: 320px) {
  .translations-btn__container {
    padding: 12px;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.activate-translations-btn {
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.activate-translations-btn--small {
  border-radius: 50%;
  height: 18px;
  width: 18px;
  margin-left: 12px;
}
@media (max-width: 320px) {
  .activate-translations-btn--small {
    margin-left: 6px;
  }
}

.translations-btn-active {
  animation: flash 1.75s infinite;
  background-color: red;
  transition: background-color 0.3s ease-in-out;
}

.translations-guide {
  font-size: 18px;
  max-width: 600px;
  position: relative;
}
.translations-guide label {
  font-weight: 600;
  margin-bottom: 6px;
  text-decoration: underline;
}
.translations-guide ul li {
  list-style-type: disc;
  margin-bottom: 12px;
  align-items: center;
  align-items: flex-start;
}
.translations-guide ul p {
  white-space: wrap;
}
.translations-guide ul img {
  height: 16px;
  margin-top: 3px;
}

.translations-call-to-action {
  display: flex;
  padding: 12px 24px;
  border: 2px solid #dc2c2c;
  border-radius: 10px;
  font-size: 18px;
  z-index: 6;
  position: absolute;
  top: 0;
  bottom: 0;
  max-height: 60px;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}
@media (max-width: 480px) {
  .translations-call-to-action {
    font-size: 16px;
  }
}
@media (hover: hover) {
  .translations-call-to-action:hover {
    outline: 1px solid #dc2c2c;
  }
}

.translations-call-to-action--tap {
  outline: 1px solid #dc2c2c;
}

.translations-modal {
  min-width: 600px;
  max-width: 600px;
  height: fit-content;
}
@media (max-width: 1280px) {
  .translations-modal {
    min-width: 500px;
    max-width: 500px;
  }
}
@media (max-width: 480px) {
  .translations-modal {
    min-width: 100%;
    max-width: 100%;
  }
}
.translations-modal .translations-modal-keywordsList {
  padding: 0px 12px 12px 0px;
}
@media (max-width: 480px) {
  .translations-modal .translations-modal-keywordsList {
    margin-bottom: 76px;
  }
}
@media (max-width: 320px) {
  .translations-modal .translations-modal-keywordsList {
    margin-bottom: 126px;
  }
}

.no-screen-timeout {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 380px;
  margin-right: auto;
  padding: 6px 24px;
}
@media (max-width: 480px) {
  .no-screen-timeout {
    margin-left: auto;
  }
}
.no-screen-timeout img {
  height: 24px;
}

.translations-dot-row {
  height: 36px;
}

.translations-checkmark {
  border-radius: 50%;
  padding: 6px;
  height: 34px;
  width: 34px;
}

.translations-checkmark--selected {
  border-radius: 50%;
  outline: 3px solid #64c071;
  padding: 6px;
  height: 34px;
  width: 34px;
}

.translations-dot {
  border-radius: 50%;
  padding: 6px;
  height: 16px;
  width: 16px;
}

.translations-dot--selected {
  border-radius: 50%;
  outline: 3px solid #f67512;
  outline-offset: 6px;
  padding: 6px;
  height: 16px;
  width: 16px;
}

.transcripts-bottom-btn {
  position: absolute;
  right: 0;
  width: fit-content;
}
@media (max-width: 750px) {
  .transcripts-bottom-btn {
    position: relative;
    margin-top: 12px;
  }
}

.transcript-keyword {
  font-size: 16px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  margin-right: 12px;
  padding: 6px;
  width: fit-content;
}

.fs__wrapper {
  display: flex;
  margin-left: 148px;
  margin-right: 148px;
  margin-top: 24px;
  flex-direction: column;
}
@media (max-width: 1650px) {
  .fs__wrapper {
    max-width: 1250px;
    min-width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1280px) {
  .fs__wrapper {
    max-width: 80%;
    min-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 800px) {
  .fs__wrapper {
    max-width: 100%;
    margin-left: 24px;
    margin-right: 24px;
  }
}
@media (max-width: 480px) {
  .fs__wrapper {
    margin-left: 12px;
    margin-right: 12px;
  }
}

.fs-search-filters__wrapper {
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 36px;
  position: relative;
  margin-bottom: 48px;
  min-width: 100%;
  flex-direction: column;
  background-color: white;
}
.fs-search-filters__wrapper:focus-visible {
  outline: 3px solid blue;
  outline-offset: 6px;
}
@media (max-width: 1280px) {
  .fs-search-filters__wrapper {
    margin-top: 24px;
    padding: 36px 24px;
  }
}
@media (max-width: 480px) {
  .fs-search-filters__wrapper {
    padding: 36px 12px;
  }
}
.fs-search-filters__wrapper .query-location {
  display: flex;
  flex-direction: column;
  min-width: 25%;
  max-width: 25%;
  padding-right: 24px;
}
@media (max-width: 1650px) {
  .fs-search-filters__wrapper .query-location {
    max-width: 650px;
    min-width: 650px;
    padding-right: 0px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1280px) {
  .fs-search-filters__wrapper .query-location {
    max-width: 80%;
    min-width: 80%;
  }
}
@media (max-width: 480px) {
  .fs-search-filters__wrapper .query-location {
    max-width: 100%;
    min-width: 100%;
  }
}
.fs-search-filters__wrapper .query-category {
  display: flex;
  flex-direction: column;
  min-width: 33%;
  max-width: 33%;
  padding-right: 24px;
  padding-left: 24px;
  flex-grow: 1;
}
.fs-search-filters__wrapper .query-category:last-child {
  padding-right: 0px;
}
@media (max-width: 1650px) {
  .fs-search-filters__wrapper .query-category {
    max-width: 650px;
    min-width: 650px;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 480px) {
  .fs-search-filters__wrapper .query-category {
    max-width: 100%;
    min-width: 100%;
  }
}
.fs-search-filters__wrapper .query-category--alt-width {
  min-width: 50%;
  max-width: 50%;
}
.fs-search-filters__wrapper .sub-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 21px;
  color: #777777;
  font-weight: 600;
  margin-bottom: 24px;
}
.fs-search-filters__wrapper .sub-label img {
  margin-right: 12px;
  height: 28px;
}
.fs-search-filters__wrapper .required-label {
  display: flex;
  font-size: 16px;
  color: #777777;
  font-weight: 600;
  margin-right: auto;
  margin-left: auto;
  white-space: nowrap;
}
.fs-search-filters__wrapper .required-label img {
  margin-left: 6px;
  max-height: 18px;
}
.fs-search-filters__wrapper .admin-btn-img {
  min-height: 32px;
  max-height: 32px;
  max-width: 32px;
  min-width: 32px;
}
@media (max-width: 1280px) {
  .fs-search-filters__wrapper .admin-btn-img {
    min-height: 28px;
    max-height: 28px;
    max-width: 28px;
    min-width: 28px;
  }
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters {
  list-style-type: none;
  display: flex;
  width: 100%;
  max-width: 300px;
  justify-content: space-between;
  margin-bottom: 54px;
}
@media (max-width: 480px) {
  .fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters {
    min-width: 80%;
  }
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters li {
  position: relative;
  display: flex;
  min-height: 100%;
  max-height: 64px;
  width: 64px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters li .fs-mob-filter-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 64px;
  max-width: 64px;
  min-width: 64px;
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters li .fs-mob-filter-btn img {
  padding: 6px;
  max-width: 100%;
  pointer-events: none;
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters li .fs-mob-filter-btn--incomplete {
  position: absolute;
  bottom: -40px;
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters li .fs-mob-filter-btn--incomplete img {
  height: 24px;
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters li .fs-mob-filter-sum {
  position: absolute;
  background-color: #dc2c2c;
  border-radius: 10px;
  padding: 0px 8px;
  height: 20px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -32px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters .fs-mob-filter-selected {
  outline: 4px solid #ff8585;
  border: 1px solid rgba(0, 0, 0, 0);
}
.fs-search-filters__wrapper .fs-mob-con .fs-mob-con-search-filters .fs-mob-filter-img {
  max-height: 100%;
}
.fs-search-filters__wrapper .fs-mob-action__container {
  max-width: 650px;
  min-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1280px) {
  .fs-search-filters__wrapper .fs-mob-action__container {
    max-width: 80%;
    min-width: 80%;
  }
}

.fs-search-results--label {
  position: absolute;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 700;
  font-size: 21px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: #ffffff;
  z-index: 4;
}
.fs-search-results--label:focus-visible {
  outline: 3px solid blue;
}

.fs-search-results--label-filters {
  top: -14px;
  left: 42px;
}

.fs-search-results--label-results {
  top: -14px;
  left: 42px;
}

.fs-search-results--label--demo {
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 3px 6px;
}
@media (max-width: 480px) {
  .fs-search-results--label--demo {
    left: 0;
    right: 0;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.find-speaker-search-results__wrapper {
  align-items: center;
  align-items: flex-start;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-y: scroll;
  padding: 48px;
  position: relative;
  scrollbar-width: none;
  z-index: 3;
  min-height: 596px;
  height: fit-content;
  max-height: fit-content;
  max-width: 948px;
  min-width: 948px;
}
@media (max-width: 1825px) {
  .find-speaker-search-results__wrapper {
    max-width: 524px;
    min-width: 524px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
}
@media (max-width: 1480px) {
  .find-speaker-search-results__wrapper {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 480px) {
  .find-speaker-search-results__wrapper {
    padding: 36px 12px;
  }
}
.find-speaker-search-results__wrapper:focus-visible {
  outline: 3px solid blue;
  outline-offset: 6px;
}
.find-speaker-search-results__wrapper .find-speaker-search-results__container {
  width: 100%;
}
@media (max-width: 1825px) {
  .find-speaker-search-results__wrapper .find-speaker-search-results__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
  }
}
@media (max-width: 1200px) {
  .find-speaker-search-results__wrapper .find-speaker-search-results__container {
    max-width: 400px;
  }
}
.find-speaker-search-results__wrapper .fs-search-results-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.find-speaker-search-results__wrapper .fs-search-results-settings .fs-search-results-settings--toggle {
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 12px;
  border-radius: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.find-speaker-search-results__wrapper .fs-search-results-settings .fs-search-results-settings--toggle .settings-img {
  min-height: 24px;
  max-height: 24px;
  max-width: 24px;
  min-width: 24px;
}
.find-speaker-search-results__wrapper .fs-search-results-settings .fs-search-results-settings--toggle .dropdown-img {
  min-height: 21px;
  max-height: 21px;
  max-width: 21px;
  min-width: 21px;
}
.find-speaker-search-results__wrapper .fs-search-results-settings .fs-search-results-settings__btn-container {
  width: 100%;
  padding: 24px 0px;
}
.find-speaker-search-results__wrapper .fs-results__container {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  justify-content: space-between;
  min-width: 100%;
}
@media (max-width: 1480px) {
  .find-speaker-search-results__wrapper .fs-results__container {
    justify-content: space-between;
  }
}
@media (max-width: 1200px) {
  .find-speaker-search-results__wrapper .fs-results__container {
    justify-content: center;
  }
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results__container--result {
  margin-bottom: 24px;
  position: relative;
  min-width: 400px;
  max-width: 400px;
  margin-left: 12px;
  margin-right: 12px;
}
@media (max-width: 1825px) {
  .find-speaker-search-results__wrapper .fs-results__container .fs-results__container--result {
    margin-left: 0px;
    margin-right: 0px;
    min-width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 1480px) {
  .find-speaker-search-results__wrapper .fs-results__container .fs-results__container--result {
    min-width: 400px;
    max-width: 400px;
    margin-left: 12px;
    margin-right: 12px;
  }
}
@media (max-width: 500px) {
  .find-speaker-search-results__wrapper .fs-results__container .fs-results__container--result {
    margin-left: 0px;
    margin-right: 0px;
    min-width: 100%;
    max-width: 100%;
  }
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results__container--result:last-child {
  margin-bottom: 0px;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results__container--result .fs-result-contact {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 8px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 1;
  top: 4px;
  left: 4px;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results__container--result .fs-result-contact img {
  max-width: 18px;
  max-height: 18px;
  min-width: 18px;
  min-height: 18px;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-result {
  display: flex;
  padding: 12px;
  border-radius: 18px;
  min-height: 50px;
  font-size: 18px;
  transition-duration: 0.15s;
  min-width: 100%;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-account-profile {
  min-width: 84px;
  max-width: 84px;
  min-height: 84px;
  max-height: 84px;
  border-radius: 50%;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-result--selected {
  border: 1px solid #f67512;
  outline: 1px solid #f67512;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper {
  max-width: 400px;
}
@media (max-width: 1280px) {
  .find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper {
    max-width: 100%;
    width: 100%;
  }
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper .fs-results-tags__container {
  display: flex;
  flex-direction: column;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper .fs-results-tags__container .fs-results-tag {
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  gap: 10px;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper .fs-results-tags__container .fs-results-tag li {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 4px 6px;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper .fs-results-tags__container .fs-results-tag p {
  width: 100%;
  white-space: nowrap;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper .fs-result--tags {
  display: flex;
  list-style-type: none;
  white-space: wrap;
  word-wrap: break-word;
  max-width: 400px;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper .fs-result--tags ul {
  max-width: 400px;
  word-wrap: break-word;
}
.find-speaker-search-results__wrapper .fs-results__container .fs-results-tags__wrapper .fs-result--tags .fs-result--tag {
  white-space: nowrap;
  word-wrap: break-word;
}

.fs-result--click {
  transform: scale(0.95);
}

.fs-selection__wrapper {
  position: relative;
  min-height: 500px;
  max-width: 512px;
  min-width: 512px;
  z-index: 1;
}
@media (max-width: 1280px) {
  .fs-selection__wrapper {
    min-width: 100%;
  }
}

.fs-selection__profile-skeleton-wrapper {
  position: absolute;
  top: 0;
}

.loadMore-divider {
  border: 1px solid #ccc;
  min-width: 100%;
}

.events {
  flex-grow: 1;
  min-height: 100%;
}

.events-grid__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.events-grid-loading-overlay {
  position: fixed;
  background-color: white;
  min-width: 100%;
  display: flex;
  min-height: 100%;
  top: 0;
  right: 0;
  z-index: 10;
}

.events-grid {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1552px;
  row-gap: 24px;
  column-gap: 24px;
  overflow: hidden;
  padding: 24px;
  justify-content: center;
}
@media (max-width: 1585px) {
  .events-grid {
    max-width: 1152px;
  }
}
@media (max-width: 1193px) {
  .events-grid {
    max-width: 776px;
  }
}
@media (max-width: 848px) {
  .events-grid {
    max-width: 400px;
  }
}
@media (max-width: 480px) {
  .events-grid {
    column-gap: 0px;
    max-width: 100vw;
  }
}

.ec__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.2);
  min-width: 352px;
  max-width: 352px;
  cursor: pointer;
  z-index: 3;
  border-radius: 20px;
  transition: transform 50ms ease-in-out, box-shadow 0.45s ease-in-out;
  -webkit-tap-highlight-color: transparent;
}
@media (max-width: 848px) {
  .ec__wrapper {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 400px) {
  .ec__wrapper {
    min-width: 100%;
    max-width: 100%;
  }
}
@media (hover: hover) {
  .ec__wrapper:hover {
    box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.4);
  }
}
.ec__wrapper .ec__info-container {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 5;
  outline-offset: -2px;
  max-width: 100%;
}
.ec__wrapper .ec__header-section {
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.ec__wrapper .ec__header-section h2 {
  padding: 0px 14px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  overflow: hidden;
  min-width: 100%;
}
@media (max-width: 320px) {
  .ec__wrapper .ec__header-section h2 {
    font-size: 16px;
  }
}
.ec__wrapper .ec__header-section .clamp-1 {
  overflow: hidden;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  flex-grow: 1;
}
.ec__wrapper .ec__header-section .clamp-2 {
  overflow: hidden;
  display: flex;
  height: 50px;
  min-height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  flex-grow: 1;
}
.ec__wrapper .ec__header-section .dividing-line {
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: 90%;
  height: 1px;
}
.ec__wrapper .ec__click-overlay {
  max-height: 100%;
  min-height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.ec__wrapper .ec__image-container {
  position: relative;
  min-height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
}
@media (max-width: 320px) {
  .ec__wrapper .ec__image-container {
    min-height: 0px;
  }
}
.ec__wrapper .ec__image-container .ec-display-image {
  max-width: 100%;
  object-fit: contain;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
}
.ec__wrapper .actions-overlay {
  z-index: 5;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  min-width: 100%;
  max-height: 100%;
  min-height: 100%;
  top: 0;
  right: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: space-evenly;
  align-items: center;
  cursor: default;
  transition: opacity 0.3s ease-in;
  transition-delay: 0.25s;
}
@media (hover: hover) {
  .ec__wrapper .actions-overlay:hover {
    opacity: 1;
  }
}
.ec__wrapper .actions-overlay__button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 50%;
  max-height: 58px;
  max-width: 58px;
  opacity: 1;
  z-index: 7;
  margin: 0 10px;
  outline: 3px solid rgba(255, 255, 255, 0.6);
  transition: 0ms ease-in;
  height: fit-content;
}
.ec__wrapper .actions-overlay__button img {
  height: 58px;
  width: 58px;
  max-width: 58px;
  max-height: 58px;
}
.ec__wrapper .ec__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 38px;
  max-height: 44px;
  line-height: 21px;
}
@media (max-width: 320px) {
  .ec__wrapper .ec__row {
    line-height: 18px;
  }
}
.ec__wrapper .ec__row .icon-container {
  height: 32px;
  max-width: 28px;
  min-width: 28px;
  margin-right: 10px;
  margin-left: 2px;
}
.ec__wrapper .ec__row .calendar {
  max-width: 27px;
  min-width: 27px;
  margin-left: 3px;
  margin-right: 9px;
}
.ec__wrapper .ec__row .ec__row-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
}
.ec__wrapper .ec__row .address-content {
  overflow: hidden;
  max-width: 224px;
}
.ec__wrapper .ec__row .address-content p {
  display: block;
  min-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ec__wrapper .ec__row-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  padding: 0px 4px;
  margin-bottom: 18px;
  white-space: wrap;
}
.ec__wrapper .ec__row-footer .info-icons-row {
  display: flex;
  align-items: center;
  min-height: 28px;
  min-width: 100%;
}

.ec-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 352px;
  max-width: 352px;
  min-height: 484px;
  transition: 0.45s ease-in-out;
}
.ec-empty h1 {
  font-size: 18px;
}
.ec-empty h2 {
  font-size: 18px;
  text-decoration: underline;
}
.ec-empty:hover {
  transition: 0.45s ease-in-out;
}
.ec-empty:hover h2 {
  color: #f67512;
  cursor: pointer;
}
@media (max-width: 1919px) {
  .ec-empty {
    margin-left: 10vw;
  }
}
@media (max-width: 480px) {
  .ec-empty {
    min-width: 94vw;
    min-height: 472px;
    min-width: 94vw;
    margin-left: 3vw;
    margin-right: 3vw;
  }
}
@media (max-width: 320px) {
  .ec-empty {
    min-height: 398px;
  }
}

.ec__ghost {
  position: relative;
  width: 352px;
  height: 480px;
}
@media (max-width: 848px) {
  .ec__ghost {
    margin-left: auto;
    margin-right: auto;
  }
}

.ec__image-skeleton {
  height: 198px;
  min-width: 100%;
  background: darkgray;
  min-height: 162px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.ec-skeleton__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 352px;
  height: 480px;
  box-shadow: 1px 1px 12px 1px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 20px;
}
@media (max-width: 400px) {
  .ec-skeleton__container {
    min-width: 100%;
    max-width: 100%;
  }
}
.ec-skeleton__container .ec__image {
  height: 198px;
  width: 100%;
  background: darkgray;
  min-height: 162px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}
.ec-skeleton__container .ec__data {
  margin-top: 24px;
  margin-bottom: 10px;
  height: 34px;
  width: 94%;
  border-radius: 5px;
  background: darkgray;
}
.ec-skeleton__container .ec__icon {
  display: flex;
  flex-direction: row;
  width: 20px;
  height: 20px;
  margin: 10px;
  margin-left: 14px;
  margin-right: auto;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(240, 5%, 96%);
  }
}
.ec-search-complete-change-search-btn {
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-evenly;
  opacity: 0.8;
  flex-direction: column;
  transition: transform 50ms ease-in-out, opacity 250ms ease-in-out;
}
@media (max-width: 480px) {
  .ec-search-complete-change-search-btn {
    padding: 24px;
    transition: 0ms ease-out;
  }
}
@media (hover: hover) {
  .ec-search-complete-change-search-btn:hover {
    opacity: 1;
  }
}

.ec-search-complete-change-search-btn--tap {
  opacity: 1;
  transform: scale(0.98);
}

.ev {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 6px 3px rgba(0, 0, 0, 0.05);
  border-radius: 22px;
  margin-bottom: 80px;
  margin-top: 40px;
}
@media (max-width: 624px) {
  .ev {
    max-width: 100vw;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.ev .heading-row {
  max-height: 48px;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ev .heading-row--organizer {
  max-height: fit-content;
  min-height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.ev .heading-icon {
  min-height: 32px;
  max-height: 32px;
}
.ev .ev-divider-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
}
.ev .ev-divider-top {
  border-top: 2px solid rgba(0, 0, 0, 0.15);
}
.ev .divider {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.ev .ev__action-btns {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  border-radius: 20px;
  padding: 24px;
}
@media (max-width: 480px) {
  .ev .ev__action-btns {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.ev .ev__action-btns--bg {
  background-color: rgba(0, 0, 0, 0.05);
}
.ev .ev__action-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30%;
  box-shadow: 0px 0px 4px 0.5px rgba(0, 0, 0, 0.15);
  min-width: 64px;
  min-height: 64px;
  transition: 0ms ease-in-out;
  background-color: #ffffff;
}
.ev .ev__action-btn:focus-visible {
  outline: 3px solid blue;
  transition: none;
  outline-offset: 6px;
}
.ev .ev__action-btns--registrations__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  padding-bottom: 12px;
}
@media (max-width: 480px) {
  .ev .ev__action-btns--registrations__wrapper {
    flex-direction: column;
  }
}
.ev .ev__action-btns--registrations__wrapper .ev__action-btns--registrations__container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}
@media (max-width: 800px) {
  .ev .ev__action-btns--registrations__wrapper .ev__action-btns--registrations__container {
    flex-direction: column;
  }
}
.ev .ev__action-btn--large {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  padding: 6px 6px;
  min-height: 64px;
  max-height: 64px;
  box-shadow: 0px 0px 4px 0.5px rgba(0, 0, 0, 0.25);
  transition: 0ms ease-in-out;
  min-width: 210px;
  max-width: 210px;
  background-color: #ffffff;
}
.ev .ev__action-btn--large img {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 18px;
  height: 32px;
}
.ev .ev__action-btn--large:focus-visible {
  outline: 3px solid blue;
  transition: none;
  outline-offset: 6px;
}
.ev .lock-img {
  margin-top: auto;
  margin-bottom: auto;
  height: 18px;
}
.ev .map-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  padding: 6px;
}
.ev .ev-display-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
.ev .ev-display-container .ev-display-image {
  max-width: 100%;
  object-fit: contain;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
}
.ev .ev-display-container .ev-display-image--skeleton {
  max-width: 100%;
  object-fit: contain;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  background: darkgray;
  min-height: 335px;
}
@media (max-width: 480px) {
  .ev .ev-display-container .ev-display-image--skeleton {
    height: 219px;
  }
}
.ev .ev-additional-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
}
.ev .ev-additional-container .ev-additional-image {
  max-width: 100%;
  object-fit: contain;
  display: flex;
  max-height: 240px;
}
.ev .ev-additional-container .ev-additional-image--skeleton {
  max-width: 100%;
  object-fit: contain;
  display: flex;
  background: darkgray;
  max-height: 240px;
}
@media (max-width: 480px) {
  .ev .ev-additional-container .ev-additional-image--skeleton {
    height: 219px;
  }
}
.ev .ev-main__container {
  display: flex;
  flex-direction: column;
  padding: 0px 36px 24px 36px;
}
@media (max-width: 1280px) {
  .ev .ev-main__container {
    padding: 24px;
    margin-left: 12px;
    margin-right: 12px;
  }
}
@media (max-width: 480px) {
  .ev .ev-main__container {
    padding: 24px 0px;
  }
}
@media (max-width: 320px) {
  .ev .ev-main__container {
    margin-left: 6px;
    margin-right: 6px;
  }
}
.ev .ev-main__container .ev-info__container {
  font-size: 18px;
  display: flex;
  width: fit-content;
  flex-direction: column;
}
.ev .ev-main__container .ev-info__container .ev-info__row {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-bottom: 12px;
  align-items: center;
  flex-wrap: wrap;
}
.ev .ev-main__container .ev-info__container .ev-info__row:last-child {
  margin-bottom: 0px;
}
.ev .ev-main__container .agenda__wrapper {
  max-height: fit-content;
  display: flex;
  flex-direction: column;
}
.ev .ev-main__container .agenda__wrapper .agenda__container {
  margin-bottom: 12px;
}
.ev .ev-main__container .agenda__wrapper .agenda__container:last-child {
  margin-bottom: 0px;
}
.ev .ev-main__container .agenda__wrapper .agenda-bar {
  width: 12px;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
}
.ev .ev-main__container .agenda__wrapper .agenda-item {
  width: 100%;
  display: flex;
  border: 2px solid rgba(0, 0, 0, 0.35);
  padding: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.ev-preview-banner {
  position: absolute;
  transform: scale(1);
  top: -5px;
  left: -5px;
  height: 220px;
  width: 220px;
}
@media (max-width: 650px) {
  .ev-preview-banner {
    height: 170px;
    width: 170px;
  }
}
@media (max-width: 480px) {
  .ev-preview-banner {
    transform: scale(1);
    height: 140px;
    width: 140px;
  }
}

.ev-image-caption {
  font-size: 18px;
  width: 100%;
  height: fit-content;
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  z-index: 3;
}
.ev-image-caption .ev-image-caption--text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.admin {
  position: relative;
  display: flex;
  flex-grow: 1;
  max-width: 100%;
}
@media (max-width: 840px) {
  .admin {
    flex-direction: column;
  }
}

.incompatible-viewscreen {
  font-size: 16px;
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.incompatible-viewscreen img {
  height: 48px;
}

.admin-table {
  margin: 48px;
  font-size: 18px;
  border-collapse: collapse;
  max-height: 0px;
}
.admin-table th {
  border: 1px solid #ddd;
  padding: 8px;
  min-width: 200px;
}
.admin-table td {
  min-width: 200px;
  border: 1px solid #ddd;
  padding: 8px;
}

.activity-log-modal {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  max-height: 820px;
}
.activity-log-modal .activity-log-modal__heading {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.35);
  display: flex;
  min-height: 54px;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  margin-bottom: 2px;
  width: 100%;
}
.activity-log-modal .activity-log-modal__container {
  list-style-type: none;
  width: 1024px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 0px 24px;
  height: 100vh;
}
.activity-log-modal .activity-log-modal__divider {
  border: 1px solid rgba(0, 0, 0, 0.15);
  background-color: rgba(0, 0, 0, 0.15);
  margin-bottom: 12px;
}
.activity-log-modal .close-btn img {
  margin-top: 3px;
  min-width: 44px;
  padding: 12px;
}
.activity-log-modal .activity-log-modal__footer {
  align-items: center;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.35);
  display: flex;
  min-height: 54px;
  width: 100%;
}

.admin-tickets__wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: hidden;
  overflow-x: visible;
  width: fit-content;
  position: relative;
  width: 100%;
}
.admin-tickets__wrapper .ticket-row {
  display: flex;
  flex-direction: row;
  overflow-x: visible;
  min-height: fit-content;
  margin-bottom: 48px;
}

.table-ticket-arrow {
  width: 50%;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 24px;
  margin-top: 24px;
  border: 1px solid rgba(0, 0, 0, 0.35);
}
.table-ticket-arrow img {
  filter: invert(71%) sepia(0%) saturate(1637%) hue-rotate(286deg) brightness(80%) contrast(87%);
}
.table-ticket-arrow:first-child {
  margin-right: 12px;
}
.table-ticket-arrow:last-child {
  margin-left: 12px;
}
@media (hover: hover) {
  .table-ticket-arrow:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.support-ticket__wrapper {
  background-color: white;
  border-radius: 10px;
  border: 3px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  font-size: 18px;
  height: fit-content;
  margin: 12px 36px 12px 12px;
  max-width: 1024px;
  min-width: 1024px;
  padding: 24px;
  position: relative;
  z-index: 2;
  min-height: 1290px;
}
.support-ticket__wrapper .close-ticket {
  padding: 12px;
}
.support-ticket__wrapper .close-ticket img {
  height: 24px;
}
.support-ticket__wrapper .ticket-status {
  padding: 6px 12px;
  font-size: 21px;
  font-weight: 600;
  border-radius: 4px;
}
.support-ticket__wrapper .ticket-status--assigned {
  border: 2px solid #64c071;
  color: #64c071;
}
.support-ticket__wrapper .ticket-status--unassigned {
  border: 2px solid #dc2c2c;
  color: #dc2c2c;
}
.support-ticket__wrapper .ticket-status--open {
  border: 2px solid darkorange;
  color: darkorange;
}
.support-ticket__wrapper .ticket-status--pendingUserAction {
  border: 2px solid red;
  color: red;
}
.support-ticket__wrapper .ticket-status--resolved {
  border: 2px solid #64c071;
  color: #64c071;
}
.support-ticket__wrapper .support-ticket__actions {
  display: flex;
  width: 100%;
  max-width: fit-content;
  align-items: center;
  min-height: 80px;
}
.support-ticket__wrapper .support-ticket__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid rgba(0, 0, 0, 0.35);
  padding: 24px;
  border-radius: 10px;
  margin-bottom: 24px;
  white-space: wrap;
  word-wrap: break-word;
}
.support-ticket__wrapper .support-ticket__container .notes {
  display: flex;
  align-items: center;
}
.support-ticket__wrapper .support-ticket__container .notes .notes-limited {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
  max-width: 100%;
}

.info-card-list {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  list-style-type: none;
  margin-top: 12px;
}
.info-card-list li {
  margin-left: 12px;
  margin-bottom: 12px;
  position: relative;
}
.info-card-list .list-handle {
  position: absolute;
  left: -18px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 6px;
  border-radius: 12px;
  opacity: 0.5;
}

.admin-search-tool {
  max-width: fit-content;
  min-width: 698px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.admin-search-tool .admin-search-tool__container {
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  display: flex;
  min-width: 100%;
  max-width: 100%;
  justify-content: space-evenly;
  flex-direction: column;
}
.admin-search-tool .search-btn {
  padding: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticket-table-filters__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}

.ticket-table-filters {
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.ticket-table-filters .ticket-table-dates {
  max-width: 396px;
  display: flex;
}
.ticket-table-filters .ticket-table-results {
  max-width: 198px;
  display: flex;
}

.ticket-table-dots {
  z-index: 3;
  display: flex;
}
.ticket-table-dots .dot {
  min-height: 11px;
  min-width: 11px;
  border-radius: 50%;
  z-index: 3;
}

.cards-row-empty-divider {
  width: 80%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.noticeOverlay__wrapper {
  position: fixed;
  min-width: 370px;
  max-width: 370px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.1);
  outline: 2px solid rgba(0, 0, 0, 0.1);
  background: #ffffff;
  transition: 500ms;
  z-index: 10;
  flex-direction: column;
  bottom: 0;
  right: 12px;
}
@media (max-width: 1280px) {
  .noticeOverlay__wrapper {
    max-width: 500px;
  }
}
@media (max-width: 480px) {
  .noticeOverlay__wrapper {
    bottom: 0;
    right: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    outline: 2px solid rgba(0, 0, 0, 0.2);
  }
}
.noticeOverlay__wrapper .noticeOverlay__header {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 8px;
  padding-bottom: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media (hover: hover) {
  .noticeOverlay__wrapper .noticeOverlay__header:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
}
@media (max-width: 480px) {
  .noticeOverlay__wrapper .noticeOverlay__header {
    border-bottom: 0px solid rgba(0, 0, 0, 0.25);
  }
}
.noticeOverlay__wrapper .noticeOverlay__header-notice-alert {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  background-color: #dc2c2c;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
}
.noticeOverlay__wrapper .msg-notice-1digit {
  height: 24px;
  width: 24px;
}
.noticeOverlay__wrapper .msg-notice-2digit {
  height: 36px;
  width: 36px;
}
.noticeOverlay__wrapper .noticeOverlay__header-profile-image {
  max-height: 42px;
  min-height: 42px;
  max-width: 42px;
  min-width: 42px;
  border-radius: 95%;
  object-fit: cover;
  border: 2px solid white;
}
.noticeOverlay__wrapper .noticeOverlay__header-profile-image img {
  object-fit: fill;
}
.noticeOverlay__wrapper .noticeOverlay__container {
  display: flex;
  list-style-type: none;
  width: 100%;
  height: auto;
  word-wrap: break-word;
  flex-direction: column;
  position: relative;
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
.noticeOverlay__wrapper .noticeOverlay__container li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
@media (hover: hover) {
  .noticeOverlay__wrapper .noticeOverlay__container li:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.noticeOverlay__wrapper .noticeOverlay__container .notice--opened {
  background-color: rgba(0, 0, 0, 0.05);
}
.noticeOverlay__wrapper .noticeOverlay__container .msg-notice-entry-btn {
  display: flex;
  word-wrap: break-word;
  align-items: center;
  height: auto;
  flex-direction: column;
  margin: 0px 0px;
  padding: 12px 6px;
  width: 100%;
}
.noticeOverlay__wrapper .noticeOverlay__container .noticeOverlay-profile {
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  border-radius: 50%;
}
.noticeOverlay__wrapper .noticeOverlay__container .noticeOverlay-action-btns__container {
  display: flex;
  padding-bottom: 12px;
  width: 100%;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
}
.noticeOverlay__wrapper .noticeOverlay__container .noticeOverlay-action-btns__container .noticeOverlay-action-btn {
  background-color: white;
  min-height: 24px;
  font-size: 16px;
  margin-bottom: 12px;
  width: 100%;
}
.noticeOverlay__wrapper .noticeOverlay__container .noticeOverlay-action-btns__container .noticeOverlay-action-btn img {
  max-height: 18px;
}
.noticeOverlay__wrapper .noticeOverlay__container .noticeOverlay-action-btns__container .noticeHistory {
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
.noticeOverlay__wrapper .noticeOverlay__container .notice-history-summary {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  min-width: 44px;
}
.noticeOverlay__wrapper .noticeOverlay__container .notice-history-summary img {
  height: 18px;
}
.noticeOverlay__wrapper .noticeOverlay__container .notice-archived-remove {
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  min-width: 44px;
}
.noticeOverlay__wrapper .noticeOverlay__container .notice-archived-remove img {
  height: 24px;
}
.noticeOverlay__wrapper .noticeOverlay__container .noticeOverlay--seen {
  position: absolute;
  width: 18px;
  height: 18px;
  background: radial-gradient(circle, orange 10%, transparent 90%);
  border-radius: 50%;
  right: 12px;
  top: 12px;
  opacity: 0.7;
  cursor: pointer;
  pointer-events: none;
  animation: pulse 2s infinite ease-in-out;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}
.noticeOverlay__wrapper .noticeOverlay-settings__wrapper {
  display: flex;
}
.noticeOverlay__wrapper .noticeOverlay-settings__wrapper .noticeOverlay-settings {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}
.noticeOverlay__wrapper .noticeOverlay-settings__wrapper .noticeOverlay-settings img {
  height: 21px;
}
@media (hover: hover) {
  .noticeOverlay__wrapper .noticeOverlay-settings__wrapper .noticeOverlay-settings:hover {
    background: rgba(0, 0, 0, 0.03);
  }
}
.noticeOverlay__wrapper .noticeOverlay-settings__wrapper .noticeOverlay-settings--selected {
  background: rgba(0, 0, 0, 0.1);
}
@media (hover: hover) {
  .noticeOverlay__wrapper .noticeOverlay-settings__wrapper .noticeOverlay-settings--selected:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.notice-remove-btn {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 6px;
  min-height: 44px;
  min-width: 44px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.noticeOverlay__wrapper--opened {
  height: 70%;
}
@media (max-width: 1280px) {
  .noticeOverlay__wrapper--opened {
    height: 80%;
  }
}

.noticeOverlay__wrapper--closed {
  height: 62px;
}
@media (max-width: 1280px) {
  .noticeOverlay__wrapper--closed {
    height: 0px;
  }
}

@media (hover: hover) {
  .noticeOverlay__wrapper--hover-rise:hover {
    height: 84px;
  }
}

.nav-bar__notices {
  position: relative;
  min-width: 44px;
}
.nav-bar__notices .nav-bar__notices-bell {
  z-index: 7;
  position: relative;
  padding: 6px;
}
.nav-bar__notices .nav-bar__notices-alert {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  background-color: #dc2c2c;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  z-index: 6;
}
.nav-bar__notices .nav-bar__msg-notice-1digit {
  height: 24px;
  width: 24px;
  top: 4px;
  left: 30px;
}
.nav-bar__notices .nav-bar__msg-notice-2digit {
  height: 32px;
  width: 32px;
  top: 2px;
  left: 28px;
}

.community__container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
}

.community-contact__wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  transition: 250ms ease-in;
  border: 2px solid rgba(0, 0, 0, 0);
  padding-left: 6px;
  padding-right: 6px;
  margin-right: 6px;
  margin-left: 6px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
}
@media (hover: hover) {
  .community-contact__wrapper:hover {
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0.25);
  }
}
.community-contact__wrapper .community-contact__container {
  display: flex;
  height: fit-content;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
  width: 100%;
  text-align: left;
}
.community-contact__wrapper .community-contact__container .community-contact--profile {
  max-height: 48px;
  max-width: 48px;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
}
.community-contact__wrapper .community-contact__container .community-contact--name {
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  font-weight: 600;
}
.community-contact__wrapper .community-contact__container .community-contact--accountType {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  font-style: italic;
}
.community-contact__wrapper .community-contact__container .community-contact-view {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0;
  background-color: rgba(233, 230, 230, 0.35);
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 150ms;
}
.community-contact__wrapper .community-contact__container .community-contact-view img {
  filter: invert(50%) sepia(34%) saturate(2601%) hue-rotate(354deg) brightness(99%) contrast(95%);
}
.community-contact__wrapper .community-contact__actions-container {
  display: flex;
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.disable-select {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.svg {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.remove {
  display: none !important;
  visibility: hidden;
}

.visibility-hidden {
  visibility: hidden;
}

.unavailable {
  cursor: not-allowed;
}

.pointer-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-pointer {
  cursor: pointer;
}

.divider {
  border: 1px solid rgba(0, 0, 0, 0.25);
  max-height: 1px;
  background-color: rgba(0, 0, 0, 0.25);
}

.divider--theme {
  border: 1px solid #f67512;
  max-height: 1px;
}

.divider-vertical {
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
  min-height: 100%;
}

.divider-noMrg {
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
}

.divider-dotted {
  border-bottom: 4px dotted rgba(0, 0, 0, 0.35);
}

.list-style-none {
  list-style-type: none;
}

.cursor-auto {
  cursor: auto;
}

.inline-block {
  display: inline-block;
}

.status-dot {
  height: 8px;
  width: 8px;
  z-index: inherit;
  border-radius: 50%;
  outline-offset: 2px;
  margin-left: 8px;
}

.status-dot-green {
  background-color: #64c071;
  outline: 2px solid #64c071;
}

.status-dot-red {
  background-color: #dc2c2c;
  outline: 2px solid #dc2c2c;
}

.status-dot-orange {
  background-color: orange;
  outline: 2px solid orange;
}

.status-dot-theme {
  background-color: #f67512;
  outline: 2px solid #f67512;
}

.status-dot-grey {
  background-color: grey;
  outline: 2px solid grey;
}

.alert-circle {
  align-items: center;
  background-color: rgb(248, 45, 45);
  border-radius: 50%;
  border: 3px solid #ffffff;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 27px;
  justify-content: center;
  position: absolute;
  right: -8.5px;
  top: -8.5px;
  width: 27px;
}

.summary-circle {
  align-items: center;
  background-color: #f67512;
  border-radius: 50%;
  border: 3px solid #ffffff;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  font-weight: 700;
  height: 27px;
  justify-content: center;
  position: absolute;
  bottom: -8.5px;
  left: -8.5px;
  width: 27px;
}

.min-width-44 {
  min-width: 44px;
}

.min-height-44 {
  min-height: 44px;
}

.min-44 {
  min-width: 44px;
  min-height: 44px;
}

.offscreen {
  position: fixed;
  left: -9999px;
  height: 0px;
  width: 0px;
  opacity: 0;
  pointer-events: none;
}

.active-dot {
  pointer-events: none;
  background-color: #f67512;
  opacity: 1;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.hidden-dot {
  pointer-events: none;
  opacity: 1;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.hide-scrollbar {
  scrollbar-width: none;
}

.scroll-y-no-x {
  overflow-y: scroll;
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.socket-status-connecting {
  animation: spin 6s linear infinite;
  min-height: 32px;
  min-width: 32px;
  max-height: 32px;
  max-width: 32px;
  animation: spin 0.8s infinite;
  margin-top: auto;
  margin-bottom: auto;
}

.socket-status-connecting--mobile {
  animation: spin 6s linear infinite;
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  animation: spin 0.8s infinite;
  position: absolute;
  right: -32px;
  top: 0;
  bottom: 0;
  margin-bottom: auto;
  margin-top: auto;
}

.opacity-0 {
  opacity: 0;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}

html {
  position: relative;
  font-size: 62.5%;
}
@media (max-width: 480px) {
  html {
    font-size: 50%;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
a a:visited {
  color: inherit;
}

Link:visited {
  color: inherit;
}

button {
  cursor: pointer;
  border-style: none;
  border-color: none;
  background: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: "Open Sans", sans-serif;
}

img {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

legend {
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.app .app__contents {
  display: flex;
  flex-grow: 1;
  min-height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

.grecaptcha-badge {
  visibility: hidden;
}
